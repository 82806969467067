import { createRoutine } from 'redux-saga-routines'

export const INIT = "Chat.Init";
export const KILL = "Chat.Kill";
export const LIST = "Chat.List";
export const SET_CURRENT = "Chat.SetCurrent";
export const SEND_MESSAGE = "Chat.SendMessage";
export const MESSAGE_RECEIVED = "Chat.MessageReceived";
export const ROOM_LIST = "Chat.RoomList";
export const WAITING_LIST = "Chat.WaitingList";
export const CONTACT_COMPANY = "Chat.ContactCompany";
export const LIST_MESSAGES = "Chat.ListMessages";
export const SET_ATTENDEE = "Chat.SetAttendee";
export const START_ROOM = "Chat.StartRoom";
export const ARCHIVE_ROOM = "Chat.ArchiveRoom";
export const SET_TAB = "Chat.SetTab";
export const SEARCH = "Chat.Search";
export const GET_PROFILE = "Chat.GetProfile";
export const LOAD_COMPANY_DATA = "Chat.LoadCompanyData";
export const CLOSE_CHAT = "Chat.CloseChat";
export const GET_COMPANY_ACTIVE = "Chat.GetCompanyActive";

export const init = createRoutine(INIT);
export const kill = createRoutine(KILL);
export const setList = createRoutine(LIST);
export const setCurrent = createRoutine(SET_CURRENT);
export const sendMessage = createRoutine(SEND_MESSAGE);
export const messageReceived = createRoutine(MESSAGE_RECEIVED);
export const roomList = createRoutine(ROOM_LIST);
export const contactCompany = createRoutine(CONTACT_COMPANY);
export const listMessages = createRoutine(LIST_MESSAGES);
export const setAttendee = createRoutine(SET_ATTENDEE);
export const startRoom = createRoutine(START_ROOM);
export const archiveRoom = createRoutine(ARCHIVE_ROOM);
export const setTab = createRoutine(SET_TAB);
export const search = createRoutine(SEARCH);
export const getProfile = createRoutine(GET_PROFILE);
export const waitingList = createRoutine(WAITING_LIST);
export const loadCompanyData = createRoutine(LOAD_COMPANY_DATA);
export const closeChat = createRoutine(CLOSE_CHAT);
export const getCompanyActive = createRoutine(GET_COMPANY_ACTIVE);
