import { createSelector } from 'reselect';

export const getLocale = createSelector(
  (state) => state.localization.current,
  current => current
);

export const getTranslations = createSelector(
  (state) => state.localization.translations[state.localization.current],
  translations => translations
);
