import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { useTheme } from './../../themes';

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-bottom: ${props => props.borderSize+"px "+props.borderStyle+" "+props.borderColor };
  color: ${props => props.textColor};
  background: ${props => props.background};
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};

  ${props => props.isDisabled ? `
    color: `+props.textColorDisabled+`;
    background: `+props.backgroundDisabled+`;
    border-color: `+props.borderColorDisabled+`;
  ` : props => props.isActive ? `
    color: `+props.textColorActive+`;
    background: `+props.backgroundActive+`;
    border-color: `+props.borderColorActive+`;
  ` : `
    &:hover{
      color: `+props.textColorHover+`;
      background: `+props.backgroundHover+`;
      border-color: `+props.borderColorHover+`;
    }
  `};
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${props => props.height.value+props.height.unit};
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  box-sizing: border-box;
`;

const Left = styled.div`
  flex-basis: ${props => props.size.value+props.size.unit};
  flex-grow: 0;
  flex-shrink: 0;
  text-align: left;
  margin-right: 10px;
  align-items: center;
  flex-basis: ${props => props.size.value+props.size.unit};
`;
const Center = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  align-items: center;
  color: ${props => props.color};
`;
const Right = styled.div`
  flex-basis: ${props => props.size.value+props.size.unit};
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
  margin-left: 10px;
  align-items: center;
  flex-basis: ${props => props.size.value+props.size.unit};
`;
const After = styled.div`
  padding: 0px ${props => props.padding ? props.padding.right.value+props.padding.right.unit+" 0 "+props.padding.left.value+props.padding.left.unit+" " : "0px"};
`;

const MenuItem = ({
    children,
    isActive = false,
    isDisabled = false,
    leftIcon,
    rightIcon,
    onClick,
    after,
    variant = "default",
    size = "default"
  }) => {
  const theme = useTheme()
  return (
    <Wrapper
      isActive={isActive}
      isDisabled={isDisabled}
      borderStyle={theme.menuitem.size[size].borderStyle}
      borderSize={theme.menuitem.size[size].borderSize}
      background={theme.menuitem.variant[variant].normal.background}
      backgroundHover={theme.menuitem.variant[variant].hover.background}
      backgroundActive={theme.menuitem.variant[variant].active.background}
      backgroundDisabled={theme.menuitem.variant[variant].disabled.background}
      borderColor={theme.menuitem.variant[variant].normal.borderColor}
      borderColorHover={theme.menuitem.variant[variant].hover.borderColor}
      borderColorActive={theme.menuitem.variant[variant].active.borderColor}
      borderColorDisabled={theme.menuitem.variant[variant].disabled.borderColor}
      textColor={theme.menuitem.variant[variant].normal.textColor}
      textColorHover={theme.menuitem.variant[variant].hover.textColor}
      textColorActive={theme.menuitem.variant[variant].active.textColor}
      textColorDisabled={theme.menuitem.variant[variant].disabled.textColor}
      textFont={theme.menuitem.textFont}
      textTransform={theme.menuitem.textTransform}
      textSize={theme.menuitem.size[size].textSize}
      textWeight={theme.menuitem.size[size].textWeight}
      textLineHeight={theme.menuitem.size[size].textLineHeight}
      textLetterSpacing={theme.menuitem.textLetterSpacing}

      onClick={onClick}
    >
      <Container
        padding={theme.menuitem.size[size].padding}
        height={theme.menuitem.size[size].height}
      >
        { leftIcon ?
          <Left size={theme.menuitem.size[size].iconSize}>{React.cloneElement(leftIcon, {
            size: theme.menuitem.size[size].iconSize,
            color: isDisabled ? theme.menuitem.variant[variant].disabled.textColor : isActive ? theme.menuitem.variant[variant].active.textColor : theme.menuitem.variant[variant].normal.textColor
          })}</Left>
          : ""
        }
        <Center>
          {children}
        </Center>
        { rightIcon ?
          <Right size={theme.menuitem.size[size].iconSize}>{React.cloneElement(rightIcon, {
            size: theme.menuitem.size[size].iconSize,
            color: isDisabled ? theme.menuitem.variant[variant].disabled.textColor : isActive ? theme.menuitem.variant[variant].active.textColor : theme.menuitem.variant[variant].normal.textColor
          })}</Right>
          : ""
        }
      </Container>
      { after ?
        <After
          padding={theme.menuitem.size[size].padding}
        >{after}</After>
      : null}
    </Wrapper>
  )
}

MenuItem.propTypes = {
  leftIcon: PropTypes.element,
  isActive: PropTypes.bool,
  rightIcon: PropTypes.element,
  onClick: PropTypes.func,
  variant: PropTypes.string,
}

export default React.memo(MenuItem)
