export const MDM = {
  tenant: "MDM",
  name: "Marmomac Plus",
  default_language: 'it',
  languages: ["it", "en"], //, "fr", "zh", "ru", "ko", "ja", "pt", "es", "de"
  enable_login: true,
  enable_helpdesk: true,
  enable_favorites: true,
  enable_suggested: false,
  enable_halls: true,
  enable_chat: false,
  enable_bcards: false,
  enable_memos: false,
  enable_wizard: false,
  enable_agenda: false,
  enable_terms: true,
  enable_languages_in_menu: false,
  enable_compact_mobile_menu: true,
  tracking: [
    {
      //   type: 'hotjar',
      //   code: 183316,
      //   domains: ['catalogo.marmomac.it']
      // }, {
      type: 'analytics',
      code: 'UA-1767479-10', //fiera produzione
      domains: ['marmomacplus.com'],
      // }, {
      //   type: 'analytics',
      //   code: 'UA-169833767-1', //interno test
      //   domains: ['recat-mdm-test.firebaseapp.com'],
      // }, {
      //   type: 'analytics',
      //   code: 'UA-169833767-2', //interno produzione
      //   domains: ['catalogo.vinitaly.com'],
    }
  ],
  share_networks: ["facebook", "twitter", "pinterest"],
  translations: {
    url: "https://ozwol-tech-cdn.s3-eu-west-1.amazonaws.com/veronafiere/marmomac/",
  },
  ozwol: {
    endpoint: "https://api.ozwol.cloud/v1/executeScript",
    apiKey: "f9dc3e58-acc9-466f-9af1-085a19f86015",
    projectId: "47S3WM8K6G",
    cdn: "https://cdn.ozwol.cloud/47S3WM8K6G"
  },
  sendmail: {
    endpoint: "https://us-central1-recat-mdm.cloudfunctions.net/app/api/sendmail",
    email: "noreply@veronafiere.it",
    bcc: "sara.potyscki@ozwol.com" //separati da virgola
  },
  cookiebot: "355421e0-012c-40cd-a1b8-d839702741dd",
  auth0: {
    domain: "login.veronafiere.it",
    clientID: "LL8DtL3zZToagBbn1Cg9nJIiEdIl0CGo",
    responseType: "token id_token",
    scope: 'openid profile email',
    audience: "https://veronafiere.api.it",
    issuer: 'https://login.veronafiere.it/',
  },
  bcards: {
    apiUrl: null //"https://vrf-vinitaly-app.appspot.com/user/bcards/"
  },
  memos: {
    apiUrl: "https://vrf-marmomac-app.appspot.com/user/memos/"
  },
  news: {
    apiUrl: "https://apicatalogo.veronafiere.it/v1/MMP/contenuti_plus/"
  },
  recommended: {
    apiUrl: "https://apicatalogo.veronafiere.it/v1/MMP/users/"
  },
  areas: {
    "companies": {
      icon: 'company.svg',
      dataServiceKey: "exhibitors",
      enable: true,
      enable_detail: true,
      default: true,
      routes: {
        list: 'companies',
        detail: 'companies/{slug}'
      },
      subareas: {
        "companies": {
          default: true,
          key: "company",
          dataServiceKey: "",
          enable: true,
          enable_detail: true
        }
      }
    },
    "products": {
      icon: 'product.svg',
      dataServiceKey: "products",
      enable: true,
      enable_detail: true,
      default: false,
      // singlePermission: "productdetail:r",
      routes: {
        list: 'products/{type}',
        detail: 'products/{type}/{slug}'
      },
      subareas: {
        "products": {
          default: true,
          key: "products",
          dataServiceKey: "",
          enable: true,
          enable_detail: true
        }
      }
    },
    "casehistories": {
      icon: 'casehistory.svg',
      dataServiceKey: "projects",
      enable: true,
      enable_detail: true,
      default: false,
      // singlePermission: "projectdetail:r",
      routes: {
        list: 'casehistories',
        detail: 'casehistories/{slug}'
      },
      subareas: {
        "casehistories": {
          default: true,
          key: "casehistories",
          dataServiceKey: "",
          enable: true,
          enable_detail: true
        }
      }
    },
    "events": {
      icon: 'event.svg',
      dataServiceKey: "events",
      enable: false,
      enable_detail: true,
      default: false,
      routes: {
        list: 'events/{type}',
        detail: 'events/{type}/{slug}'
      },
      subareas: {
        "virtual": {
          default: false,
          key: "virtual",
          dataServiceKey: "virtual",
          enable: true,
          enable_detail: true
        },
        "indoor": {
          default: true,
          key: "indoor",
          dataServiceKey: "indoor",
          enable: true,
          enable_detail: true
        },
        "outdoor": {
          default: false,
          key: "outdoor",
          dataServiceKey: "outdoor",
          enable: true,
          enable_detail: false
        }
      }
    },
    "subcompanies": {
      icon: 'casehistory.svg',
      dataServiceKey: "architects",
      enable: true,
      enable_detail: true,
      default: false,
      // singlePermission: "projectdetail:r",
      routes: {
        list: 'subcompanies',
        detail: 'subcompanies/{slug}'
      },
      subareas: {
        "subcompanies": {
          default: true,
          key: "subcompanies",
          dataServiceKey: "",
          enable: true,
          enable_detail: true
        }
      }
    },
    "subcasehistories": {
      icon: 'casehistory.svg',
      dataServiceKey: "projects-architects",
      enable: true,
      enable_detail: true,
      default: false,
      // singlePermission: "projectdetail:r",
      routes: {
        list: 'subcasehistories',
        detail: 'subcasehistories/{slug}'
      },
      subareas: {
        "subcasehistories": {
          default: true,
          key: "subcasehistories",
          dataServiceKey: "",
          enable: true,
          enable_detail: true
        }
      }
    },
    "people": {
      icon: 'people.svg',
      dataServiceKey: "people",
      enable: true,
      enable_detail: false,
      default: false,
      // singlePermission: "projectdetail:r",
      routes: {
        list: 'people',
        detail: 'people/{slug}'
      },
      subareas: {
        "people": {
          default: true,
          key: "people",
          dataServiceKey: "",
          enable: true,
          enable_detail: false
        }
      }
    },
  },
  accountSettings: {
    theme: "marmomac",
    proAccount: false
  },
  chatConfig: {
    default: {
      pluginEnv: 'staging',
      wsEndpoint: 'wss://qh2k5m2qw1.execute-api.eu-west-1.amazonaws.com/test/',
      apiUrl: 'https://11o2teju4f.execute-api.eu-west-1.amazonaws.com/staging/mmp/',
      favoritesUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/favorites',
      recentsUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/recents',
      accountUrl: 'https://deevent-api-dev.24apps.it/api/v2/account/public'
    },
    'recat-mdm-test.firebaseapp.com': {
      pluginEnv: 'staging',
      wsEndpoint: 'wss://qh2k5m2qw1.execute-api.eu-west-1.amazonaws.com/test/',
      apiUrl: 'https://11o2teju4f.execute-api.eu-west-1.amazonaws.com/staging/mmp/',
      favoritesUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/favorites',
      recentsUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/recents',
      accountUrl: 'https://deevent-api-dev.24apps.it/api/v2/account/public'
    },
    'catalogo.marmomac.it': {
      pluginEnv: 'production',
      wsEndpoint: 'wss://chat.quickchat.online/vrf/',
      apiUrl: 'https://l1iq9e6hm6.execute-api.eu-west-1.amazonaws.com/production/mmp/',
      favoritesUrl: 'https://deevent-api.24apps.it/api/v2/userdata/public/favorites',
      recentsUrl: 'https://deevent-api.24apps.it/api/v2/userdata/public/recents',
      accountUrl: 'https://deevent-api.24apps.it/api/v2/account/public'
    },
    'marmomacplus.com': {
      pluginEnv: 'production',
      wsEndpoint: 'wss://chat.quickchat.online/vrf/',
      apiUrl: 'https://l1iq9e6hm6.execute-api.eu-west-1.amazonaws.com/production/mmp/',
      favoritesUrl: 'https://deevent-api.24apps.it/api/v2/userdata/public/favorites',
      recentsUrl: 'https://deevent-api.24apps.it/api/v2/userdata/public/recents',
      accountUrl: 'https://deevent-api.24apps.it/api/v2/account/public'
    }
  },
  awsAnalytics: {
    localhost: null,
    default: {
      area: "staging",
      credentials: {
        accessKeyId: "AKIATLSVYBWBKK4LNFSX",
        secretAccessKey: "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"
      },
      region: "eu-west-1",
      deliveryStreamName: "testCatalogoWebFirehoseDelivery"
    },
    'marmomacplus.com': {
      area: "production",
      credentials: {
        accessKeyId: "AKIAUCEJSNJLQTNPVR7F",
        secretAccessKey: "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"
      },
      region: "eu-west-1",
      deliveryStreamName: "MMPCatalogoWebFirehoseDeliveryStream"
    }
  },
  "needPermissionTo": ["filters", "products-list", "products-detail", "casehistories-list", "casehistories-detail"]
};
