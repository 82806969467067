import produce from "immer"
import { omit } from 'lodash';
import {handleActions} from 'redux-actions';
import {
  archiveRoom,
  contactCompany,
  // getRooms,
  listMessages,
  roomList,
  messageReceived,
  search,
  sendMessage,
  setAttendee,
  setCurrent,
  setTab,
  waitingList, loadCompanyData, closeChat, getCompanyActive
} from './actions'

const INITIAL_STATE = {
  rooms: [],
  waitingList: [],
  isLoadingRooms: false,
  current: null,
  isLoadingMessages: false,
  error: null,
  isSending: false,
  messages: null,
  attendee: null,
  tab: 'pending',
  search: '',
  unreadMessages: null,
  companyData: {},
  activeCompanies: {}
};

const reducer = handleActions({

  [setAttendee.SUCCESS]: (state, {payload}) => produce(state, draft => {
    draft.attendee = payload;
  }),

  [roomList.TRIGGER]: (state, {payload}) => produce(state, draft => {

    // let rooms = payload.map(room => {
    //   const search = state.rooms.find(r => (room.hasOwnProperty('roomId') && room.roomId === r.id) || (!room.hasOwnProperty('roomId') && room.userId === r.userId && room.companyId === r.companyId));
    //   if (search) return search;
    //   return room;
    // });

    draft.rooms = payload;

    if (state.current) {
      const activatedRoom = payload
        .filter(room => room.roomStatus === 'ACTIVE')
        .find(room => (state.current.type === 'company' && state.current.id === room.companyId) || (state.current.type !== 'company' && state.current.id === room.userId));

      if (activatedRoom) {
        const isAgent = state.attendee.attendeeType === 'AGENT';
        draft.unreadMessages = omit(state.unreadMessage, [isAgent ? activatedRoom.userId: activatedRoom.companyId]);
        draft.current = {
          id: activatedRoom.roomId,
          type: 'room'
        };
        draft.tab = 'active';
      }

      const archivedRoom = payload.filter(room => room.roomStatus === 'CLOSED').find(room => state.current.id === room.id);
      if (archivedRoom) {
        draft.tab = 'archived';
      }
    }
  }),

  // [roomList.SUCCESS]: (state, {payload}) => produce(state, draft => {
  //   draft.rooms = payload;
  //   draft.isLoadingRooms = false;
  // }),

  [roomList.FAILURE]: (state, {payload}) => produce(state, draft => {
    draft.isLoadingRooms = false;
    draft.error = payload
  }),

  [setCurrent.TRIGGER]: (state, {payload: {id, type, tab}}) => produce(state, draft => {

    draft.current = {
      id,
      type
    };

    if(state.unreadMessages && state.unreadMessages.hasOwnProperty(id)) {
      draft.unreadMessages = omit(state.unreadMessages, [id])
    }

    if (tab) {
      draft.tab = tab;
    }
  }),

  [sendMessage.TRIGGER]: (state) => produce(state, draft => {
    draft.isSending = true;
  }),

  [sendMessage.SUCCESS]: (state) => produce(state, draft => {
    draft.isSending = false;
  }),

  [listMessages.SUCCESS]: (state, {payload}) => produce(state, draft => {
    draft.messages = payload;
  }),

  [setTab.TRIGGER]: (state, {payload}) => produce(state, draft => {
    draft.tab = payload;
  }),

  [archiveRoom.TRIGGER]: (state, {payload}) => produce(state, draft => {
    draft.tab = 'archived';
  }),

  [contactCompany.TRIGGER]: (state, {payload}) => produce(state, draft => {
    draft.tab = 'pending';
    draft.current = {
      id: payload.companyId,
      type: 'company'
    }
  }),

  [search.TRIGGER]: (state, {payload}) => produce(state, draft => {
    draft.search = payload;
  }),

  [messageReceived.SUCCESS]: (state, {payload: {roomId}}) => produce(state, draft => {
    if (!state.current || state.current.id !== roomId) {
      const roomExists = state.unreadMessages ? Object.keys(state.unreadMessages).find(key => key === roomId) : false;
      draft.unreadMessages = {...state.unreadMessages, [roomId]: !roomExists ? 1 : draft.unreadMessages[roomId] + 1}
    }
  }),

  [waitingList.TRIGGER]: (state, {payload}) => produce(state, draft => {
    const isAgent = state.attendee && state.attendee.attendeeType === 'AGENT';
    const temp = payload.map((item) => ({...item, id: isAgent ? item.userId : item.companyId}));
    draft.waitingList = temp;
    if (isAgent) {
      draft.unreadMessages = temp.reduce((acc, item) => (!acc.hasOwnProperty(item.id) ? ({...acc, [item.id]: 1}) : (acc)), state.unreadMessages ? state.unreadMessages : {})
    }
  }),

  [loadCompanyData.SUCCESS]: (state, {payload}) => produce(state, draft => {
    draft.companyData = {...state.companyData, ...payload};
  }),

  [closeChat.TRIGGER]: (state) => produce(state, draft => {
    draft.current = null;
  }),

  [getCompanyActive.SUCCESS]: (state, {payload}) => produce(state, draft => {
    draft.activeCompanies = {...state.activeCompanies, [payload.companyId]: payload.success};
  }),

}, INITIAL_STATE);

export default reducer;
