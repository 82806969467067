import React from 'react';
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { withTheme } from './../../themes';
import { getLayout } from './../../store/settings/selectors';
import {LAYOUT_XS, LAYOUT_SM} from './../../settings';

const Row  = styled.div`
	display: flex;
	flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
	justify-content: space-between;
	align-items: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "left" : "center"};
	padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  border-bottom: ${props => props.borderSize + "px " + props.borderStyle + " " + props.borderColor};
`;
const Label  = styled.div`
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  color: ${props => props.textColor};
	margin-right: 15px;
`;
const Value  = styled.div`
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  color: ${props => props.textColor};
	text-align: flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "left" : "right"};;
`;
const Children  = styled.div`
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  color: ${props => props.textColor};
`;


const InfoRow = ({
	variant = "default",
	label,
	value,
	children,
	theme
}) => {
  const layout = useSelector(getLayout);
	return (
		<>
			<Row
				padding={theme.infoRow.bar.padding}
				borderStyle={theme.infoRow.bar.borderStyle}
				borderSize={theme.infoRow.bar.borderSize}
				borderColor={theme.infoRow.variant[variant].bar.borderColor}
				layout={layout}
			>
				<Label
					textFont={theme.infoRow.label.textFont}
					textSize={theme.infoRow.label.textSize}
					textWeight={theme.infoRow.label.textWeight}
					textLineHeight={theme.infoRow.label.textLineHeight}
					textLetterSpacing={theme.infoRow.label.textLetterSpacing}
					textTransform={theme.infoRow.label.textTransform}
					textColor={theme.infoRow.variant[variant].label.textColor}
				>{label}</Label>
				<Value
					textFont={theme.infoRow.value.textFont}
					textSize={theme.infoRow.value.textSize}
					textWeight={theme.infoRow.value.textWeight}
					textLineHeight={theme.infoRow.value.textLineHeight}
					textLetterSpacing={theme.infoRow.value.textLetterSpacing}
					textTransform={theme.infoRow.value.textTransform}
					textColor={theme.infoRow.variant[variant].value.textColor}
				>{value}</Value>
			</Row>
			{ children ?
				<Children
					padding={theme.infoRow.children.padding}
					textFont={theme.infoRow.children.textFont}
					textSize={theme.infoRow.children.textSize}
					textWeight={theme.infoRow.children.textWeight}
					textLineHeight={theme.infoRow.children.textLineHeight}
					textLetterSpacing={theme.infoRow.children.textLetterSpacing}
					textTransform={theme.infoRow.children.textTransform}
					textColor={theme.infoRow.variant[variant].children.textColor}
				>
					{children}
				</Children>
			: "" }
		</>
	)
}

InfoRow.InfoRow = {
	variant: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string
}

export default withTheme(InfoRow)
