import React from 'react';
import styled from 'styled-components';
import spinner from '../../assets/loader.gif';

const Wrapper = styled.div`
    display: inline-block;
    ${({size}) => !size ? 'width: 100%;height: 100vh;' : ''}
    ${({size}) => size ? ('width: ' + size.value + size.unit + ';') : ''}
    ${({size}) => size ? ('height: ' + size.value + size.unit + ';') : ''}
    ${({size}) => size ? 'background-size: 100% auto !important;' : ''}
    background: url(${spinner}) center no-repeat;
`;

const Loader = ({size}) => {
  return <Wrapper size={size} />
}

export default Loader;
