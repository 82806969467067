import React, {useState} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import {showPlansModal} from "../../../store/settings/actions";
import {getArea, getConfig, getLayout} from "./../../../store/settings/selectors";
import {getLocale, getTranslations} from "./../../../store/localization/selectors";
import {getUser} from "./../../../store/auth/selectors";
import {setStatus, showFullModal} from "./../../../store/settings/actions";

import Logo from "./../../App/Logo";
import UserTag from "./../../User/UserTag";
import Item from "./Item";
import ItemExternal from "./ItemExternal";
import Icon from "./../../App/Icon";
import {useTheme} from "./../../../themes";

import {LAYOUT_SM, LAYOUT_XS} from './../../../settings';
import {getPermissions} from "../../../store/auth/selectors";

import Wizard from "./../../Common/Wizard";

const Wrapper = styled.div`
  overflow: visible;
  width: 100%;
`;
const Bar = styled.div`
  background: ${props => props.background};
  color: ${props => props.textColor};
  border-bottom: ${props => props.borderBottom};
  ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "bottom: 0px;" : "top: 0px;"};
  
  z-index: 1079;
  

  ${props => props.compact ? `
    
  `:`
    position: absolute;
    left: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ` + props.height + `;
    box-shadow: ` + props.boxShadow+ `;
    padding: 0px ` + (props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? 15 : 25) + `px;
  `}

`;
const Left = styled.div`
  ${props => props.compact ? `
  
`:`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  width: 50%;
`}
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
`;
const LogoWrapper = styled.div`
  
  ${props => props.compact ? `
    padding-top: 15px;
    padding-left: 25px;
  `:`
  
  `}
`;
const Items = styled.div`
  padding: 0px ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? 5 : 25}px;
  

  ${props => props.compact ? `
    & * {text-align: left !important;}
    margin-bottom: 20px;
  `:`
    display: flex;
    align-items: center;
    overflow-x: auto;
    flex-grow: 1;
    flex-shrink: 1;
  `}

  overflow: visible;
`;
const ToggleMore = styled.div`
  cursor: pointer;

`;
const More = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: #fff;
  z-index: 9999;
  margin-bottom: ${props => props.isOpen ? 0 : -100}%;
  transition: all 0.25s ease-in-out;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border: 1px solid #dedede;
  padding: 25px 15px 25px;
`;
const MoreShadow = styled.div`
  position: fixed;
  left: 0px;
  width: 100%;
  height: 200%;
  /* background: linear-gradient(0deg, rgba(0,0,0,0.33) 0%, rgba(0,0,0,0.33) 50%, rgba(0,0,0,0) 100%); */
  z-index: 9998;
  top: ${props => props.isOpen ? -100 : 100}vh;
  transition: all 0.15s ease-in-out;
`;
// const MoreItem = styled.div`
//   cursor: pointer;
//   height: 80px;
// `;
const LangSelector = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  color: ${props => props.style.color};
  font-weight: 600;
  cursor: pointer;
  border: ${props => props.style.borderSize}px ${props => props.style.borderStyle} ${props => props.style.borderColor};
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const WizardToggle = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  color: #585858;
  cursor: pointer;
  border: 2px solid #585858;
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const CompactRight = styled.div`
  float: right;
  padding-top: 13px;
  padding-right: 25px;
`;


const SubMenu = styled.div`
  position: relative;
  padding-right: 10px;

  ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? `` : `
    & > *:last-child{
      display: none;
    }
    &:hover > *:last-child{
      display: block;
    }
  `}

  
`;
const SubMenuIcon = styled.div`
  ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? `
    display: none;
  ` : `
    position: absolute;
    top: 5px;
    right: 0px;
  `}
`;
const SubItems = styled.div`


  ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? `
    padding-left: 20px;
  ` : `
    position: absolute;
    top: 30px;
    left: 0px;
    background-color: #ffffff;
    width: 100%;
    border: 1px solid #EDEDED;
    box-shadow: 0px 3px 5px #0000000F;

    & > *:not(:last-child) > * {
      border-bottom: 1px solid #EDEDED;
    }
  `}

`;



const Topbar = ({compact = false, onClick}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const config = useSelector(getConfig);
  const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);
  const layout = useSelector(getLayout);
  let area = useSelector(getArea);
  const user = useSelector(getUser);
  const permissions = useSelector(getPermissions);
  const { pathname } = useLocation();
  // let location = useLocation();


  const [isOpen, setIsOpen] = useState(false);
  const activeAreas = Object.keys(config.areas).filter(areaKey => config.areas[areaKey].enable && (Object.keys(config.areas[areaKey].subareas).filter(subareaKey => config.areas[areaKey].subareas[subareaKey].enable).length > 0));

  function onAreaClick(e, area) {
    const areaConfig = config.areas[area];
    const needPermission = config?.needPermissionTo && config.needPermissionTo.indexOf(`${area}-list`) !== -1;

    if (needPermission && config.enable_login && (areaConfig.hasOwnProperty('singlePermission') && areaConfig.singlePermission !== '' && permissions.indexOf(areaConfig.singlePermission) === -1)) {
      e.preventDefault();
      dispatch(showPlansModal());
    }
  }

  if(pathname.indexOf('bcards') !== -1)
    area = 'bcards';

  return (
    <Wrapper>
      <Bar
        height={theme.topbar.height.value + theme.topbar.height.unit}
        layout={layout}
        background={theme.topbar.background}
        boxShadow={theme.topbar.boxShadow}
        textColor={theme.topbar.textColor}
        borderBottom={theme.topbar.borderBottom}
        compact={compact}
      >
        <Left compact={compact}>
          {compact ?
            <CompactRight>
              <UserTag
                full={config.tenant === "MDM" ? false : true}
                color={theme.topbar.textColor}
                size={{"value": 30, "unit": "px"}}
              />
            </CompactRight>
          : null }
          <Link to={`/${lang}`} onClick={(e) => {
                  dispatch(setStatus({
                    isUserpanelOpen: false
                  }), [dispatch]);

              }}>
            <LogoWrapper compact={compact}>
              <Logo
                variant={theme.topbar.logo.variant}
                width={compact ? null : theme.topbar.logo.width}
                height={compact ? {"value": 25, "unit": "px"} : theme.topbar.logo.height}
              />
            </LogoWrapper>
          </Link>
          {compact ?
            <><br/></>
          : null}
          <Items layout={layout} id="Items" compact={compact}>
            {activeAreas.map(areaKey => {
              const { subareas } = config.areas[areaKey];
              const defaultSubarea = Object.keys(subareas).length > 1 ? Object.keys(subareas).find(subareaKey => subareas[subareaKey].default) : null;
              if(config.tenant !== "MDM" || (areaKey !== "subcompanies" && areaKey !== "subcasehistories")){
                if( config.areas[areaKey].enable &&  config.areas[areaKey].enable_detail){
                  return (<Item onClick={(e) => {
                    onAreaClick(e, areaKey);
                    dispatch(setStatus({
                      isUserpanelOpen: false
                    }), [dispatch]);  
                  }} isActive={areaKey === area} layout={layout} key={`link-${areaKey}`} to={`/${lang}/${areaKey}${defaultSubarea ? ('/' + defaultSubarea) : ''}`}>{translations["UI"]["_label-" + areaKey]}</Item>);
                }else{
                  return null;
                }                
              }else{
                return null;
              }
            })}
            
            {config.tenant === "MDM" ?
              <SubMenu layout={layout}>
                <Item onClick={(e) => {
                  onAreaClick(e, "subcompanies");
                  dispatch(setStatus({
                    isUserpanelOpen: false
                  }), [dispatch]);

                }} isActive={"subcompanies" === area || "subcasehistories" === area} forceActive={"subcompanies" === area || "subcasehistories" === area} layout={layout} to={`/${lang}/subcompanies`}>{translations["UI"]["_label-sub"]} <SubMenuIcon layout={layout}><Icon name={"arrow-bottom-2"} color={"#585858"} size={{value: 20, unit: 'px'}} /></SubMenuIcon></Item>

                <SubItems layout={layout}>
                  <Item onClick={(e) => {
                    onAreaClick(e, "subcompanies");
                    dispatch(setStatus({
                      isUserpanelOpen: false
                    }), [dispatch]);

                  }} isActive={"subcompanies" === area} layout={layout} to={`/${lang}/subcompanies`}>{translations["UI"]["_label-subcompanies"]}</Item>
                  <Item onClick={(e) => {
                      onAreaClick(e, "subcasehistories");
                      dispatch(setStatus({
                        isUserpanelOpen: false
                      }), [dispatch]);

                    }} isActive={"subcasehistories" === area} layout={layout} to={`/${lang}/subcasehistories`}>{translations["UI"]["_label-subcasehistories"]}</Item>
                  </SubItems>
              </SubMenu>             
            : null }

            {layout === LAYOUT_XS || layout === LAYOUT_SM ?
              <>
                {config.tenant === "MDM" ?
                  <ItemExternal to="https://liveshop.marmomac.com/">{translations["UI"]["Live Streaming"]}</ItemExternal>
                : null }
              </>
            :
              <>
                {config.enable_bcards ?
                  <Item isDisabled={!user} isActive={"bcards" === area} to={`/${lang}/bcards`}>{translations["UI"]["Contacts"]}</Item>
                : null }
                {config.tenant === "MDM" ?
                  <ItemExternal to="https://liveshop.marmomac.com/">{translations["UI"]["Live Streaming"]}</ItemExternal>
                : null }
                {/*<Item isActive={"meeting" === area} to={`/${lang}/meeting`}>Meeting</Item>*/}
              </>
            }
          </Items>
        </Left>
        <Right>
          {layout === LAYOUT_XS || layout === LAYOUT_SM ?
            (config.enable_bcards || false ?
              <ToggleMore onClick={() => setIsOpen(!isOpen)}><Icon color={"#000000"} name={theme.icons.set.more} size={{value: 25, unit: "px"}} /></ToggleMore>
            : null)
          :
            <>
              {config.enable_wizard ?
                <WizardToggle onClick={() => dispatch(showFullModal(<Wizard tab={area} />))}>
                  <Icon name={"info-1"} color={"#585858"} size={{value: 20, unit: 'px'}} />
                </WizardToggle>
              : null }
              <LangSelector style={theme.topbar.language} onClick={() => dispatch(setStatus({
                isUserpanelOpen: true,
                userpanelSection: 1
              }), [dispatch])}>{lang}</LangSelector>
              <UserTag
                full={config.tenant === "MDM" ? false : true}
                color={theme.topbar.textColor}
              />
            </>
          }
        </Right>
      </Bar>
      {layout === LAYOUT_XS || layout === LAYOUT_SM ?
        (config.enable_bcards || false ?
          <>
            <More isOpen={isOpen}>
              {config.enable_bcards ?
                <Item isDisabled={!user} isActive={"bcards" === area} to={`/${lang}/bcards`}>{translations["UI"]["Contacts"]}</Item>
              : null}
              {/*<Item isActive={"meeting" === area} to={`/${lang}/meeting`}>Meeting</Item>*/}
            </More>
            <MoreShadow isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
          </>
        : null)
      : null }
    </Wrapper>
  )
}

export default Topbar;
