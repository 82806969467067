import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { useTheme } from './../../themes';

const Wrapper  = styled.div`
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  border: ${props => props.borderSize + "px " + props.borderStyle + " " + props.borderColor};
  border-radius: ${props => props.borderRadius.value + props.borderRadius.unit};
  background: ${props => props.background};
  color: ${props => props.textColor};

  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};

  text-align: center;

  display: inline-flex;

  & > *{
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

const Badge = ({variant = "default", children}) => {
  const theme = useTheme()
  return (
    <Wrapper
      padding={theme.badge.padding}
      background={theme.badge.variant[variant].background}
      borderStyle={theme.badge.borderStyle}
      borderSize={theme.badge.borderSize}
      borderColor={theme.badge.variant[variant].borderColor}
      borderRadius={theme.badge.borderRadius}
      textFont={theme.badge.textFont}
      textColor={theme.badge.variant[variant].textColor}
      textSize={theme.badge.textSize}
      textWeight={theme.badge.textWeight}
      textLineHeight={theme.badge.textLineHeight}
      textLetterSpacing={theme.badge.textLetterSpacing}
      textTransform={theme.badge.textTransform}
    >
      {children}
    </Wrapper>
  )
}

Badge.propTypes = {
  variant: PropTypes.string
}

export default React.memo(Badge)
