import { takeLatest, put, all, call, select } from 'redux-saga/effects'
// import { push } from 'connected-react-router'
import { init as initChat} from "../chat/actions";
import {login, check, logout, setFavorite, getFavorites, setRecents, getPlan} from './actions'
import { getRecords as getBCards } from "../bcard/actions";
import auth0 from '../../data/network/auth'
import {getUser, hasPermissionTo} from "./selectors";
import {getLocale} from "../localization/selectors";
import {PERMISSION_FAVOURITES} from "./permissions";
import {toggleModal} from "../settings/actions";
// import {useSelector} from "react-redux";
// import i18n from '../../locales'
import FavoritesService from "./../../services/FavoritesService";
// import {getConfig} from "../settings/selectors";

// import api from '../../data/network'
// import { HOME } from '../../navigation/routes'

function* loginSaga({ payload }) {
  // codice aync
  try {
    // const { config } = payload

    // effettua la chiamata di rete per il login
    const locale = yield select(getLocale);
    const user = yield call([auth0, auth0.login], locale);

    // manda la action success
    yield call(login.success(user));

    // manda l'utente in home
    // yield put(push(HOME))

  } catch(e) {
    console.log(e)

    // manda la action failure
    // yield put(login.failure(i18n.t("Username or password invalid")))
    yield put(login.failure("Failed"))
  }
}

function* checkSaga({ payload }) {
  try {
    // const { config } = payload

    const user = yield call([auth0, auth0.check]);
    if (user) {
      yield put(check.success(user));
      yield put(initChat.trigger(user));
      yield put(getBCards.trigger());
      try {
        let favorites = [
          ...yield call(FavoritesService.getList, user.accessToken, "companies"),
          ...yield call(FavoritesService.getList, user.accessToken, "products"),
          ...yield call(FavoritesService.getList, user.accessToken, "events"),
          ...yield call(FavoritesService.getList, user.accessToken, "casehistories")
        ];
        yield put(getFavorites.success(favorites))
      } catch (e) {
        console.log(e)
        // yield put(login.failure(i18n.t("Failed")))
        yield put(getFavorites.failure("Failed"))
      }
    } else {
      yield put(check.failure("Failed"))
    }
  } catch(e) {
    console.log('error')
    // yield put(login.failure(i18n.t("Failed")))
    yield put(check.failure("Failed"))
  }
}

function* logoutSaga({ payload }) {
  try {
    // const { config } = payload
    const user = yield call([auth0, auth0.logout])
    yield put(logout.success(user))
    // yield put(setFavorite.success(null))

  } catch(e) {
    console.log(e)
    // yield put(login.failure(i18n.t("Failed")))
    yield put(logout.failure("Failed"))
  }
}

function* setFavoriteSaga({ payload }) {

  // const config = yield select(getConfig);
  const hasPermission = yield select(hasPermissionTo(PERMISSION_FAVOURITES));

  if (!hasPermission) {
    yield put(toggleModal('plans'))
  } else {
    try {

      // const favAreas = {
      //   "companies": "exhibitor",
      //   "products": "product",
      //   "events": "event",
      //   "casehistories": "project",
    // "architect": "architect",
    // "architects": "architect",
    // "project-architects": "project-architects",
    // "projects-architects": "project-architects",
      //   "subcompanies": "architect",
      //   "subcasehistories": "project-architects",
      // };

      // const favorites = yield select(getFavorites);
      const user = yield select(getUser);
      const { area, idElement, isFavorite } = payload;
      if (isFavorite) {
        // console.log("remove");
        yield call(FavoritesService.remove, user.accessToken, user.id, area, idElement);
        yield put(setFavorite.success({"status": false, "item": { itemType: area, itemId: idElement, userId: user.id }}))
      } else {
        // console.log("add");
        yield call(FavoritesService.add, user.accessToken, user.id, area, idElement);
        yield put(setFavorite.success({"status": true, "item": { itemType: area, itemId: idElement, userId: user.id }}))
      }
    } catch(e) {
      console.log(e)
      // yield put(login.failure(i18n.t("Failed")))
      yield put(setFavorite.failure("Failed"))
    }
  }
}

function* setRecentsSaga({ payload }) {
  try {
    yield put(setRecents.success(payload))

  } catch(e) {
    console.log(e)
    // yield put(login.failure(i18n.t("Failed")))
    // yield put(setFavorite.failure("Failed"))
  }
}

function* getPlanSaga({ payload }) {

  // const user = yield select(getUser);
  // const locale = yield select(getLocale)

}

export default function* root() {
  yield all([
    yield takeLatest([login.TRIGGER], loginSaga),
    yield takeLatest([check.TRIGGER], checkSaga),
    yield takeLatest([logout.TRIGGER], logoutSaga),
    yield takeLatest([setFavorite.TRIGGER], setFavoriteSaga),
    yield takeLatest([setRecents.TRIGGER], setRecentsSaga),
    yield takeLatest([getPlan.TRIGGER], getPlanSaga),
  ])
}
