import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import ListColumn from "./ListColumn";
import Button from "./Button";
import {useSelector} from "react-redux";
import {getTranslations} from "../../../store/localization/selectors";
// import {useTheme} from "../../../themes";

const Wrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  border-radius: 10px;
  background: #f2f4f4;
  padding: 30px 15px;
  max-height: calc(100vh - 30px);
  overflow: auto;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Columns = styled.div`
  @media(min-width: 992px) {
    display: flex;
    /* margin-right: 25%; */
  }
`;



export default function List({onClick, onClose, canClose, roles}) {

  const isBasic = roles ? roles.indexOf('userBasic') !== -1 : false;
  const translations = useSelector(getTranslations);
  const signinLabel = translations['UI']['SignIn'];

  const items = [
    translations['UI']['_plan-info-favorites'],
  ];

  const description = translations['UI']['PlansDescription'];

  return (
    <Wrapper>
      <div style={{ position: 'relative '}}>
        {canClose ? <Button style={{ position: 'absolute', right: '20px', top: '-5px'}} type="close" onClick={onClose} /> : null}
        <Container>
          <Header>
          </Header>
          <Columns>
            <ListColumn isList items={items} />
            <ListColumn signInLabel={signinLabel} onClick={() => onClick('basic')} isSelected={isBasic} actives={[1,1,1,0,0,0]} title={translations['UI']['_plan-lv1-name']} subtitle={translations['UI']['_plan-lv1-subtitle']} items={items} />
            <ListColumn isList={true} isText={true} isLast>
              <p>{ReactHtmlParser(description)}</p>
            </ListColumn>
          </Columns>
        </Container>
      </div>
    </Wrapper>
  );

}
