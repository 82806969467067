import React from 'react';
import styled from 'styled-components';
import Icon from '../../../components/App/Icon';

const Wrapper = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border: none;
  padding: 4px 0 0 0;
  background: ${props => props.type === 'close' ? '#fff' : 'transparent'};
  cursor: pointer;
  z-index: 1;
`;

export default function Button({ onClick, type = 'close', style }) {

  return (
    <Wrapper onClick={onClick} style={style} type={type}>
      <Icon name={type === 'close' ? 'close-1' : 'arrow-left-2'} size={{value: 30, unit: 'px'}} />
    </Wrapper>
  )

}
