export const SEPARATOR_ORDERVALUE = "|";
export const SEPARATOR_MULTIFILTER = "+";

export const LAYOUT_DESKTOP = "D";
export const LAYOUT_MOBILE = "M";
export const BREAKPOINT_MOBILE = 768;

export const BREAKPOINT_XS = 768 - 1;
export const BREAKPOINT_SM = 992 - 1;
export const BREAKPOINT_MD = 1400 - 1;

export const LAYOUT_XS = "XS";
export const LAYOUT_SM = "SM";
export const LAYOUT_MD = "MD";
export const LAYOUT_LG = "LG";

export const RECORDS_PER_PAGE = 20;
