import auth0 from 'auth0-js'
// import axios from "axios";
// import AWS from 'aws-sdk'
import {DOMAINS} from "./../../domains"
// import store from './../../store/store'

const BackendClient = (webAuth, tenant = null) => {

  const login = (locale = 'it') => {
    if (locale !== 'it') locale = 'en';
    let redirectUri = `${window.location.origin}/authorization?url=${window.location.href}`;

    console.log('tenant', tenant);

    if (tenant === 'MDM') {
        if (window.location.href.indexOf('?') === -1)
            redirectUri = `${redirectUri}?show-welcome-modal`
        else
            redirectUri = `${redirectUri}&show-welcome-modal`
    }

    webAuth.authorize({
      redirectUri,
      ui_locales: locale
    });
  }

  const check = () => {

    return new Promise((resolve, reject) => {
      webAuth.checkSession({}, function (err, checkResult) {
      if(!checkResult){
        return webAuth.parseHash({ hash: window.location.hash }, function(err, authResult) {
           if (err) {
              return console.log(err);
           }
           if(authResult){
              webAuth.client.userInfo(authResult.accessToken, function(err, user) {
                let ret = {}
                ret.id = user["sub"]
                ret.email = user.email
                ret.firstname = user["https://veronafiere.it/claims/given_name"]
                ret.lastname = user["https://veronafiere.it/claims/family_name"]
                ret.avatar = user["picture"]
                ret.accessToken = authResult.accessToken
                ret.idToken = authResult.idToken
                // console.log(ret);
                resolve(ret)
              });
           }else{
             resolve(null)
           }
        });
      }else{
        return webAuth.client.userInfo(checkResult.accessToken, function(err, user) {
          let ret = {}
          ret.id = user["sub"]
          ret.firstname = user["https://veronafiere.it/claims/given_name"]
          ret.email = user.email
          ret.lastname = user["https://veronafiere.it/claims/family_name"]
          ret.avatar = user["picture"]
          ret.accessToken = checkResult.accessToken
          ret.idToken = checkResult.idToken
          // console.log(ret);
          resolve(ret)
        });
      }
     });

    });
  }

  const logout = () => {

    webAuth.logout({
       clientID: DOMAINS[window.location.hostname].auth0.clientID,
       returnTo: window.location.origin
    });
  }




  return {
    login,
    check,
    logout,
  }
}

const Backend = () => {
  if(DOMAINS[window.location.hostname].enable_login){
    const webAuth = new auth0.WebAuth({
      domain: DOMAINS[window.location.hostname].auth0.domain,
      clientID: DOMAINS[window.location.hostname].auth0.clientID,
      redirectUri: window.location.origin+"/authorization",
      responseType: DOMAINS[window.location.hostname].auth0.responseType,
      scope: DOMAINS[window.location.hostname].auth0.scope,
      audience: DOMAINS[window.location.hostname].auth0.audience,
      issuer: DOMAINS[window.location.hostname].auth0.issuer,
      leeway: 5*60
    });

    let tenant = null;
    try {
        tenant = DOMAINS[window.location.hostname].tenant;
    } catch (e) {}

    const client = BackendClient(webAuth, tenant)

    const login = client.login
    const check = client.check
    const logout = client.logout

    return {
      login,
      check,
      logout,
    }
  }else{
    return null;
  }
}
//
const auth = Backend()


export default auth
