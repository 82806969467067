import { LAYOUT_XS, LAYOUT_SM, LAYOUT_MD, LAYOUT_LG } from "./../../settings"

import logoDefault from "./assets/logo.svg";
import logoPlus from "./assets/logo-plus.svg";
import logoIcon from "./assets/logo-icon.svg";
import placeholderDefault from "./assets/placeholder.svg";
import placeholderUser from "./assets/user-placeholder.jpg";
import placeholderProduct from "./assets/placeholder.svg";
import placeholderCasehistory from "./assets/placeholder.svg";
import idleImage from "./assets/idlepage.svg";
// import idleImageCompanies from "./assets/idlepage-companies.svg";
// import idleImageProducts from "./assets/idlepage-products.svg";
// import idleImageEvents from "./assets/idlepage-events.svg";
// import idleImageCasehistories from "./assets/idlepage-casehistories.svg";
import faviconImage from "./assets/favicon.png";


import hallVoid from "./assets/hall-void.svg";
import hallP1 from "./assets/hall-P1.svg";
import hallP2 from "./assets/hall-P2.svg";
import hallP3 from "./assets/hall-P3.svg";
import hallP4 from "./assets/hall-P4.svg";
import hallP5 from "./assets/hall-P5.svg";
import hallP6 from "./assets/hall-P6.svg";
import hallP7 from "./assets/hall-P7.svg";
import hallP8 from "./assets/hall-P8.svg";
import hallP9 from "./assets/hall-P9.svg";
import hallP10 from "./assets/hall-P10.svg";
import hallP11 from "./assets/hall-P11.svg";
import hallP12 from "./assets/hall-P12.svg";
import hallPA from "./assets/hall-PA.svg";
import hallPB from "./assets/hall-PB.svg";
import hallPC from "./assets/hall-PC.svg";
import hallPD from "./assets/hall-PD.svg";
import hallPF from "./assets/hall-PF.svg";
import hallCSA from "./assets/hall-CSA.svg";
import hallCSC from "./assets/hall-CSC.svg";
import hallCSE from "./assets/hall-CSE.svg";
import hallCSS from "./assets/hall-CSS.svg";


export const AGR = {
  logo: {
    variant: {
      "default": logoDefault,
      "plus": logoPlus,
      "icon": logoIcon,
    }
  },
  halls:{
    "void": hallVoid,
    "1": hallP1,
    "1N": hallP1,
    "2": hallP2,
    "2N": hallP2,
    "3": hallP3,
    "3N": hallP3,
    "4": hallP4,
    "4N": hallP4,
    "5": hallP5,
    "5N": hallP5,
    "6": hallP6,
    "6N": hallP6,
    "7": hallP7,
    "7N": hallP7,
    "8": hallP8,
    "8N": hallP8,
    "9": hallP9,
    "9N": hallP9,
    "10": hallP10,
    "10N": hallP10,
    "11": hallP11,
    "11N": hallP11,
    "12": hallP12,
    "12N": hallP12,
    "A": hallPA,
    "B": hallPB,
    "C": hallPC,
    "D": hallPD,
    "F": hallPF,
    "CSA": hallCSA,
    "CSC": hallCSC,
    "CSE": hallCSE,
    "CSS": hallCSS
  },
  color: "#39b54a",
  favicon: faviconImage,
  loading:{
    variant: {
      "default": {
        color: "#39b54a"
      }
    }
  },
  placeholder:  placeholderDefault,
  placeholders: {
    default: placeholderDefault,
    user: placeholderUser,
    product: placeholderProduct,
    casehistory: placeholderCasehistory
  },
  background:{
    homepage: "linear-gradient(180deg, rgba(229,227,193,1) 0px, rgba(233,244,235,1) 200px, rgba(233,244,235,1) 100%)",
    global: "linear-gradient(180deg, rgba(229,227,193,1) 0px, rgba(233,244,235,1) 200px, rgba(233,244,235,1) 100%)"
  },
  icons: {
    set: {
      openFilters: "filters-1",
      closeFilters: "close-1",
      clearFilters: "trash-1",
      backFilters: "arrow-left-2",
      memo: "edit-1",
      contact: "contact-1",
      chat: "chat-1",
      calendar: "calendar-1",
      favorite: "favorite-5",
      homepage: "home-2",
      more: "more-1",
      list: "list-1",
      menuitemPrev: "arrow-left-2",
      menuitemNext: "arrow-right-2",
      language: "language-1",
      chunk: "arrow-bottom-2",
      login: "login-1",
      logout: "logout-1",
      profile: "fieragricola-1",
      checked: "tick-1",
      highlightedPost: "flag-1",
      recommendedPost: "recommended-1",
      timestampPost: "clock-1",
      menu: "menu-1",
      loading: "spinner-1",
      helpdesk: "helpdesk-1",
      legal: "legal"
    },
    scale: {
      normal: 0.7,
      wrapped: 0.5
    }
  },
  container: {
    [LAYOUT_XS]: {
      width: {
        value: "calc(100% - 30px)",
        unit: ""
      }
    },
    [LAYOUT_SM]: {
      width: {
        value: "calc(100% - 50px)",
        unit: ""
      }
    },
    [LAYOUT_MD]: {
      width: {
        value: "calc(100% - 50px)",
        unit: ""
      }
    },
    [LAYOUT_LG]: {
      width: {
        value: 990,
        unit: "px"
      }
    }
  },
  slider:{
    dots:{
      size: {
        value: 10,
        unit: "px"
      },
      spacing: {
        value: 10,
        unit: "px"
      },
      borderRadius: {
        value: 0,
        unit: "px"
      },
    },
    arrows:{
      width: {
        value: 40,
        unit: "px"
      },
      height: {
        value: 60,
        unit: "px"
      },
      size: {
        value: 20,
        unit: "px"
      },
      offset: {
        value: 20,
        unit: "px"
      },
      borderRadius: {
        value: 0,
        unit: "px"
      },
    },
    variant: {
      "default": {
        dots:{
          colorActive: "#39b54a",
          colorUnactive: "#dedede"
        },
        arrows:{
          background: "#ffffff",
          color: "#050505",
          border: "#EDEDED"
        }
      }
    }
  },
  userpanel:{
    background: "#F2F4F4",
    menuitemVariantBar: "grey",
    menuitemVariantItem: "default",
    version: {
      background: "#e6e6e6",
      color: "#999999"
    }
  },
  idlepage:{
    image: {
      companies: idleImage, //idleImageCompanies,
      products: idleImage, //idleImageProducts,
      events: idleImage, //idleImageEvents,
      casehistories: idleImage, //idleImageCasehistories,
      bcards: idleImage,
      chat: idleImage
    }

  },
  topbar:{
    height: {
      value: 50,
      unit: "px"
    },
    background: "#ffffff",
    borderBottom: "0px solid transparent",
    boxShadow: "0px 3px 5px #0000000F",
    textColor: "#000000",
    badgeVariant: "default",
    buttonVariant: "default",
    enable:{

    },
    logo:{
      variant: "icon",
      height: {
        value: 30,
        unit: "px"
      },
      width: {
        value: 30,
        unit: "px"
      },
      alignment: "C", // L C R
    },
    language:{
      borderSize: 2,
      borderStyle: "solid",
      borderColor: "#585858",
      color: "#585858",
    },
    icon:{
      normal:{
        color: "#39b54a",
        background: null
      },
      hover:{
        color: "#39b54a",
        background: null
      },
      active:{
        color: "#39b54a",
        background: null
      }
    },
    item:{
      padding: {
        top:{
          value: 6,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 6,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      textFont: null,
      textSize: 15,
      textWeight: 600,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
      borderSize: 0,
      borderStyle: "solid",
      borderRadius: {
        value: 4,
        unit: "px"
      },
      state:{
        normal:{
          textColor: "#000000",
          borderColor: "transparent",
          background: "transparent"
        },
        hover:{
          textColor: "#39b54a",
          borderColor: "transparent",
          background: "#9A9A9A1A"
        },
        active:{
          textColor: "#39b54a",
          borderColor: "transparent",
          background: "#9A9A9A1A"
        },
        disabled:{
          textColor: "##0000004d",
          borderColor: "transparent",
          background: "transparent"
        }
      }

    },

    desktop:{
      height: {
        value: 60,
        unit: "px"
      },
      background: "#ffffff",
      textColor: "#000000",
      badgeVariant: "default",
      enable:{
        website: true,
        homeButton: true,
        updateBadge: true
      },
      icon:{
        normal:{
          color: "#39b54a",
          background: null
        },
        hover:{
          color: "#39b54a",
          background: null
        },
        active:{
          color: "#39b54a",
          background: null
        }
      }
    },
    mobile:{
      height: {
        value: 60,
        unit: "px"
      },
      background: "#ffffff",
      textColor: "#000000",
      badgeVariant: "default",
      enable:{
        website: true,
        homeButton: true,
        updateBadge: true
      },
      logo:{
        variant: "default",
        height: {
          value: 40,
          unit: "px"
        },
        width: {
          value: 100,
          unit: "%"
        },
        alignment: "C", // L C R
      },
      icon:{
        normal:{
          color: "#39b54a",
          background: null
        },
        hover:{
          color: "#39b54a",
          background: null
        },
        active:{
          color: "#39b54a",
          background: null
        }
      }
    }
  },
  list:{
    width: {
      value: 380,
      unit: "px"
    },
    levelTransition: 0.3, // in seconds
    background: "transparent",
    textColor: "#000000",
    logo:{
      variant: "default",
      height: {
        value: 40,
        unit: "px"
      },
      width: {
        value: 340,
        unit: "px"
      },
      alignment: "C", // L C R
    },
    resultsTab: {
      button: {
        normal: {
          background: "transparent",
          color: "#000000"
        },
        active: {
          background: "#ffffff",
          color: "#000000"
        }
      }
    },
    search:{
      borderStyle: "solid",
      borderSize: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
      },
      borderColor: "transparent",
      borderRadius: {
        value: 4,
        unit: "px"
      },
      background: "#ffffff",
      textColor: "#000000",
      placeholderColor: "#ffffff",
      icon:{
        normal:{
          color: "#39b54a",
          background: "#ffffff"
        },
        hover:{
          color: "#39b54a",
          background: "#ffffff"
        },
        active:{
          color: "#fff",
          background: "#39b54a",
        }
      }
    }
  },
  filters: {
    width: {
      value: 380,
      unit: "px"
    },
    item: {
      menuitemVariant: "filter",
      badgeVariant: "filter"
    },
    search:{
      borderStyle: "solid",
      borderSize: 0,
      borderColor: "transparent",
      borderRadius: {
        value: 4,
        unit: "px"
      },
      background: "#ffffff",
      textColor: "#000000",
      placeholderColor: "#ffffff",
    },
    bar:{
      iconSize: {
        value: 40,
        unit: "px"
      },
      textSize: 14,
      textColor: "#ffffff",
      textWeight: "400",
      icon:{
        normal:{
          color: "#ffffff",
          background: "transparent"
        },
        hover:{
          color: "#ffffff",
          background: "#f6f6f699"
        },
        active:{
          color: "#ffffff",
          background: "f6f6f699"
        }
      }
    },
    tag:{
      icon:{
        color: "#ffffff"
      }
    },
    background: "#39b54a",
    buttonVariant: "default-inverse",

  },
  homepage: {
    menuitemVariant: "homepage",
    menuitemSize: "tiny",
    fullsize: true
  },
  actionbar:{
    borderStyle: "solid",
    borderSize: 1,
    borderRadius: {
      value: 4,
      unit: "px"
    },
    variant: {
      "default": {
        normal: {
          textColor: "#585858",
          iconColor: "#d4d4d4",
          borderColor: "#EDEDED",
          background: "#ffffff"
        },
        hover: {
          textColor: "#585858",
          iconColor: "#d4d4d4",
          borderColor: "#39b54a",
          background: "#ffffff"
        },
        active: {
          textColor: "#585858",
          iconColor: "#39b54a",
          borderColor: "#EDEDED",
          background: "#ffffff"
        },
        disabled: {
          textColor: "#58585880",
          iconColor: "#d4d4d480",
          borderColor: "#EDEDED80",
          background: "#ffffff80"
        }
      }
    }
  },
  chunk:{
    borderStyle: "solid",
    borderSize: 0,
    borderRadius: {
      value: 4,
      unit: "px"
    },
    padding:{
      [LAYOUT_XS]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 0,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_SM]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 0,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_MD]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 0,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_LG]: {
        top:{
          value: 20,
          unit: "px"
        },
        left:{
          value: 20,
          unit: "px"
        },
        bottom:{
          value: 0,
          unit: "px"
        },
        right:{
          value: 20,
          unit: "px"
        }
      }
    },
    bar: {
      height: {
        value: 60,
        unit: "px"
      },
      padding:{
        [LAYOUT_XS]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 15,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        },
        [LAYOUT_SM]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 15,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        },
        [LAYOUT_MD]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 15,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        },
        [LAYOUT_LG]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 20,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        }
      },
      textFont: null,
      textSize: 13,
      textWeight: 700,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
    },
    content: {
      borderStyle: "solid",
      borderSize: 0,
      padding:{
        [LAYOUT_XS]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 15,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        },
        [LAYOUT_SM]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 15,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        },
        [LAYOUT_MD]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 15,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        },
        [LAYOUT_LG]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 20,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        },
      },
      textFont: null,
      textSize: null,
      textWeight: null,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
    },
    variant: {
      "default":{
        borderColor: "transparent",
        boxShadow: "0px 3px 5px #0000000F",
        background: "#ffffff",
        bar: {
          background: null,
          textColor: "#39b54a",
          icon: {
            normal: {
              color: "#000000",
              background: null
            }
          }
        },
        content: {
          inforowVariant: "default",
          borderColor: "transparent",
          background: null,
          textColor: "#000000"
        }
      },
      "event":{
        borderColor: "transparent",
        boxShadow: "0px 3px 5px #0000000F",
        background: "#ffffff",
        bar: {
          background: null,
          textColor: "#39b54a",/*"#079192",*/
          icon: {
            normal: {
              color: "#39b54a",/*"#079192",*/
              background: null
            }
          }
        },
        content: {
          inforowVariant: "default",
          borderColor: "transparent",
          background: null,
          textColor: "#000000"
        }
      },
      "grey":{
        borderColor: "transparent",
        boxShadow: "0px 3px 5px #0000000F",
        background: "#DDDDDD",
        bar: {
          background: null,
          textColor: "#39b54a",
          icon: {
            normal: {
              color: "#39b54a",
              background: null
            }
          }
        },
        content: {
          inforowVariant: "default",
          borderColor: "transparent",
          background: null,
          textColor: "#39b54a"
        }
      },
      "event-grey":{
        borderColor: "transparent",
        boxShadow: "0px 3px 5px #0000000F",
        background: "#DDDDDD",
        bar: {
          background: null,
          textColor: "#39b54a",/*"#079192",*/
          icon: {
            normal: {
              color: "#39b54a",/*"#079192",*/
              background: null
            }
          }
        },
        content: {
          inforowVariant: "default",
          borderColor: "transparent",
          background: null,
          textColor: "#39b54a"/*"#079192"*/
        }
      },
      "dark":{
        borderColor: "transparent",
        boxShadow: "0px 3px 5px #0000000F",
        background: "#000000",
        bar: {
          background: null,
          textColor: "#ffffff",
          icon: {
            normal: {
              color: "#ffffff",
              background: null
            }
          }
        },
        content: {
          inforowVariant: "default",
          borderColor: "transparent",
          background: null,
          textColor: "#ffffff"
        }
      }
    }
  },
  post:{
    borderStyle: "solid",
    borderSize: 1,
    borderRadius: {
      value: 4,
      unit: "px"
    },
    padding:{
      [LAYOUT_XS]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 15,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_SM]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 15,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_MD]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 15,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_LG]: {
        top:{
          value: 20,
          unit: "px"
        },
        left:{
          value: 20,
          unit: "px"
        },
        bottom:{
          value: 20,
          unit: "px"
        },
        right:{
          value: 20,
          unit: "px"
        }
      }
    },
    tags: {
      textFont: null,
      textSize: 11,
      textWeight: null,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    title: {
      textFont: null,
      textSize: 15,
      textWeight: 700,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    content: {
      textFont: null,
      textSize: 13,
      textWeight: null,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    highlighted: {
      textFont: null,
      textSize: 13,
      textWeight: 700,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    recommended: {
      textFont: null,
      textSize: 13,
      textWeight: 700,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    footer: {
      textFont: null,
      textSize: 11,
      textWeight: null,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
      borderStyle: "solid",
      borderSize: 1
    },
    cta:{
      alignment: "left"
    },
    variant: {
      "default":{
        borderColor: "transparent",
        boxShadow: "0px 3px 5px #0000000F",
        background: "#ffffff",
        title: {
          textColor: "#000000"
        },
        content: {
          textColor: "#000000"
        },
        tags: {
          textColor: "#39b54a"
        },
        highlighted: {
          textColor: "#39b54a"
        },
        recommended: {
          textColor: "#39b54a"
        },
        footer: {
          textColor: "#000000",
          borderColor: "#d4d4d4",
        },
        buttonSize: "default",
        buttonVariant: "default-o"
      },
      "advertising":{
        borderColor: "#E5087E4B",
        boxShadow: "0px 3px 5px #0000000F",
        background: "#ffffff",
        title: {
          textColor: "#000000"
        },
        content: {
          textColor: "#000000"
        },
        tags: {
          textColor: "#39b54a"
        },
        highlighted: {
          textColor: "#39b54a"
        },
        recommended: {
          textColor: "#39b54a"
        },
        footer: {
          textColor: "#000000",
          borderColor: "#d4d4d4",
        },
        buttonSize: "default",
        buttonVariant: "default-o"
      },
    }
  },
  fullModal:{
    borderStyle: "solid",
    borderSize: 0,
    borderRadius: {
      value: 4,
      unit: "px"
    },
    padding:{
      [LAYOUT_XS]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 15,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_SM]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 15,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_MD]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 15,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_LG]: {
        top:{
          value: 20,
          unit: "px"
        },
        left:{
          value: 20,
          unit: "px"
        },
        bottom:{
          value: 20,
          unit: "px"
        },
        right:{
          value: 20,
          unit: "px"
        }
      }
    },
    variant: {
      "default":{
        borderColor: "transparent",
        boxShadow: "0px 3px 5px #0000000F",
        background: "#ffffff"
      },
    }
  },

  mobileheader:{
    textFont: null,
    textSize: null,
    textWeight: 600,
    textLineHeight: null,
    textLetterSpacing: null,
    textTransform: null,
    textColor: "#000000",
    icon: {
      normal: {
        color: "#000000",
        background: "transparent"
      }
    }
  },
  infoRow:{
    bar: {
      borderStyle: "solid",
      borderSize: 1,
      height: {
        value: 60,
        unit: "px"
      },
      padding: {
        top: {
          value: 15,
          unit: "px"
        },
        left: {
          value: 0,
          unit: "px"
        },
        bottom: {
          value: 15,
          unit: "px"
        },
        right: {
          value: 0,
          unit: "px"
        }
      }
    },
    label: {
      textFont: null,
      textSize: 13,
      textWeight: 700,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
    },
    value: {
      textFont: null,
      textSize: 13,
      textWeight: 400,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
    },
    children:{
      padding: {
        top: {
          value: 15,
          unit: "px"
        },
        left: {
          value: 0,
          unit: "px"
        },
        bottom: {
          value: 30,
          unit: "px"
        },
        right: {
          value: 0,
          unit: "px"
        }
      },
      textFont: null,
      textSize: 13,
      textWeight: 400,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
    },
    variant: {
      "default": {
        bar: {
          borderColor: "#DDDDDDCC"
        },
        label: {
          textColor: "#000000"
        },
        value: {
          textColor: "#000000"
        },
        children: {
          textColor: "#000000"
        }
      },
      "event": {
        bar: {
          borderColor: "#39b54a"/*"#079192"*/
        },
        label: {
          textColor: "#39b54a"/*"#079192"*/
        },
        value: {
          textColor: "#000000"
        },
        children: {
          textColor: "#000000"
        }
      },
      "event-noborder": {
        bar: {
          borderColor: "#39b54a"/*"#079192"*/
        },
        label: {
          textColor: "#39b54a"/*"#079192"*/
        },
        value: {
          textColor: "#000000"
        },
        children: {
          textColor: "#000000"
        }
      }
    }
  },
  sheetTopbar:{
    bar: {
      borderStyle: "solid",
      borderSize: 0,
      height: {
        value: 60,
        unit: "px"
      },
      padding: {
        top: {
          value: 15,
          unit: "px"
        },
        left: {
          value: 0,
          unit: "px"
        },
        bottom: {
          value: 15,
          unit: "px"
        },
        right: {
          value: 0,
          unit: "px"
        }
      }
    },
    title: {
      textFont: null,
      textSize: null,
      textWeight: 400,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
    },
    variant: {
      "default": {
        anchorLinkVariant: "default",
        bar: {
          background: "#39b54a",
          boxShadow: "0px 3px 5px #0000000F",
          borderColor: "transparent"
        },
        title: {
          textColor: "#ffffff"
        }
      },
      "event": {
        anchorLinkVariant: "default",
        bar: {
          background: "#39b54a",/*"#079192",*/
          boxShadow: "0px 3px 5px #0000000F",
          borderColor: "transparent"
        },
        title: {
          textColor: "#ffffff"
        }
      }
    }
  },
  anchors:{
    spacing: 15,
    link: {

      textFont: null,
      textSize: null,
      textWeight: 400,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
    },
    variant: {
      "default": {
        link: {
          normal: {
            textColor: "#ffffff66"
          },
          hover: {
            textColor: "#ffffff"
          },
          active: {
            textColor: "#ffffff"
          }
        }
      }
    }
  },

  typography: {
    text: {
      textColor: "#000000",
      textFont: '"Lato", sans-serif',
      textSize: 13,
      textWeight: 400,
      textLineHeight: 18,
      textLetterSpacing: null,
      textTransform: null
    },
    h1: {
      textColor: "#000000",
      textFont: null,
      textSize: 50,
      textWeight: 400,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    h2: {
      textColor: "#000000",
      textFont: null,
      textSize: 40,
      textWeight: 400,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    h3: {
      textColor: "#000000",
      textFont: null,
      textSize: 16,
      textWeight: 400,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    }

  },
  button:{

    textFont: null,
    textLetterSpacing: null,
    textTransform: null,
    borderStyle: "solid",
    size: {
      "default": {
        textSize: 12,
        textWeight: 600,
        textLineHeight: null,
        borderSize: 2,
        borderRadius: {
          value: 4,
          unit: "px"
        },
        padding: {
          top:{
            value: 8,
            unit: "px"
          },
          left:{
            value: 20,
            unit: "px"
          },
          bottom:{
            value: 8,
            unit: "px"
          },
          right:{
            value: 20,
            unit: "px"
          }
        },
      },
      "tiny":{
        textSize: 12,
        textWeight: 400,
        textLineHeight: null,
        borderSize: 2,
        borderRadius: {
          value: 4,
          unit: "px"
        },
        padding: {
          top:{
            value: 4,
            unit: "px"
          },
          left:{
            value: 10,
            unit: "px"
          },
          bottom:{
            value: 4,
            unit: "px"
          },
          right:{
            value: 10,
            unit: "px"
          }
        },
      }
    },
    variant:{
      "default": {
        normal:{
          textColor: "#ffffff",
          borderColor: "#39b54a",
          background: "#39b54a"
        },
        hover:{
          textColor: "#39b54a",
          borderColor: "#39b54a",
          background: "#ffffff"
        },
        active:{
          textColor: "#39b54a",
          borderColor: "#39b54a",
          background: "#ffffff"
        },
        disabled:{
          textColor: "#dedede",
          borderColor: "#dedede",
          background: "#ffffff"
        }
      },
      "event": {
        normal:{
          textColor: "#ffffff",
          borderColor: "#39b54a",/*"#079192",*/
          background: "#39b54a"/*"#079192"*/
        },
        hover:{
          textColor: "#39b54a",/*"#079192",*/
          borderColor: "#39b54a",/*"#079192",*/
          background: "#ffffff"
        },
        active:{
          textColor: "#39b54a",/*"#079192",*/
          borderColor: "#39b54a",/*"#079192",*/
          background: "#ffffff"
        },
        disabled:{
          textColor: "#dedede",
          borderColor: "#dedede",
          background: "#ffffff"
        }
      },
      "default-inverse": {
        normal:{
          textColor: "#39b54a",
          borderColor: "#ffffff",
          background: "#ffffff"
        },
        hover:{
          textColor: "#ffffff",
          borderColor: "#ffffff",
          background: "#39b54a"
        },
        active:{
          textColor: "#ffffff",
          borderColor: "#ffffff",
          background: "#39b54a"
        },
        disabled:{
          textColor: "#dedede",
          borderColor: "#dedede",
          background: "#ffffff"
        }
      },
      "default-o": {
        normal:{
          textColor: "#39b54a",
          borderColor: "#39b54a",
          background: "#ffffff"
        },
        hover:{
          textColor: "#ffffff",
          borderColor: "#39b54a",
          background: "#39b54a"
        },
        active:{
          textColor: "#ffffff",
          borderColor: "#39b54a",
          background: "#39b54a"
        },
        disabled:{
          textColor: "#dedede",
          borderColor: "#dedede",
          background: "#ffffff"
        }
      },
      "default-inverse-o": {
        normal:{
          textColor: "#ffffff",
          borderColor: "#ffffff",
          background: "#39b54a"
        },
        hover:{
          textColor: "#39b54a",
          borderColor: "#ffffff",
          background: "#ffffff"
        },
        active:{
          textColor: "#39b54a",
          borderColor: "#ffffff",
          background: "#ffffff"
        },
        disabled:{
          textColor: "#dedede",
          borderColor: "#dedede",
          background: "#39b54a"
        }
      },
      "black-o": {
        normal:{
          textColor: "#000000",
          borderColor: "#000000",
          background: "#39b54a"
        },
        hover:{
          textColor: "#39b54a",
          borderColor: "#000000",
          background: "#000000"
        },
        active:{
          textColor: "#39b54a",
          borderColor: "#000000",
          background: "#000000"
        },
        disabled:{
          textColor: "#dedede",
          borderColor: "#dedede",
          background: "#39b54a"
        }
      },
      "event-o": {
        normal:{
          textColor: "#39b54a",/*"#079192",*/
          borderColor: "#39b54a",/*"#079192",*/
          background: "#ffffff"
        },
        hover:{
          textColor: "#ffffff",
          borderColor: "#39b54a",/*"#079192",*/
          background: "#39b54a"/*"#079192"*/
        },
        active:{
          textColor: "#ffffff",
          borderColor: "#39b54a",/*"#079192",*/
          background: "#39b54a"/*"#079192"*/
        },
        disabled:{
          textColor: "#dedede",
          borderColor: "#dedede",
          background: "#ffffff"
        }
      },
      "subareas": {
        normal:{
          textColor: "#777878",
          borderColor: "#f6f6f6",
          background: "#f6f6f6"
        },
        hover:{
          textColor: "#39b54a",
          borderColor: "#ffffff",
          background: "#ffffff"
        },
        active:{
          textColor: "#39b54a",
          borderColor: "#ffffff",
          background: "#ffffff"
        },
        disabled:{
          textColor: "#dedede",
          borderColor: "#f6f6f6",
          background: "#f6f6f6"
        }
      },
    }
  },
  labelledValue: {
    textFont: null,
    textSize: 15,
    textWeight: 700,
    textLineHeight: null,
    textLetterSpacing: null,
    textTransform: "uppercase",
    borderSize: 1,
    borderStyle: "solid",
    variant:{
      "default": {
        textColor: "#39b54a",
        borderColor: "#39b54a"
      }
    }

  },
  badge:{
    padding: {
      top:{
        value: 2,
        unit: "px"
      },
      left:{
        value: 10,
        unit: "px"
      },
      bottom:{
        value: 2,
        unit: "px"
      },
      right:{
        value: 10,
        unit: "px"
      }
    },
    textFont: null,
    textSize: 11,
    textWeight: 700,
    textLineHeight: null,
    textLetterSpacing: null,
    textTransform: "uppercase",
    borderSize: 0,
    borderStyle: "solid",
    borderRadius: {
      value: 4,
      unit: "px"
    },
    variant:{
      "default": {
        textColor: "#000000",
        borderColor: "transparent",
        background: "#F2F4F4"
      },
      "event": {
        textColor: "#39b54a",/*"#079192",*/
        borderColor: "transparent",
        background: "#39b54a17"/*"#07919217"*/
      },
      "filter": {
        textColor: "#000000",
        borderColor: "transparent",
        background: "#ffffff"
      },
      "primary": {
        textColor: "#ffffff",
        borderColor: "transparent",
        background: "#39b54a"
      }
    }
  },

  menuitem:{

    textFont: null,
    textTransform: null,


    size: {
      "default": {
        textSize: null,
        textWeight: null,
        textLineHeight: null,
        textLetterSpacing: null,
        borderSize: 1,
        borderStyle: "solid",
        height:{
          value: 50,
          unit: "px"
        },
        iconSize: {
          value: 22,
          unit: "px"
        },
        padding: {
          top:{
            value: 15,
            unit: "px"
          },
          left:{
            value: 20,
            unit: "px"
          },
          bottom:{
            value: 15,
            unit: "px"
          },
          right:{
            value: 20,
            unit: "px"
          }
        },
      },
      "tiny":{
        textSize: 14,
        textWeight: 600,
        textLineHeight: null,
        textLetterSpacing: null,
        borderSize: 0,
        borderStyle: "solid",
        height:{
          value: 30,
          unit: "px"
        },
        iconSize: {
          value: 10,
          unit: "px"
        },
        padding: {
          top:{
            value: 15,
            unit: "px"
          },
          left:{
            value: 20,
            unit: "px"
          },
          bottom:{
            value: 15,
            unit: "px"
          },
          right:{
            value: 20,
            unit: "px"
          }
        },
      }
    },
    variant:{
      "default": {
        normal:{
          textColor: "#000000",
          background: "#ffffff",
          borderColor: "#F2F4F4"
        },
        hover:{
          textColor: "#000000",
          background: "#f4f4f4",
          borderColor: "#F2F4F4"
        },
        active:{
          textColor: "#000000",
          background: "#f4f4f4",
          borderColor: "#F2F4F4"
        },
        disabled:{
          textColor: "#999999",
          background: "#ffffff",
          borderColor: "#F2F4F4"
        }
      },
      "grey": {
        normal:{
          textColor: "#000000",
          background: "#F2F4F4",
          borderColor: "transparent"
        },
        hover:{
          textColor: "#000000",
          background: "#F2F4F4",
          borderColor: "transparent"
        },
        active:{
          textColor: "#000000",
          background: "#F2F4F4",
          borderColor: "transparent"
        },
        disabled:{
          textColor: "#999999",
          background: "#F2F4F4",
          borderColor: "transparent"
        }
      },
      "inverse": {
        normal:{
          textColor: "#ffffff",
          background: "#39b54a",
          borderColor: "transparent"
        },
        hover:{
          textColor: "#ffffff",
          background: "#39b54a",
          borderColor: "transparent"
        },
        active:{
          textColor: "#ffffff",
          background: "#39b54a",
          borderColor: "transparent"
        },
        disabled:{
          textColor: "#999999",
          background: "#39b54a",
          borderColor: "transparent"
        }
      },
      "filter": {
        normal:{
          textColor: "#ffffff",
          background: "transparent",
          borderColor: "#f6f6f6"
        },
        hover:{
          textColor: "#ffffff",
          background: "transparent",
          borderColor: "#f6f6f6"
        },
        active:{
          textColor: "#ffffff",
          background: "transparent",
          borderColor: "#f6f6f6"
        },
        disabled:{
          textColor: "#999999",
          background: "transparent",
          borderColor: "#f6f6f6"
        }
      },
      "homepage": {
        normal:{
          textColor: "#A3A3A3",
          background: "transparent",
          borderColor: "transparent"
        },
        hover:{
          textColor: "#39b54a",
          background: "transparent",
          borderColor: "transparent"
        },
        active:{
          textColor: "#39b54a",
          background: "transparent",
          borderColor: "transparent"
        },
        disabled:{
          textColor: "#999999",
          background: "transparent",
          borderColor: "transparent"
        }
      },
    }
  },
  colors: {
    primary: '#39b54a'
  }
};
