import React from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from '../App/Button';
import NoButton from '../App/NoButton';
import { withTheme } from "../../themes";

const Text  = styled.div`
  font-weight: 700;
  font-size: 18px;
`;
const Counters  = styled.div`
  font-size: 12px;
`;

const ConfirmButton = ({ hideButton, theme, onClick, text, current, total }) => {
  return (
    hideButton ? (
      <NoButton variant={theme.filters.buttonVariant} fullSize>
        <Text>{text}</Text>
        <Counters>{current + " / " + total}</Counters>
      </NoButton>

      ) : (
      <Button onClick={onClick} variant={theme.filters.buttonVariant} fullSize>
        <Text>{text}</Text>
        {/*<Counters>{current + " / " + total}</Counters>*/}
      </Button>
    )
  );
};

ConfirmButton.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  text: PropTypes.string
};

ConfirmButton.defaultProps = {
  text: '!Show results'
};

export default React.memo(withTheme(ConfirmButton));
