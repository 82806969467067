import {all, takeLatest, select, call, put} from 'redux-saga/effects'
import {getRecords} from './actions'
import {getToken} from "../auth/selectors";
import axios from 'axios';

import {getChatConfig} from "../settings/selectors";

function* getRecordsSaga() {

  const { accessToken } = yield select(getToken);
  const chatConfig = yield select(getChatConfig);
  let url = chatConfig.default.bcardsUrl;
  if (chatConfig && window && window.location) {
    const key = Object.keys(chatConfig).find(key => key === window.location.hostname);
    if (key) {
      url = chatConfig[key].bcardsUrl;
    }
  }

  const apiUrl = `${url}`;
  try {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    const { data } = yield call([axios, axios.get], `${apiUrl}?exhibitionId=VIR`);
    if (data.length) {
      const parsedData = data.map(({ userId, bCardId, targetUserId, createdDate, generatedBy, rawOptions }) => ({
        id: bCardId,
        userId: userId,
        targetUserId,
        createdDate,
        generatedBy,
        email: rawOptions.email,
        fullname: `${rawOptions.givenName} ${rawOptions.familyName}`,
        name: rawOptions.givenName,
        surname: rawOptions.familyName,
        organization: rawOptions.businessName,
        nickname: rawOptions.nickname,
        telephone: rawOptions.phoneNumber,
        country: rawOptions.country,
        city: rawOptions.city,
        website: rawOptions.webSite,
        address: null,
        photo: null
      }))
      yield put(getRecords.success(parsedData));
    }
  } catch (e) {}

}

export default function* root() {
  yield all([
    yield takeLatest([getRecords.TRIGGER], getRecordsSaga),
  ])
}
