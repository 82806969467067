import { hotjar } from 'react-hotjar';

class HotjarService {

  setConfig(codes) {

    // console.log('HotjarService', codes);
    codes.map(code => {
      hotjar.initialize(code, 6);
      return null;
    })

  }

}

export default new HotjarService();
