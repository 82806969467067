import styled from "styled-components";

// export const Levels  = styled.div`
//   flex: 1;
//   flex-wrap: nowrap;
//   display: inline-flex;
//   transition: all ${props => props.transitionDuration}s cubic-bezier(0.77, 0, 0.175, 1);
//   transform: translate3d(${props => props.depth * props.width.value * -1}px,0,0);
// `;

// export const Level  = styled.div`
//   display: inline-flex;
//   width: ${props => props.width.value + props.width.unit};
//   height: 100%;
//   flex-direction: column;
// `;


export const Levels  = styled.div`
  flex: 1;
  flex-wrap: nowrap;
  display: inline-flex;
  transition: all ${props => props.transitionDuration}s cubic-bezier(0.77, 0, 0.175, 1);
  transform: translate3d(${props => props.isMobile ? ((props.depth * 100 * -1) + 'vw') : ((props.depth * props.width.value * -1) + 'px')},0,0);
`;

export const LevelButtonContainer  = styled.div`
  /* border-bottom: 1px solid #A3A3A3; */
`;

export const Level  = styled.div`
  display: inline-flex;
  width: ${props => !props.isMobile ? (props.width.value + props.width.unit) : '100vw'};
  height: 100%;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;
