import React, { useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useTheme } from "./../../../themes";

const Wrapper  = styled.div`
  margin-right: ${props => props.spacing ? props.spacing.value+props.spacing.unit : "0px"};
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  border-radius: ${props => props.borderRadius.value + props.borderRadius.unit};

  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};

  text-align: center;

  border: ${props => props.borderSize+"px "+props.borderStyle+" "+props.borderColor };
  color: ${props => props.textColor};
  background: ${props => props.background};

  ${props => props.isDisabled ? `
    color: `+props.textColorDisabled+`;
    background: `+props.backgroundDisabled+`;
    border-color: `+props.borderColorDisabled+`;
  ` : props => props.isActive ? `
    color: `+props.textColorActive+`;
    background: `+props.backgroundActive+`;
    border-color: `+props.borderColorActive+`;
  ` : `
    &:hover{
      color: `+props.textColorHover+`;
      background: `+props.backgroundHover+`;
      border-color: `+props.borderColorHover+`;
    }
  `};
`;

const Item = ({to, children, isDisabled = false, onClick = () => null, forceActive = false}) => {
  const theme = useTheme();
  const [isActive, setActive] = useState(false);
  let baseUrl = to.substr(1).split('/');
  if (baseUrl.length > 1)
    baseUrl = baseUrl[1];

  function setItemActive(value) {
    setActive(value);
  }

  return (
    <NavLink onClick={onClick} to={!isDisabled ? to : "#"} activeStyle={{ color: 'red' }} isActive={(match, { pathname }) => {
      setTimeout(() => {
        let currentBaseUrl = pathname.substr(1).split('/');
        if (forceActive) { //TRICK
          setItemActive(true);
        } else if (currentBaseUrl.length > 1 && currentBaseUrl[1] === baseUrl) {
          setItemActive(true);
        } else if (isActive) {
          setItemActive(false);
        }
      }, 10)
    }}>
      <Wrapper
        spacing={theme.topbar.item.spacing}
        padding={theme.topbar.item.padding}
        borderStyle={theme.topbar.item.borderStyle}
        borderSize={theme.topbar.item.borderSize}
        borderRadius={theme.topbar.item.borderRadius}
        textFont={theme.topbar.item.textFont}
        textSize={theme.topbar.item.textSize}
        textWeight={theme.topbar.item.textWeight}
        textLineHeight={theme.topbar.item.textLineHeight}
        textLetterSpacing={theme.topbar.item.textLetterSpacing}
        textTransform={theme.topbar.item.textTransform}

        isActive={isActive}
        isDisabled={isDisabled}
        background={theme.topbar.item.state.normal.background}
        backgroundHover={theme.topbar.item.state.hover.background}
        backgroundActive={theme.topbar.item.state.active.background}
        backgroundDisabled={theme.topbar.item.state.disabled.background}
        borderColor={theme.topbar.item.state.normal.borderColor}
        borderColorHover={theme.topbar.item.state.hover.borderColor}
        borderColorActive={theme.topbar.item.state.active.borderColor}
        borderColorDisabled={theme.topbar.item.state.disabled.borderColor}
        textColor={theme.topbar.item.state.normal.textColor}
        textColorHover={theme.topbar.item.state.hover.textColor}
        textColorActive={theme.topbar.item.state.active.textColor}
        textColorDisabled={theme.topbar.item.state.disabled.textColor}

      >
        {children}
      </Wrapper>
    </NavLink>
  )
}


export default Item;
