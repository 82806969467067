import styled from 'styled-components'
import {LAYOUT_XS, LAYOUT_SM} from './../../settings';


export const Screen  = styled.div`
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  top: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? 0 : 50}px;
  left: 0px;
  width: 100%;
  height: calc(100% - ${props => props.compactMenu ? 0 : 50}px);
  ${props => props.background ? "background: "+props.background+";" : ""}
  background-size: cover;
  background-position: center;

  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  color: ${props => props.textColor};
`;

export const Mover = styled.div`
  display: flex;
  width: calc(${ props => !props.isMobile ? '100vw + ' + (props.filterWidth.value + props.filterWidth.unit) : '100vw' });
  height: 100%;
  position: absolute;
  top: 0px;
  left: ${ props => props.isOpen ? (!props.isMobile ? 0 : '100vw') : (!props.isMobile ? props.filterWidth.value * -1 + props.filterWidth.unit : '0') };
  transition: all 0.25s ease-in-out;
`;

export const ListWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(${ props => props.filterWidth.value+props.filterWidth.unit } + ${ props => props.listWidth.value+props.listWidth.unit });
  height: 100%;
`;

export const PageWrapper = styled.div`
  position: absolute;
  top: 0px;
  overflow: auto;
  left: calc(${ props => props.filterWidth.value+props.filterWidth.unit } + ${ props => props.listWidth.value+props.listWidth.unit });
  width: calc(100% - (${ props => props.filterWidth.value+props.filterWidth.unit } + ${ props => props.listWidth.value+props.listWidth.unit }));
  height: 100%;
`;

export const PageWrapperNoSidebar = styled.div`
  position: absolute;
  top: 0px;
  overflow: auto;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: calc(${ props => props.filterWidth.value+props.filterWidth.unit });
`;

export const PageWrapperNoSidebarCentered = styled.div`
  position: absolute;
  top: 0px;
  overflow: auto;
  right: 0;
  width: 100vw;
  height: 100%;
`;

export const MobileContentWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: ${ props => props.withBar ? "calc(100% - 0px)" : "100%" };
`;

export const MobileBarWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px -2px 2px 1px rgba(0,0,0,0.1);
`;
