import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '../../themes'
import styled from 'styled-components';
import Icon from "./../App/Icon";

const Wrapper  = styled.div`
  display: flex;
  padding: 34px 0px 10px;
`;
const Item  = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: ${props => props.borderSize+"px "+props.borderStyle+" "+props.borderColor };
  color: ${props => props.textColor};
  background: ${props => props.background};
  height: 46px;
  min-width: 80px;
  padding: 0px 16px 0px 10px;
  border-radius: ${props => props.borderRadius.value+props.borderRadius.unit};
  margin-right: 10px;
  text-align: center;

  /* opacity: ${props => props.isDisabled ? 0.5 : 1}; */

  ${props => props.isDisabled ? `
    cursor: default;
    color: `+props.textColorDisabled+`;
    background: `+props.backgroundDisabled+`;
    border-color: `+props.borderColorDisabled+`;
  ` : props => props.isActive ? `
    color: `+props.textColorActive+`;
    background: `+props.backgroundActive+`;
    border-color: `+props.borderColorActive+`;
  ` : `
    &:hover{
      color: `+props.textColorHover+`;
      background: `+props.backgroundHover+`;
      border-color: `+props.borderColorHover+`;
    }
  `};
`;
const Label  = styled.div`
  margin-left: 5px;
`;

const Actionbar = ({
  list,
  theme,
  variant = "default"
}) => {
  return (
    <Wrapper>
      {list.map((item, index) => (
        item.show ?
          <Item
            key={index}
            isDisabled={item.isDisabled}
            onClick={item.onClick && !item.isDisabled ? e => item.onClick(e) : () => {}}

            borderStyle={theme.actionbar.borderStyle}
            borderSize={theme.actionbar.borderSize}
            borderRadius={theme.actionbar.borderRadius}
            background={theme.actionbar.variant[variant].normal.background}
            backgroundHover={theme.actionbar.variant[variant].hover.background}
            backgroundActive={theme.actionbar.variant[variant].active.background}
            backgroundDisabled={theme.actionbar.variant[variant].disabled.background}
            borderColor={theme.actionbar.variant[variant].normal.borderColor}
            borderColorHover={theme.actionbar.variant[variant].hover.borderColor}
            borderColorActive={theme.actionbar.variant[variant].active.borderColor}
            borderColorDisabled={theme.actionbar.variant[variant].disabled.borderColor}
            textColor={theme.actionbar.variant[variant].normal.textColor}
            textColorHover={theme.actionbar.variant[variant].hover.textColor}
            textColorActive={theme.actionbar.variant[variant].active.textColor}
            textColorDisabled={theme.actionbar.variant[variant].disabled.textColor}

          ><Icon color={
            item.isActive ? theme.actionbar.variant[variant].active.iconColor
            : item.isDisabled ? theme.actionbar.variant[variant].disabled.iconColor
            : theme.actionbar.variant[variant].normal.iconColor
          } name={item.icon} size={{value: 30, unit: "px"}} /> <Label>{item.name}</Label></Item>
        : null
      ))}
    </Wrapper>
  )
}

Actionbar.propTypes = {
  list: PropTypes.array
}

export default withTheme(Actionbar)
