import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
// import { getLayout } from "../../store/settings/selectors";
// import { getConfig } from "../../store/settings/selectors";
import {getTranslations} from "../../store/localization/selectors";
// import {LAYOUT_XS, LAYOUT_SM} from './../../settings'
// import {Link } from 'react-router-dom';
// import { useParams } from 'react-router-dom'
import { useTheme } from './../../themes';
// import {stripTags} from "../../helpers";

import { getCount as getCompaniesCount } from "../../store/companies/selectors";
import { getCount as getProductsCount } from "../../store/products/selectors";
import { getCount as getEventsCount } from "../../store/events/selectors";
import { getCount as getCasehistoriesCount } from "../../store/casehistories/selectors";

// import Logo from "./../../components/App/Logo";
import Meta from "./../../components/App/Meta";
import Image from "./../../components/App/Image";
import Badge from "./../../components/App/Badge";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 100%;
  flex: 1;
  ${props => props.image ? 'background: url(' + props.image + ') center repeat;' : ''}
`;

const Area = styled.div`
  position: relative;
  text-align: center;
  background-color: #f6f6f6;
  padding: 20px;
`;

const Text = styled.div`
  padding: 20px 30px;
  margin-top: 40px;
  position: relative;
  border-top: 3px solid #fff;
`;

const Counter = styled.div`
  position: absolute;
  top: -15px;
  width: calc(100% - 60px);
  font-size: 18px;
`;
const Title = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: 900;
  color: #39b54a;
  margin-top: 10px;
  text-transform: uppercase;
`;
const Subtitle = styled.div`
  font-size: 20px;
  line-height: 30px;
  margin-top: 5px;
  color: #231f20;
  font-weight: 400;
  max-width: 350px;
`;


function AgrIdlepage({ area }) {

  const theme = useTheme();
  // let { lang } = useParams()
  // const layout = useSelector(getLayout);
  // const config = useSelector(getConfig);
  const translations = useSelector(getTranslations);
  let counters = {};
  counters["companies"] = useSelector(getCompaniesCount);
  counters["products"] = useSelector(getProductsCount);
  counters["events"] = useSelector(getEventsCount);
  counters["casehistories"] = useSelector(getCasehistoriesCount);
// console.log(counters);

  // let logoWidth = {value: '370', unit: 'px'};
  // if(layout === LAYOUT_XS || layout === LAYOUT_SM){
  //   logoWidth = {value: '90', unit: '%'};
  // }


  return (
    <Wrapper>
      <Meta />
      <Area>
        <Image width={{"value": 250, "unit": "px"}} src={theme.idlepage.image[area]} alt="Idle"/>
        <Text>
          <Counter>{counters[area] ? <Badge variant={"primary"}><div style={{"fontSize": "18px", "paddingTop": "2px"}}>{counters[area]}</div></Badge> : null }</Counter>
          <Title>{translations["UI"]["_idlepage-title-"+area]}</Title>
          <Subtitle>{translations["UI"]["_idlepage-subtitle-"+area]}</Subtitle>
        </Text>
      </Area>
    </Wrapper>
  )

}

export default React.memo(AgrIdlepage)
