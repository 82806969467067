import { createStore, applyMiddleware, compose } from 'redux'
// import { createLogger } from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import createRootReducer from './reducers'
import sagas from './sagas'

const history = createBrowserHistory();
const rootReducer = createRootReducer(history);

// const logger = createLogger({collapsed: true});

const persistConfig = {
  key: 'data-db',
  storage,
  whitelist: ['auth']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

let middleware = [sagaMiddleware, /*routerMiddleware(history), */];

if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware]; //, logger
}

const store = createStore(
    // rootReducer,
    persistedReducer,
    {},
    compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
)

sagaMiddleware.run(sagas);

const persistor = persistStore(store)

export default {
  store,
  persistor,
  history
}
