import {channel} from 'redux-saga';
import {all, put, select, takeLatest, call} from 'redux-saga/effects'
import {
    archiveRoom,
    contactCompany, getCompanyActive,
    getProfile,
    init,
    listMessages, loadCompanyData,
    messageReceived,
    roomList,
    sendMessage,
    setAttendee,
    // setCount,
    setCurrent,
    // setFacets,
    // setFilters,
    // setFiltersTree,
    startRoom,
    waitingList
} from './actions'
import {getCompanyListData, getCurrent, getIsAgent, getPendingRooms, getRooms} from "./selectors";
import DataService from "../../services/DataService";
// import {getLocale} from "../localization/selectors";
import {profile} from "../auth/actions";
import {difference, capitalize, isArray} from 'lodash';
import {getRecords as updateBCards} from "../bcard/actions";
import {getToken} from "../auth/selectors";
// import {getChatConfig, getConfig, getTenant} from "../settings/selectors";
import AccountService from "../../services/AccountService";
// import axios from "axios";

let instance;
// let connection;

const listMessagesTriggerChannel = channel();
const setAttendeeChannel = channel();
const messageReceivedChannel = channel();
const profileChannel = channel();

const roomsListTriggerChannel = channel();
const waitingListTriggerChannel = channel();
const loadCompanyDataChannel = channel();
const loadCompanyDataSuccessChannel = channel();

// const COMPANY_INFO_LIST = [
//     'id',
//     'logo',
//     'slug',
//     'name',
//     'identity',
//     'owner',
//     'foundation',
//     'typology',
//     'labels',
//     'brands',
//     'tastings',
//     'otherInfo',
//     'events',
//     'lowertext'
// ];

async function getCompanyInfo(companyId) {

    let companyInfo = null;
    let response = await DataService.getItemById("companies", companyId, false);
    if (response) {
        companyInfo = {
            id: response.id,
            logo: response.options.logo.src,
            slug: response.id,
            name: response.options.name,
            identity: null,
            foundation: response.options.foundationYear,
            typology: null,
            labels: null,
            brands: response.options.brands,
            tastings: null,
            otherInfo: null,
            events: [],
            lowertex: response.options.name,
        }
    }
    return Promise.resolve(companyInfo);

}

async function getRoomsCompanyInfo(companyIds) {
    return Promise.all(companyIds.map(companyId => getCompanyInfo(companyId)));
}

function* initSaga({payload}) {

    const user = payload;
    const {accessToken} = user;

    if (user) {

        const account = yield call([AccountService, 'getAccount'], accessToken);
        let permissions = [],
            roles = [],
            companyData = null


        if (account?.roles && account?.permissions) {
            permissions = account.permissions;
            roles = account.roles;
        }

        if (account?.options && account.options.hasOwnProperty('premium')) {
            const options = account.options;
            companyData = {
                activity: capitalize(options.premium.activity),
                activity2: isArray(options.premium?.subactivity) ? options.premium?.subactivity.map(item => capitalize(item)) : null,
                name: options.premium?.businessName,
                nation: options.premium?.businessNation,
                vatNumber: options.premium?.businessVatNumber,
                address: options.premium?.businessAddress,
                zipCode: options.premium?.businessZipCode,
                city: options.premium?.businessCity,
                province: options.premium?.businessProvince,
            }
        }
        profileChannel.put(profile.trigger({
            permissions,
            roles,
            companyData
        }));

        try {
            yield;
        } catch (e) {
            console.error('connection error');
        }

    }

}

function* sendMessageSaga({payload}) {

    const current = yield select(getCurrent);

    if (current.type === 'room') {

        instance.sendMessage({
            roomId: current.id,
            message: payload.message
        });

    } else {

        instance.contactCompany({
            companyId: current.id,
            message: payload.message
        });

    }

}

function setCurrentSaga({payload}) {
    if (payload.type === 'room') {
        instance.listMessages({
            roomId: payload.id
        })
    }
}

function* listMessagesTriggerSaga({payload}) {
    yield put(listMessages.success(payload))
}

function* setAttendeeSaga({payload}) {
    yield put(setAttendee.success(payload))
}

function startRoomSaga({payload}) {
    instance.beginChat({
        userId: payload.userId
    })
}

function archiveRoomSaga({payload}) {
    if (payload.type === 'room') {
        instance.closeRoom({
            roomId: payload.id
        });
    } else {
        instance.rejectChat({
            userId: payload.id
        });
    }
}

function* contactCompanySaga({payload}) {

    let rooms = yield select(getRooms);
    let waitingRooms = yield select(getPendingRooms);
    const roomExists = rooms.find(room => room.hasOwnProperty('roomId') && room.roomStatus === 'ACTIVE' && room.companyId === payload.companyId);
    const waitingExists = waitingRooms.find(room => room.companyId === payload.companyId);
    if (!roomExists && !waitingExists) {
        waitingRooms = [...waitingRooms, ...[{label: payload.companyName, companyId: payload.companyId}]];
        yield put(loadCompanyData.trigger([payload.companyId]));
        yield put(waitingList.trigger(waitingRooms));
    } else if (roomExists) {
        yield put(setCurrent.trigger({
            type: 'room',
            id: roomExists.roomId,
            tab: 'active'
        }))
    }
}

function* messageReceivedSaga({payload}) {
    yield put(messageReceived.success(payload));
}

function* profileSaga({payload}) {
    yield put(profile.success(payload));
}

function* getProfileSaga() {

    const token = yield select(getToken);
    instance.getProfile(token.accessToken).then((response) => {
        const {roles, permissions} = response;
        profileChannel.put(profile.trigger({
            permissions,
            roles,
        }));
    });

}

function* roomsListTriggerSaga({payload}) {
    const isAttendeeAgent = yield select(getIsAgent);
    payload = payload.map(item => ({...item, label: !isAttendeeAgent ? item.companyName : item.userNickname}));
    yield put(roomList.trigger(payload));
    yield put(updateBCards.trigger());
}

function* waitingListTriggerSaga({payload}) {
    const isAttendeeAgent = yield select(getIsAgent);
    payload = payload.map(item => ({...item, label: !isAttendeeAgent ? item.companyName : item.userNickname}));
    yield put(waitingList.trigger(payload));
    yield put(updateBCards.trigger());
}

function* loadCompanyDataSaga({payload}) {
    const alreadyLoaded = yield select(getCompanyListData);
    const loadData = difference(payload, alreadyLoaded);
    getRoomsCompanyInfo(loadData).then((response) => {
        const data = response.filter(item => item).reduce((acc, item) => ({...acc, [item.id]: item}), {});
        loadCompanyDataSuccessChannel.put(loadCompanyData.success(data));
    })
}

function* loadCompanyDataSuccessSaga({payload}) {
    yield put(loadCompanyData.success(payload));
}

function* activeCompanySaga({payload}) {

    let success = false;
    const token = yield select(getToken);

    try {
        success = yield call([instance, 'hasChatOperator'], payload, token.accessToken);
    } catch (e) {
    }

    yield put(getCompanyActive.success({companyId: payload, success}));

}

export default function* root() {
    yield all([
        yield takeLatest([init.TRIGGER], initSaga),
        yield takeLatest([setCurrent.TRIGGER], setCurrentSaga),
        yield takeLatest([sendMessage.TRIGGER], sendMessageSaga),
        yield takeLatest([contactCompany.TRIGGER], contactCompanySaga),
        yield takeLatest([startRoom.TRIGGER], startRoomSaga),
        yield takeLatest([archiveRoom.TRIGGER], archiveRoomSaga),
        yield takeLatest(listMessagesTriggerChannel, listMessagesTriggerSaga),
        yield takeLatest(setAttendeeChannel, setAttendeeSaga),
        yield takeLatest(messageReceivedChannel, messageReceivedSaga),
        yield takeLatest(profileChannel, profileSaga),

        yield takeLatest(roomsListTriggerChannel, roomsListTriggerSaga),
        yield takeLatest(waitingListTriggerChannel, waitingListTriggerSaga),

        yield takeLatest([profile.TRIGGER], profileSaga),
        yield takeLatest([getProfile.TRIGGER], getProfileSaga),

        yield takeLatest([loadCompanyData.TRIGGER], loadCompanyDataSaga),
        yield takeLatest(loadCompanyDataChannel, loadCompanyDataSaga),
        yield takeLatest(loadCompanyDataSuccessChannel, loadCompanyDataSuccessSaga),

        yield takeLatest([getCompanyActive.TRIGGER], activeCompanySaga),

    ])
}
