import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
// import { getLayout } from "../../store/settings/selectors";
// import { getConfig } from "../../store/settings/selectors";
import {getTranslations} from "../../store/localization/selectors";
// import {LAYOUT_XS, LAYOUT_SM} from './../../settings'
// import {Link } from 'react-router-dom';
// import { useParams } from 'react-router-dom'
import { useTheme } from './../../themes';
// import {stripTags} from "../../helpers";

import { getCount as getCompaniesCount } from "../../store/companies/selectors";
import { getCount as getProductsCount } from "../../store/products/selectors";
import { getCount as getEventsCount } from "../../store/events/selectors";

// import Logo from "./../../components/App/Logo";
import Meta from "./../../components/App/Meta";
import Image from "./../../components/App/Image";
import Badge from "./../../components/App/Badge";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 100%;
  flex: 1;
  ${props => props.image ? 'background: url(' + props.image + ') center repeat;' : ''}
`;

const Circle = styled.div`
  width: 400px;
  height: 400px;
  border-radius: 1000px;
  background-color: #efe7ed;
  position: relative;
`;

const Text = styled.div`
  position: absolute;
  top: 71%;
  left: 15%;
  width: 70%;
  text-align: center;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #7B3572;
  margin-top: 10px;
`;
const Subtitle = styled.div`
  font-size: 16px;
  margin-top: 5px;
  color: #585858;
`;


function VirIdlepage({ area }) {

  const theme = useTheme();
  // let { lang } = useParams()
  // const layout = useSelector(getLayout);
  // const config = useSelector(getConfig);
  const translations = useSelector(getTranslations);
  let counters = {};
  counters["companies"] = useSelector(getCompaniesCount);
  counters["products"] = useSelector(getProductsCount);
  counters["events"] = useSelector(getEventsCount);
// console.log(counters);

  // let logoWidth = {value: '370', unit: 'px'};
  // if(layout === LAYOUT_XS || layout === LAYOUT_SM){
  //   logoWidth = {value: '90', unit: '%'};
  // }

// console.log(area);

  return (
    <Wrapper>
      <Meta />
      <Circle>
      <Image width={{"value": 400, "unit": "px"}} src={theme.idlepage.image[area]} alt="Idle"/>
        <Text>
          {/* {counters[area] ? <Badge variant={"primary"}>{counters[area]}</Badge> : null } */}
          <Badge variant={"primary"}>{translations["UI"]["_idlepage-update"]}</Badge>
          <Title>{translations["UI"]["_idlepage-title-"+area]}</Title>
          <Subtitle>{translations["UI"]["_idlepage-subtitle-"+area]}</Subtitle>
        </Text>
      </Circle>
    </Wrapper>
  )

}

export default React.memo(VirIdlepage)
