import React from 'react';
import styled from 'styled-components';
import Icon from '../App/Icon';
import { withTheme } from "../../themes";

const Wrapper  = styled.button`
  position: relative;
  cursor: pointer;
  width: ${props => props.size.value+props.size.unit};
  height: ${props => props.size.value+props.size.unit};
  cursor: pointer;
  background: transparent;
  padding: 0;
  border: none;
  outline: none;
`;

const Button = ({ theme, onClick, icon }) => {
  return (
    <Wrapper type={'button'} onClick={onClick} size={theme.filters.bar.iconSize}>
      <Icon
        name={theme.icons.set[icon]}
        size={theme.filters.bar.iconSize}
        color={theme.filters.bar.icon.normal.color}
        background={theme.filters.bar.icon.normal.background}
        hoverColor={theme.filters.bar.icon.hover.color}
        hoverBackground={theme.filters.bar.icon.hover.background}
      />
    </Wrapper>
  );
};

export default withTheme(Button);
