import axios from "axios";
import {DOMAINS} from "./../domains";

export class AccountService {

  constructor() {

    this.getDomainConfig = this.getDomainConfig.bind(this);
    this.getApiUrl = this.getApiUrl.bind(this);
    this.getAccount = this.getAccount.bind(this);

  }

  getDomainConfig() {
    return DOMAINS[window.location.hostname];
  }

  getApiUrl() {

    const domainConfig = this.getDomainConfig().chatConfig;
    if (domainConfig.hasOwnProperty(window.location.hostname))
      return domainConfig[window.location.hostname].accountUrl
    return domainConfig.default.accountUrl;

  }


    async getAccount(accessToken){

        const apiUrl = this.getApiUrl();

        return new Promise(async (resolve, reject) => {
            if(apiUrl){
                try {
                    const result = await axios.get(apiUrl, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    });
                    resolve(result.data);
                } catch (error) {
                    console.error(error);
                    resolve(null);
                }
            }else{
                resolve(null);
            }
        });

    }

}

export default new AccountService();
