import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { useTheme } from './../../themes';

const Wrapper  = styled.button`
  display: ${props => props.display};
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  border: ${props => props.borderSize + "px " + props.borderStyle + " " + props.borderColor};
  border-radius: ${props => props.borderRadius.value+props.borderRadius.unit};
  ${props => props.fullSize ? 'width: 100%;' : ''}
  cursor: pointer;

  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  ${props => props.align === 'center' ? 'margin: 0 auto;' : ''};

  text-align: center;



  border: ${props => props.borderSize+"px "+props.borderStyle+" "+props.borderColor };
  color: ${props => props.textColor};
  background: ${props => props.background};
  
  &:disabled {
    ${props => `
      cursor: default;
      color: ` + props.textColorDisabled +`;
      background: ` + props.backgroundDisabled + `;
      border-color: ` + props.borderColorDisabled + `;
    `}
  }

  ${props => props.isDisabled ? `
    cursor: default;
    color: `+props.textColorDisabled+`;
    background: `+props.backgroundDisabled+`;
    border-color: `+props.borderColorDisabled+`;
  ` : props => props.isActive ? `
    color: `+props.textColorActive+`;
    background: `+props.backgroundActive+`;
    border-color: `+props.borderColorActive+`;
  ` : `
    &:hover{
      color: `+props.textColorHover+`;
      background: `+props.backgroundHover+`;
      border-color: `+props.borderColorHover+`;
    }
  `};
`;

const Button = ({
  variant = "default",
  size = "default",
  display = "block",
  onClick,
  children,
  disabled,
  fullSize = false,
  align = null,
  isActive = false
}) => {
  const theme = useTheme()
  return (
    <Wrapper
      isActive={isActive}
      disabled={disabled === true}
      onClick={onClick}
      display={display}
      fullSize={fullSize}
      padding={theme.button.size[size].padding}
      borderStyle={theme.button.borderStyle}
      borderSize={theme.button.size[size].borderSize}
      borderRadius={theme.button.size[size].borderRadius}
      textFont={theme.button.textFont}
      textSize={theme.button.size[size].textSize}
      textWeight={theme.button.size[size].textWeight}
      textLineHeight={theme.button.size[size].textLineHeight}
      textLetterSpacing={theme.button.textLetterSpacing}
      textTransform={theme.button.textTransform}
      align={align}

      background={theme.button.variant[variant].normal.background}
      backgroundHover={theme.button.variant[variant].hover.background}
      backgroundActive={theme.button.variant[variant].active.background}
      backgroundDisabled={theme.button.variant[variant].disabled.background}
      borderColor={theme.button.variant[variant].normal.borderColor}
      borderColorHover={theme.button.variant[variant].hover.borderColor}
      borderColorActive={theme.button.variant[variant].active.borderColor}
      borderColorDisabled={theme.button.variant[variant].disabled.borderColor}
      textColor={theme.button.variant[variant].normal.textColor}
      textColorHover={theme.button.variant[variant].hover.textColor}
      textColorActive={theme.button.variant[variant].active.textColor}
      textColorDisabled={theme.button.variant[variant].disabled.textColor}
    >
      {children}
    </Wrapper>
  )
}

Button.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  display: PropTypes.string,
  align: PropTypes.string,
  onClick: PropTypes.func,
}

export default React.memo(Button)
