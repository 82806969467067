import React from "react";
import styled from "styled-components";

const Wrapper  = styled.button`
  position: relative;
  width: 46px;
  height: 46px;
  border-radius: ${props => props.borderRadius.value + props.borderRadius.unit};
  background-color: ${(props) => props.background };
  border: none;
  cursor: pointer;
  padding: 5px;
`;

export const Icon  = styled.div`
  position: absolute;
  top: 30%;
  left: 30%;
  width: 40%;
  height: 40%;
  background-color: ${props => props.iconColor};
  mask: url("/assets/icons/${props => props.icon}.svg") no-repeat 100% 100%;
  mask-size: contain;
`;

export const Sticker = styled.div`
  position: absolute;
  top: -2px;
  right: -2px;
  width: 11px;
  height: 11px;
  background: #EA4E28;
  border: 2px solid #fff;
  border-radius: 50%;
`;

const ToggleFilters = ({ icon, iconBackground, iconColor, onClick, showSticker, borderRadius }) => {

  // console.log('iconBackground', iconBackground);

  return (
    <Wrapper
      onClick={onClick}
      background={iconBackground}
      borderRadius={borderRadius}
    >
      {showSticker && (<Sticker />)}
      <Icon type='button' icon={icon} iconColor={iconColor}></Icon>
    </Wrapper>
  );
}

export default ToggleFilters;
