import axios from "axios";
import {DOMAINS} from "./../domains";

export class RecommendedService {

  async getList(userId = null){
    return new Promise((resolve, reject) => {
      if(DOMAINS[window.location.hostname].recommended.apiUrl){
        axios.get(DOMAINS[window.location.hostname].recommended.apiUrl+(userId ? userId : "anonymous")+"/recommend?preset=home", {
          headers: {
            'Authorization': "Bearer 69a3250eC9ac2eFBc5ff804c9f20d0Bd66f4dDad"
          }
        }).then(function(result){
          if(result.status === 200){
            resolve(result.data);
          }else{
            resolve(null);
          }
        }).catch(function (error) {
          console.log(error);
          resolve(null);
        });
      }else{
        resolve(null);
      }
    });
  }

  async getCompanies(userId){
    return new Promise((resolve, reject) => {
      if(DOMAINS[window.location.hostname].recommended.apiUrl){
        axios.get(DOMAINS[window.location.hostname].recommended.apiUrl+(userId ? userId : "anonymous")+"/recommend?preset=companies", {
          headers: {
            'Authorization': "Bearer 69a3250eC9ac2eFBc5ff804c9f20d0Bd66f4dDad"
          }
        }).then(function(result){
          if(result.status === 200){
            if(result.data){
              resolve(result.data.items);
            }else{
              resolve(null);
            }
          }else{
            resolve(null);
          }
        }).catch(function (error) {
          console.log(error);
          resolve(null);
        });
      }else{
        resolve(null);
      }
    });
  }

  async getProducts(userId){
    return new Promise((resolve, reject) => {
      if(DOMAINS[window.location.hostname].recommended.apiUrl){
        // axios.defaults.headers.common['Authorization'] = "Bearer 69a3250eC9ac2eFBc5ff804c9f20d0Bd66f4dDad";
        axios.get(DOMAINS[window.location.hostname].recommended.apiUrl+(userId ? userId : "anonymous")+"/recommend?preset=products", {
          headers: {
            'Authorization': "Bearer 69a3250eC9ac2eFBc5ff804c9f20d0Bd66f4dDad"
          }
        }).then(function(result){
          if(result.status === 200){
            // console.log(result.data);
            if(result.data){
              resolve(result.data.items);
            }else{
              resolve(null);
            }
          }else{
            resolve(null);
          }
        }).catch(function (error) {
          console.log(error);
          resolve(null);
        });
      }else{
        resolve(null);
      }
    });
  }

  async getProjects(userId){
    return new Promise((resolve, reject) => {
      if(DOMAINS[window.location.hostname].recommended.apiUrl){
        axios.get(DOMAINS[window.location.hostname].recommended.apiUrl+(userId ? userId : "anonymous")+"/recommend?preset=projects", {
          headers: {
            'Authorization': "Bearer 69a3250eC9ac2eFBc5ff804c9f20d0Bd66f4dDad"
          }
        }).then(function(result){
          if(result.status === 200){
            // console.log(result.data);
            if(result.data){
              resolve(result.data.items);
            }else{
              resolve(null);
            }
          }else{
            resolve(null);
          }
        }).catch(function (error) {
          console.log(error);
          resolve(null);
        });
      }else{
        resolve(null);
      }
    });
  }

}

export default new RecommendedService();
