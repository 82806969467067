import React from 'react'
import { withTheme } from '../../themes'
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {getTranslations} from "./../../store/localization/selectors";

import Chunk from './../App/Chunk';
// import Logo from "./../App/Logo";
// import Spacer from './../App/Spacer';


// const Wrapper  = styled.div`
//   display: flex;
//   padding: 34px 0px 10px;
// `;
const HallTitle = styled.div`
  color: ${props => props.color};
  font-size: 12px;
  font-weight: 700;
`;
const HallText = styled.div`
  color: #585858;
  font-size: 12px;
  margin-bottom: 10px;

`;

const FindUs = ({
  children,
  logo = "default",
  theme
}) => {
  const translations = useSelector(getTranslations);

  return (
    <Chunk open={true} collapsible={false}>
      {/*logo ?
        <>
          <Logo
            variant={logo}
            height={{value: 40, unit: "px"}}
            width={{value: 60, unit: "%"}}
            alignment={"left center"}
          />
          <Spacer size={{value: 14, unit: "px"}}/>
        </>
      : null */}
      <HallTitle color={theme.color}>
        {translations["UI"]["_find-us-title"]}
      </HallTitle>
      <HallText>
        {/*translations["UI"]["_find-us-description"]*/}
      </HallText>

      {children}
    </Chunk>
  )
}

export default withTheme(FindUs)
