import React, {Component} from 'react';
import {debounce, pick} from 'lodash';
import {compose} from "redux";
import {connect} from 'react-redux';
import CookieBot from "react-cookiebot";
import {getParameterByName} from "../../helpers";

import {init, setLayout, toggleModal} from './../../store/settings/actions';
import {
    getAccountSettings,
    getArea,
    getConfig,
    getIfMobile,
    getIsPlansModalOpen,
    getIsWelcomeModalOpen,
    getLayout,
    getReady,
    getStatus,
    getSubarea
} from './../../store/settings/selectors';
import {loadTranslation, setLocale} from "../../store/localization/actions";
import {getLocale} from "../../store/localization/selectors";
import {
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XS,
  LAYOUT_LG,
  LAYOUT_MD,
  LAYOUT_SM,
  LAYOUT_XS
} from './../../settings';
import Meta from "./../App/Meta";
import {withTheme} from './../../themes';
import {Screen} from './styled';
import Topbar from "./Topbar/Topbar";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import Loader from '../Common/Loader';

import AnalyticsService from '../../services/AnalyticsService';
import {login} from "../../store/auth/actions";
import {getProfile} from "../../store/chat/actions";
import HotjarService from "../../services/HotjarService";
import * as moment from 'moment';
import {getPermissions, getRoles, getToken, getUser} from "../../store/auth/selectors";
import VIRUserPlansModal from "../../tenants/VIR/UserPlans/Modal";
import MDMUserPlansModal from "../../tenants/MDM/UserPlans/Modal";
import AGRUserPlansModal from "../../tenants/AGR/UserPlans/Modal";
import FICUserPlansModal from "../../tenants/FIC/UserPlans/Modal";
import SAMUserPlansModal from "../../tenants/SAM/UserPlans/Modal";

import MDMWelcomeModal from "../../tenants/MDM/WelcomeModal";

import FullModal from "./../Common/FullModal";
import DataService from "../../services/DataService";
import axios from "axios";
import LetsFair from "../LetsFair/LetsFair";

const mapStateToProps = (state) => ({
  layout: getLayout(state),
  config: getConfig(state),
  status: getStatus(state),
  area: getArea(state),
  subarea: getSubarea(state),
  isAppReady: getReady(state),
  isMobile: getIfMobile(state),
  language: getLocale(state),
  token: getToken(state),
  showPlansModal: getIsPlansModalOpen(state),
  showWelcomeModal: getIsWelcomeModalOpen(state),
  user: getUser(state),
  roles: getRoles(state),
  permissions: getPermissions(state),
  accountSettings: getAccountSettings(state),
  planSettings: state.settings.modals.plans
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

class Catalogue extends Component {

  constructor(props) {
    super(props);

    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount() {

    this.handleInit();
    this.handleWindowResize();
    this.handleDetectLanguage();
    window.addEventListener('resize', debounce(this.handleWindowResize, 200));

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (!prevProps.config && this.props.config) {

      const { tracking, awsAnalytics, tenant } = this.props.config;

      const analyticsCodes = tracking.filter(item => item.type === 'analytics' && item.domains.indexOf(window.location.host) !== -1).map(item => item.code);
      const hotjarCodes = tracking.filter(item => item.type === 'hotjar' && item.domains.indexOf(window.location.host) !== -1).map(item => item.code);

      if (analyticsCodes.length) {
        AnalyticsService.setConfig(analyticsCodes);
      }

      if(awsAnalytics) {
        const awsSettings = awsAnalytics.hasOwnProperty(window.location.hostname) ? awsAnalytics[window.location.hostname] : awsAnalytics.default;
        AnalyticsService.setAwsAnalticsConfig(tenant, awsSettings);
      }

      if (hotjarCodes.length) {
        HotjarService.setConfig(hotjarCodes);
      }
    }

    if (!prevProps.language && this.props.language) {

      if(this.props.language !== "en"){
        if(this.props.language === "zh"){
          require(`moment/locale/zh-cn`);
          moment.locale("zh-cn");
        }else{
          require(`moment/locale/${this.props.language}`);
          moment.locale(this.props.language);
        }
      }else{
        moment.locale(this.props.language);
      }

      DataService.setLanguage(this.props.language);
      axios.defaults.headers.common['Authorization'] = this.props.language;

    }


    if (!prevProps.isAppReady && this.props.isAppReady) {
      const { dispatch, user, permissions } = this.props;

      if (user) {
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Authorization'] = "Bearer " + user.accessToken;
      }

      if (user && permissions && permissions.length === 0) {
        dispatch(toggleModal('plans'));
      }

      if (this.props.location?.search && this.props.location.search.indexOf('show-welcome-modal') !== -1) {
          dispatch(toggleModal('welcome'));
      }
    }

    if (this.props.language !== this.props.match?.params?.lang) {
      this.handleDetectLanguage();
    }

  }

  handleInit() {
    const {dispatch} = this.props;
    dispatch(init());
    dispatch(loadTranslation());

  }

  handleWindowResize() {

    const {dispatch} = this.props;
    const layout = (window.innerWidth <= BREAKPOINT_XS ? LAYOUT_XS : (window.innerWidth <= BREAKPOINT_SM ? LAYOUT_SM : (window.innerWidth <= BREAKPOINT_MD ? LAYOUT_MD : LAYOUT_LG)));
    const isMobile = layout === LAYOUT_XS || layout === LAYOUT_SM;
    dispatch(setLayout({
      layout,
      isMobile
    }));
  }

  handleDetectLanguage() {

    const {dispatch} = this.props;
    let lang = this.props.match.params.lang ? this.props.match.params.lang : "it";
    if (lang === 'authorization'){
      let newUrl = getParameterByName('url', window.location.href);
      if(newUrl){
        newUrl = newUrl.replace(window.location.origin, '');
        lang = newUrl.substring(1, 3);
      }
    }
    dispatch(setLocale(lang));
  }

  handleCloseModal = () => {

    const { dispatch } = this.props;
    dispatch(toggleModal('plans'));

  }

  handleCloseWelcomeModal = () => {

    const { dispatch } = this.props;
    dispatch(toggleModal('welcome'));

  }

  handleModalSubmit = () => {

    const { dispatch } = this.props;
    dispatch(getProfile());

  }

  handleLogin = () => {

    this.props.dispatch(login());

  }

  render() {

    const {theme, layout, config, isAppReady, isMobile, token, showPlansModal, user, roles, permissions, language, accountSettings, showWelcomeModal} = this.props;
    let pluginEnv = 'production';
    let componentProps = pick(this.props, ['area', 'theme', 'config', 'isMobile', 'language', 'status', 'layout']);

    componentProps = Object.assign({}, componentProps, {
      analyticsEnabled: true
    });

    const forceModal = user && (permissions && permissions.length === 0);

    if (config && config.chatConfig) {
      const { chatConfig } = config;
      pluginEnv = chatConfig.hasOwnProperty(window.location.hostname) ? chatConfig[window.location.hostname].pluginEnv : chatConfig.default.pluginEnv;
    }

    return (
      !isAppReady ?
        (<Loader />)
        : (
          <>
            <Meta/>

            {config.cookiebot ?
              <CookieBot domainGroupId={config.cookiebot} language={language}/>
            : null }

            <div id="app" className={`${(isMobile ? "isMobile" : "isDesktop")} tenant-${config.tenant} skin-${config.skin}`}>
              {!(config.enable_compact_mobile_menu && (layout === LAYOUT_XS || layout === LAYOUT_SM)) ?
                <Topbar/>
              : null }
                <Screen id="screen"
                  layout={layout}
                  background={theme.background.global}
                  textFont={theme.typography.text.textFont}
                  textSize={theme.typography.text.textSize}
                  textWeight={theme.typography.text.textWeight}
                  textLineHeight={theme.typography.text.textLineHeight}
                  textLetterSpacing={theme.typography.text.textLetterSpacing}
                  textTransform={theme.typography.text.textTransform}
                  textColor={theme.typography.text.textColor}
                  compactMenu={(config.enable_compact_mobile_menu && (layout === LAYOUT_XS || layout === LAYOUT_SM))}
                >
                  {!isMobile ?
                    <Desktop {...componentProps} />
                    : <Mobile {...componentProps}/>
                  }
                </Screen>
            </div>
            <FullModal/>
            {(!showPlansModal && showWelcomeModal) &&
              <MDMWelcomeModal
                onClose={this.handleCloseWelcomeModal}
              />
            }
            {showPlansModal ?
              config.tenant === 'VIR' ? (
                <VIRUserPlansModal
                  token={token}
                  tenant={config.tenant}
                  accountSettings={accountSettings}
                  pluginEnv={pluginEnv}
                  locale={language}
                  roles={roles}
                  canClose={!forceModal}
                  onSubmit={this.handleModalSubmit}
                  onClose={this.handleCloseModal}
                  onLogin={this.handleLogin}
                />
              ) : config.tenant === 'MDM' ? (
                <MDMUserPlansModal
                  token={token}
                  tenant={config.tenant}
                  accountSettings={accountSettings}
                  pluginEnv={pluginEnv}
                  locale={language}
                  roles={roles}
                  canClose={!forceModal}
                  onSubmit={this.handleModalSubmit}
                  onClose={this.handleCloseModal}
                  onLogin={this.handleLogin}
                />
              ) : config.tenant === 'AGR' ? (
                <AGRUserPlansModal
                  token={token}
                  tenant={config.tenant}
                  accountSettings={accountSettings}
                  pluginEnv={pluginEnv}
                  locale={language}
                  roles={roles}
                  canClose={!forceModal}
                  onSubmit={this.handleModalSubmit}
                  onClose={this.handleCloseModal}
                  onLogin={this.handleLogin}
                />
              ) : config.tenant === 'FIC' ? (
                <FICUserPlansModal
                  token={token}
                  tenant={config.tenant}
                  accountSettings={accountSettings}
                  pluginEnv={pluginEnv}
                  locale={language}
                  roles={roles}
                  canClose={!forceModal}
                  onSubmit={this.handleModalSubmit}
                  onClose={this.handleCloseModal}
                  onLogin={this.handleLogin}
                />
              ) : config.tenant === 'SAM' ? (
                <SAMUserPlansModal
                  token={token}
                  tenant={config.tenant}
                  accountSettings={accountSettings}
                  pluginEnv={pluginEnv}
                  locale={language}
                  roles={roles}
                  canClose={!forceModal}
                  onSubmit={this.handleModalSubmit}
                  onClose={this.handleCloseModal}
                  onLogin={this.handleLogin}
                />
              ) : null
            : null}
            {config && config.hasOwnProperty('letzFair') && config.letzFair.enabled ? (
                <LetsFair apiKey={config.letzFair.key} jsUrl={config.letzFair.jsFile} />
            ) : null}
          </>
        )
    );

  }

}

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(Catalogue);
