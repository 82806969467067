import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import {loadTranslation, setLocale} from './actions'
import {setReady} from "../settings/actions";
import {getDefaultLanguage} from "../settings/selectors";
import {DOMAINS} from "./../../domains";

import TranslationsService from "../../services/TranslationsService";

function* setLanguageSaga({ payload }) {

  try {

    let defaultLanguage = yield select(getDefaultLanguage);
    const language = !payload || payload === 'authorization' ? defaultLanguage : payload;

    yield put(setLocale.success(language))

  } catch(e) {

    yield put(setLocale.failure("Failed"))

  }
}

function* loadTranslationSaga({ payload }) {

  try {
    let translations = {};




    if(DOMAINS[window.location.hostname].languages.indexOf("it") > -1){
      translations["it"] = yield call([TranslationsService, 'getTranslation'], "it");
    }
    if(DOMAINS[window.location.hostname].languages.indexOf("en") > -1){
      translations["en"] = yield call([TranslationsService, 'getTranslation'], "en");
    }
    if(DOMAINS[window.location.hostname].languages.indexOf("de") > -1){
      translations["de"] = yield call([TranslationsService, 'getTranslation'], "de");
    }
    if(DOMAINS[window.location.hostname].languages.indexOf("fr") > -1){
      translations["fr"] = yield call([TranslationsService, 'getTranslation'], "fr");
    }
    if(DOMAINS[window.location.hostname].languages.indexOf("es") > -1){
      translations["es"] = yield call([TranslationsService, 'getTranslation'], "es");
    }
    if(DOMAINS[window.location.hostname].languages.indexOf("pt") > -1){
      translations["pt"] = yield call([TranslationsService, 'getTranslation'], "pt");
    }
    if(DOMAINS[window.location.hostname].languages.indexOf("ru") > -1){
      translations["ru"] = yield call([TranslationsService, 'getTranslation'], "ru");
    }
    if(DOMAINS[window.location.hostname].languages.indexOf("zh") > -1){
      translations["zh"] = yield call([TranslationsService, 'getTranslation'], "zh");
    }
    if(DOMAINS[window.location.hostname].languages.indexOf("ko") > -1){
      translations["ko"] = yield call([TranslationsService, 'getTranslation'], "ko");
    }
    if(DOMAINS[window.location.hostname].languages.indexOf("ja") > -1){
      translations["ja"] = yield call([TranslationsService, 'getTranslation'], "ja");
    }

    // yield console.log(translations);
    yield put(loadTranslation.success(translations));
    yield put(setReady.trigger());

  } catch(e) {

    console.error(e);
    yield put(loadTranslation.failure(e))

  }
}

export default function* root() {
  yield all([
    yield takeLatest([setLocale.TRIGGER], setLanguageSaga),
    yield takeLatest([loadTranslation.TRIGGER], loadTranslationSaga),
  ])
}
