import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {withTheme} from './../../themes'

const Wrapper  = styled.div`
  position: relative;
  width: ${props => props.w !== null ? props.w.value + props.w.unit : "auto"};
  height: ${props => props.h !== null ? props.h.value + props.h.unit : "auto"};
  max-width: 100%;
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  margin-bottom: -6px;
}
`;
const Img  = styled.img`
  max-width: 100%;
  ${props => (props.w !== null && props.h !== null) ? `
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: ${props.fit};
    object-position: center;
  ` : ((props.w !== null) ? `
    width: ${props.w.value + props.w.unit};
  ` : `
    height: ${props.h.value + props.h.unit};
  `)}
`;

const Image = ({
    src = null,
    alt = "Missing ALT",
    width = {value: 100, unit: "%"},
    height = null,
    fit = "cover",
    theme
  }) => {
  return (
    <Wrapper w={width} h={height}>
      <Img w={width} h={height} src={src ? src : theme.placeholders.default} fit={fit} alt={alt} />
    </Wrapper>
  )
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  fit: PropTypes.string,
  width: PropTypes.object,
  height: PropTypes.object
}

export default withTheme(Image)
