import React from 'react';
import styled from 'styled-components';
import {withTheme} from './../../themes';

import Icon from "./Icon";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const Spinner = styled.div`
  animation: spinner 1.2s linear infinite;
  transform-origin: center;
  width: 31px;
  height: 31px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loading = ({
    variant = "default",
    theme
  }) => {
  return (
    <Wrapper>
      <Spinner>
        <Icon name={theme.icons.set.loading} size={{value: 30, unit: 'px'}} color={theme.loading.variant[variant].color} />
      </Spinner>
    </Wrapper>
  )
}

export default withTheme(Loading)
