import React, {Component} from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {withRouter} from 'react-router-dom'
import {compose} from "redux";
import {configureAnchors} from 'react-scrollable-anchor'

import {withTheme} from "../../themes";
import Analytics from "../Analytics/Analytics";

import DataService from './../../services/DataService';

import { scrollTo } from "./../../helpers";

import VirCompanySheet from './../../tenants/VIR/Companies/CompanySheet';
import VirProductSheet from './../../tenants/VIR/Products/ProductSheet';
import VirEventSheet from './../../tenants/VIR/Events/EventSheet';
import MdmCompanySheet from './../../tenants/MDM/Companies/CompanySheet';
import MdmProductSheet from './../../tenants/MDM/Products/ProductSheet';
import MdmCasehistorySheet from './../../tenants/MDM/Casehistories/CasehistorySheet';
import MdmEventSheet from './../../tenants/MDM/Events/EventSheet';
import MdmSubCompanySheet from './../../tenants/MDM/SubCompanies/SubCompanySheet';
import MdmSubCasehistorySheet from './../../tenants/MDM/SubCasehistories/SubCasehistorySheet';
import AgrCompanySheet from './../../tenants/AGR/Companies/CompanySheet';
import AgrProductSheet from './../../tenants/AGR/Products/ProductSheet';
import AgrEventSheet from './../../tenants/AGR/Events/EventSheet';
import FicCompanySheet from './../../tenants/FIC/Companies/CompanySheet';
import FicProductSheet from './../../tenants/FIC/Products/ProductSheet';
import FicEventSheet from './../../tenants/FIC/Events/EventSheet';
import SamCompanySheet from './../../tenants/SAM/Companies/CompanySheet';
import SamProductSheet from './../../tenants/SAM/Products/ProductSheet';
import SamEventSheet from './../../tenants/SAM/Events/EventSheet';

import RecentsService from "./../../services/RecentsService";
// import {setRecents} from "./../../store/auth/actions";
import Loader from "./../Common/Loader";
import {getCompanyActive} from "../../store/chat/actions";
import {getPermissions, getUser} from "../../store/auth/selectors";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
// const TopbarWrapper = styled.div`
//   flex-grow: 0;
//   flex-shrink: 0;
// `;
const PageWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const mapStateToProps = (state) => ({
  isAppReady: state.settings.ready,
  config: state.settings.config,
  layout: state.settings.layout,
  language: state.localization.current,
  translations: state.localization.translations,
  // area: state.settings.area,
  subarea: state.settings.subarea,
  companies: state.companies,
  user: getUser(state),
  permissions: getPermissions(state)
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

class Page extends Component {
  state = {
    loading: true,
    content: null
  };

  sectionReferences = null;

  constructor(props) {
    super(props);
    this.props = props;

    this.handleSectionClick = this.handleSectionClick.bind(this);
    this.handleAddReference = this.handleAddReference.bind(this);
  }

  componentWillMount() {
    configureAnchors({containerId: "pageWrapper", offset: -60, scrollDuration: 200})
  }

  componentDidMount() {

    this.getData();

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.isAppReady !== this.props.isAppReady ||
      prevProps.language !== this.props.language ||
      prevProps.area !== this.props.area ||
      prevProps.match.params.slug !== this.props.match.params.slug
    ) {

      this.sectionReferences = null;
      this.getData(prevProps);

    }

    if (prevState.loading !== this.state.loading && this.state.loading === false && window.location.hash.trim() !== '') {
      const anchor = window.location.hash.substr(1);
      this.handleSectionClick(null, anchor);
    }

  }

  async getData(prevProps) {

    const { dispatch, area, user } = this.props;

    const {slug} = this.props.match.params;
    let content;

    await this.setState({
      loading: true
    });

    if (area === 'companies') {
      content = await DataService.getItemById("companies", slug);
    } else if (area === 'products') {
      content = (await DataService.getItemById("products", slug));
      dispatch(getCompanyActive(content.scope.exhibitorId));

    } else if (area === 'casehistories') {
      content = (await DataService.getItemById("casehistories", slug));
      dispatch(getCompanyActive(content.scope.exhibitorId));

    } else if (area === 'events') {
      content = (await DataService.getItemById("events", slug));
      dispatch(getCompanyActive(content.scope.exhibitorId));

    } else if (area === 'subcompanies') {
      content = (await DataService.getItemById("subcompanies", slug));
      dispatch(getCompanyActive(content.scope.exhibitorId));

    } else if (area === 'subcasehistories') {
      content = (await DataService.getItemById("subcasehistories", slug));
      dispatch(getCompanyActive(content.scope.exhibitorId));

    }

    if(content && user){
      const { accessToken, id } = user;
      await RecentsService.add(accessToken, id, area, slug);
      // dispatch(setRecents(recents));
    }

    this.setState({
      content,
      loading: false
    })

  }

  handleSectionClick(e, ref) {
    if (e)
      e.preventDefault();

    if (this.sectionReferences && this.sectionReferences.hasOwnProperty(ref)) {
      const section = this.sectionReferences[ref];
      scrollTo(this.wrapper, section.offsetTop+180, 1000)
    }


  }

  handleAddReference(name, ref) {

    this.sectionReferences = Object.assign({}, this.sectionReferences, {
      [name]: ref
    });

  }

  renderContent() {

    const {config, area, subarea, permissions} = this.props;
    const {content, loading} = this.state;

    if (!content || loading) return <Loader />

    const props = {
      addSectionReference: this.handleAddReference,
      onSectionClick: this.handleSectionClick,
      data: content,
      subarea,
      permissions
    }

    switch (area) {
      case "companies":
        switch (config.tenant) {
          case "VIR":
            return (
              <>
                <Analytics name={area} id={content.id} />
                <VirCompanySheet {...props}/>
              </>
            );
          case "MDM":
            return (
              <>
              <Analytics name={area} id={content.id} />
              <MdmCompanySheet {...props}/>
              </>
            );
          case "AGR":
            return (
              <>
                <Analytics name={area} id={content.id} />
                <AgrCompanySheet {...props}/>
              </>
            );
          case "FIC":
            return (
              <>
                <Analytics name={area} id={content.id} />
                <FicCompanySheet {...props}/>
              </>
            );
          case "SAM":
            return (
              <>
                <Analytics name={area} id={content.id} />
                <SamCompanySheet {...props}/>
              </>
            );
          default:
            return <div>default</div>;

        }
      case "products":
        switch (config.tenant) {
          case "VIR":
            return (
              <>
                <Analytics name={area} id={content.objectID} />
                <VirProductSheet {...props}/>
              </>
            );
          case "MDM":
            return (
              <>
                <Analytics name={area} id={content.objectID} />
                <MdmProductSheet {...props}/>
              </>
            );
          case "AGR":
            return (
              <>
                <Analytics name={area} id={content.objectID} />
                <AgrProductSheet {...props}/>
              </>
            );
          case "FIC":
            return (
              <>
                <Analytics name={area} id={content.objectID} />
                <FicProductSheet {...props}/>
              </>
            );
          case "SAM":
            return (
              <>
                <Analytics name={area} id={content.objectID} />
                <SamProductSheet {...props}/>
              </>
            );
          default:
            return <div>default</div>;

        }
      case "events":
        switch (config.tenant) {
          case "VIR":
            return (
              <>
                <Analytics name={area} id={content.id} />
                <VirEventSheet {...props}/>
              </>
            );
          case "MDM":
            return (
              <>
                <Analytics name={area} id={content.id} />
                <MdmEventSheet {...props}/>
              </>
            );
          case "AGR":
            return (
              <>
                <Analytics name={area} id={content.id} />
                <AgrEventSheet {...props}/>
              </>
            );
          case "FIC":
            return (
              <>
                <Analytics name={area} id={content.id} />
                <FicEventSheet {...props}/>
              </>
            );
          case "SAM":
            return (
              <>
                <Analytics name={area} id={content.id} />
                <SamEventSheet {...props}/>
              </>
            );
          default:
              return <div>default</div>

        }
      case "casehistories":
        switch (config.tenant) {
          case "MDM":
            return (
              <>
                <Analytics name={area} id={content.id} />
                <MdmCasehistorySheet {...props}/>
              </>
            );

          default:
            return <div>default</div>;

        }
      case "subcompanies":
        switch (config.tenant) {
          case "MDM":
            return (
              <>
                <Analytics name={area} id={content.id} />
                <MdmSubCompanySheet {...props}/>
              </>
            );

          default:
            return <div>default</div>;

        }
      case "subcasehistories":
        switch (config.tenant) {
          case "MDM":
            return (
              <>
                <Analytics name={area} id={content.id} />
                <MdmSubCasehistorySheet {...props}/>
              </>
            );

          default:
            return <div>default</div>;

        }
      default:
        return <div>default</div>;

    }



  }

  render() {

    // const {config, layout, analytics, area, subarea, showListButton} = this.props;

    return (
      <Wrapper>
        {/*<TopbarWrapper>
          {layout === LAYOUT_XS || layout === LAYOUT_SM ?
            <MobileTopbar hideListButton={!showListButton} area={area} subarea={subarea} />
            :
            <DesktopTopbar/>
          }
        </TopbarWrapper>*/}
        <PageWrapper id="pageWrapper" ref={ref => this.wrapper = ref}>
          {this.renderContent()}
        </PageWrapper>
      </Wrapper>
    );
  }
}

export default compose(
  withRouter,
  withTheme,
  connect

  (
    mapStateToProps
    ,
    mapDispatchToProps
  )
)(
  Page
)
;
