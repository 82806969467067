import styled from "styled-components";
import {LAYOUT_SM, LAYOUT_XS} from "../../settings";

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const FilterWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "-100vw" : 0};
  width: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "100vw" : props.widthSize.value + props.widthSize.unit};
  height: 100%;
  ${props => props.background ? "background: " + props.background + ";" : ""};
  padding-top: 25px;
`;
export const ContentWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "100%" : props.widthSize.value + props.widthSize.unit};
  height: 100%;
  display: flex;
  flex-direction: column;
  ${props => props.background ? "background: " + props.background + ";" : ""}
  padding-top: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? 5 : 25}px;
  color: ${props => props.color};
`;

export const TopbarWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;
export const LogoWrapper = styled.div`
  width: 100%;
  height: ${props => props.height.value + props.height.unit};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ListWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
`;

export const OverlayWrapper = styled.div`
  position: absolute;
  left: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "100vw" : props.widthSize.value + props.widthSize.unit};
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
`
