import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {withTheme} from './../../themes'

const Wrapper  = styled.div`
  position: relative;
  width: ${props => props.w !== null ? props.w.value + props.w.unit : "auto"};
  height: ${props => props.h !== null ? props.h.value + props.h.unit : "auto"};
  max-width: 100%;
  text-align: center;
  display: inline-block;
  margin: 0 auto;
`;
const Img  = styled.img`
  max-width: 100%;
  ${props => (props.w !== null && props.h !== null) ? `
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: ` + props.alignment + `;
  ` : ((props.w !== null) ? `
    width: ${props.w.value + props.w.unit};
  ` : `
    height: ${props.h.value + props.h.unit};
  `)}
`;

const Logo = ({
    variant = "default",
    width = null,
    height = null,
    alignment = "center",
    theme
  }) => {
  return (
    <Wrapper w={width} h={height}>
      <Img w={width} h={height} alignment={alignment} src={theme.logo.variant[variant]} alt="Logo" />
    </Wrapper>
  )
}

Logo.propTypes = {
  variant: PropTypes.string, //V: vertical H: horizontal
  width: PropTypes.object,
  height: PropTypes.object
}

export default withTheme(Logo)
