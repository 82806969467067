import React from 'react';
// import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import styled from 'styled-components';
// import ReactHtmlParser from 'react-html-parser';
// import { getTranslations } from "./../../../store/localization/selectors";
// import { getConfig } from './../../../store/settings/selectors';
// import { getLayout } from './../../../store/settings/selectors';
import { useParams } from 'react-router-dom';
import { useTheme } from './../../../themes';
// import { getTranslations } from "../../store/localization/selectors";

import Image from './../../../components/App/Image';
import Proportional from "./../../../components/App/Proportional";
// import Button from './../../../components/App/Button';
// import {LAYOUT_XS, LAYOUT_SM} from './../../../settings';


// const Wrapper = styled.div`
//   display: flex;
//   background-color: #fff;
//   flex-direction: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "row" : "column"};
// `;
// const Photo = styled.div`
//   flex-basis: 100px;
//   flex-grow: 0;
//   flex-shrink: 0;
//   height: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "110px" : "200px"};
// `;
// const Description = styled.div`
//   margin-${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "left" : "top"}: 20px;
//   flex-grow: 1;
//   flex-shrink: 1;
// `;

// const Type = styled.div`
//   color: #a1a1a1;
//   font-size: 12px;
//   font-weight: 700;
//   text-transform: uppercase;
//   margin-bottom: 5px;
// `;
// const Name = styled.div`
//   color: #E4018E;
//   font-size: 15px;
//   font-weight: 700;
//   text-transform: uppercase;
//   margin-bottom: 10px;
// `;
// // const Year = styled.div`
// //   color: #000000;
// //   font-size: 13px;
// //   font-weight: 700;
// //   text-transform: uppercase;
// //   margin-bottom: 10px;
// // `;
// const Designation = styled.div`
//   color: #606060;
//   font-size: 13px;
//   font-weight: 700;
//   margin-bottom: 10px;
// `;
// const Category = styled.div`
//   color: #606060;
//   font-size: 13px;
//   font-weight: 700;

// `;


// const Wrapper = styled.div`
//   display: flex;
//   flex-direction: column;
// `;
const ImageCnt = styled.div`
  border-radius: 5px;
  overflow: hidden;
`;
const Upper = styled.div`
  color: #E5087E;
  font-size: 10px;
  margin-top: 5px;
`;
const Title = styled.div`
  color: #000000;
  font-size: 14px;
  margin-top: 15px;
  font-weight: 600;
  height: 36px;
  overflow: hidden;
`;
// const Lower = styled.div`
// `;


function MdmProductThumb(props) {

  const theme = useTheme();
  // const config = useSelector(getConfig);
  // const translations = useSelector(getTranslations);
  // const layout = useSelector(getLayout)
  const { item } = props;
  let { lang } = useParams()

  // let subareaSlug = null;
  // if(item){
  //   let subareas = config.areas["products"].subareas;
  //   Object.keys(subareas).forEach(function(index){
  //     if(subareas[index].key === item.type){
  //       subareaSlug = index;
  //     }
  //   });
  // }

  return item ? (
    <>
      <Link to={"/"+lang+"/products/"+item.id}>
        <ImageCnt>
          <Proportional ratio={1}>
            <Image
              src={item.options.headerImage ? item.options.headerImage.src : theme.placeholders.product}
              height={{"value": 100, "unit": "%"}}
              width={{"value": 100, "unit": "%"}}
              fit={"cover"}
              alt={item.options.name[lang]}
            />
          </Proportional>
        </ImageCnt>
        <Upper>{item.options.exhibitor.name[lang]}</Upper>
        <Title>{item.options.name[lang]}</Title>
        {/*<Lower>{ReactHtmlParser(product.lowertext)}</Lower>*/}
      </Link>
      {/*<Link to={"/"+lang+"/products/"+item.id}>
        <Wrapper layout={layout}>
          <Photo layout={layout}>
              <Image fit="contain" width={{value: 100, unit: "%"}} height={{value: 100, unit: "%"}} src={item.options.headerImage ? item.options.headerImage.src : theme.placeholders.product} alt={item.options.name[lang]} />
          </Photo>
          <Description layout={layout}>
            <Type>{translations["UI"]["_label-products"]}</Type>
            <Name>{item.options.name[lang]}</Name>
            <Designation>{item.options.typology && item.options.typology.classification ? (item.options.typology.classification ? item.options.typology.classification[lang] : "") + (item.options.typology.category ? ", " + item.options.typology.category[lang] : "") + (item.options.typology.subCategory ? ", " + item.options.typology.subCategory[lang] : "") : ""}</Designation>
          </Description>
        </Wrapper>
      </Link>*/} 
    </> 
  ) : <></>;
}

export default React.memo(MdmProductThumb)
