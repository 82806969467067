import produce from "immer"
import {handleActions} from 'redux-actions';
import {getRecords} from './actions'

const INITIAL_STATE = {
  list: []
};

const reducer = handleActions({

  [getRecords.SUCCESS]: (state, {payload}) => produce(state, draft => {
    draft.list = [...payload];
  }),

}, INITIAL_STATE);

export default reducer;
