import React from 'react';

import './Button.scss';
import Icon from "../../../App/Icon";

const Button = ({ active, onClick, label, count, hideCount = false }) => {
  return (
    <button className={`FilterButton`} onClick={onClick}>
      <div className={'LevelButton__Text'}>{label}</div>
      {active ? <Icon name={'tick-1'} color={'#fff'} size={{value: 20, unit: 'px'}} /> : null}
      {!hideCount ? (!count ? <Icon name={'arrow-right-2'} color={'#fff'} size={{value: 20, unit: 'px'}} /> : <div className={'LevelButton__RightCount'}>{count}</div>) : null}
    </button>
  );
}

export default Button;
