import React from "react";
import styled from "styled-components";


const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: block;
`;
const Push = styled.div`
	display: block;
  padding-top: ${props => props.ratio}%;
`;
const Content = styled.div`
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;


const Proportional = ({ ratio=1, children }) => {
  if(ratio === 1){
    ratio = 1.000001;
  }

  return (
    <Wrapper>
      <Push ratio={1/ratio*100} />
      <Content>
        {children}
      </Content>
    </Wrapper>
  )
}

export default Proportional;
