import axios from "axios";
import {DOMAINS} from "./../domains";

export class NewsService {

  constructor() {

    this.getList = this.getList.bind(this);
    this.getDomainConfig = this.getDomainConfig.bind(this);

  }

  getDomainConfig() {
    return DOMAINS[window.location.hostname];
  }

  async getList(category = null){

    const domainConfig = this.getDomainConfig();

    return new Promise((resolve, reject) => {
      if(domainConfig.news.apiUrl){
        axios.get(domainConfig.news.apiUrl, {
          headers: {
            'Authorization': "Bearer 69a3250eC9ac2eFBc5ff804c9f20d0Bd66f4dDad"
          }
        }).then(function(result){
          // console.log(result);
          if(result.status === 200){
            if (category)
              resolve(result.data[category]);
            else
              resolve(result.data);
          }else{
            resolve(null);
          }
        }).catch(function (error) {
          console.log(error);
          resolve(null);
        });
      }else{
        resolve(null);
      }
    });
  }

}

export default new NewsService();
