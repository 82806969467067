import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import ListColumn from "./ListColumn";
import Button from "./Button";
import {useSelector} from "react-redux";
import {getTranslations} from "../../../store/localization/selectors";
import {getTranslation} from "../../../helpers";
import {useTheme} from "../../../themes";

const Wrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  border-radius: 10px;
  background: #f2f4f4;
  padding: 30px 15px;
  max-height: calc(100vh - 30px);
  overflow: auto;
`;

const Container = styled.div`
  position: relative;
  width: 94%;
  //max-width: 900px;
  margin: 0 auto;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Columns = styled.div`
  @media(min-width: 992px) {
    display: flex;
    /* margin-right: 25%; */
  }

  > div:last-child > *{
    padding-right: 0;
  }
`;



export default function List({onClick, onClose, canClose, token, roles}) {

  const isBasic = !!token;
  const translations = useSelector(getTranslations);
  const signinLabelFree = getTranslation(translations, ['plans','button-free']);
  const signinLabelPremium = getTranslation(translations, ['plans','button-premium']);
  const theme = useTheme();

  const items = [
    getTranslation(translations, ['plans', 'homepage']),
    getTranslation(translations, ['plans', 'partial-sheet']),
    getTranslation(translations, ['plans', 'full-data-brandroom']),
    getTranslation(translations, ['plans', 'products-projects']),
    getTranslation(translations, ['plans', 'events']),
    getTranslation(translations, ['plans', 'advanced-filters']),
    getTranslation(translations, ['plans', 'chat-contacts']) /*+ ' *'*/,
  ];

  const description = getTranslation(translations, ['plans', 'description']);
  //const description2 = getTranslation(translations, ['plans', 'chat-contacts-soon']);

  return (
    <Wrapper>
      <div style={{ position: 'relative '}}>
        {canClose ? <Button style={{ position: 'absolute', right: '20px', top: '-5px'}} type="close" onClick={onClose} /> : null}
        <Container>
          <Header />
          <Columns>
            <ListColumn isList items={items} />
            <ListColumn primaryColor={theme.colors.primary} signInLabel={signinLabelFree} onClick={() => onClick('basic')} isSelected={isBasic} actives={[1,1,0,0,0,0,0]} title={getTranslation(translations, ['plans', 'plan1'])} subtitle={null} items={items} />
            <ListColumn primaryColor={theme.colors.primary} signInLabel={signinLabelPremium} onClick={() => onClick('premium')} actives={[1,1,1,1,1,1,1]} title={getTranslation(translations, ['plans', 'plan2'])} subtitle={getTranslation(translations, ['plans', 'plan2_description'])} items={items} isLast />
            <ListColumn isList={true} isText={true} isLast>
              <p>{ReactHtmlParser(description)}</p>
              {/*<p>*{ReactHtmlParser(description2)}</p>*/}
            </ListColumn>
          </Columns>
        </Container>
      </div>
    </Wrapper>
  );

}
