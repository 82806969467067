import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTheme } from './../../themes';

const Wrapper = styled.div`
  padding-bottom: 8px;
  margin-bottom: 30px;
  border-bottom: ${props => props.borderSize + "px " + props.borderStyle + " " + props.borderColor};

`
const Label = styled.div`
  padding-bottom: 8px;
  color: ${props => props.textColor};
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
`
const Value = styled.div`
`

const LabelledValue = ({
  variant = "default",
  border = true,
  label,
  children
}) => {
  const theme = useTheme()

  return (
    <Wrapper
      borderColor={theme.labelledValue.variant[variant].borderColor}
      borderSize={border ? theme.labelledValue.borderSize : 0}
      borderStyle={theme.labelledValue.borderStyle}
    >
      <Label
        textColor={theme.labelledValue.variant[variant].textColor}
        textFont={theme.labelledValue.textFont}
        textSize={theme.labelledValue.textSize}
        textWeight={theme.labelledValue.textWeight}
        textLineHeight={theme.labelledValue.textLineHeight}
        textLetterSpacing={theme.labelledValue.textLetterSpacing}
        textTransform={theme.labelledValue.textTransform}
      >
        {label}
      </Label>
      <Value>
        {children}
      </Value>
    </Wrapper>
  )
}

LabelledValue.propTypes = {
  variant: PropTypes.string,
  label: PropTypes.string,
  border: PropTypes.bool,
}

export default React.memo(LabelledValue)
