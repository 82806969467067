import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {compose} from "redux";
import ReactHtmlParser from 'react-html-parser';
// import { useParams } from 'react-router-dom';
import {withTheme} from './../../../themes';
import {getLocale, getTranslations} from "./../../../store/localization/selectors";
import {getConfig, getLayout,} from './../../../store/settings/selectors';
import { getFavorites } from './../../../store/auth/selectors';
import { getActiveCompanies } from "../../../store/chat/selectors";
// import { DOMAINS } from "./../../../domains";
import {LAYOUT_MD, LAYOUT_SM, LAYOUT_XS} from './../../../settings'
import LabelledValue from "./../../../components/Common/LabelledValue"
import Container from './../../../components/App/Container';
import Spacer from './../../../components/App/Spacer';
import Chunk from './../../../components/App/Chunk';
import InfoRow from './../../../components/App/InfoRow';
import Gallery from './../../../components/App/Gallery';
import Image from './../../../components/App/Image';
import Button from './../../../components/App/Button';
import List from './../../../components/App/List';
import Icon from './../../../components/App/Icon';
import Modal from './../../../components/App/Modal/Modal';
// import Logo from "./../../../components/App/Logo";
import ReadMore from "./../../../components/App/ReadMore";
// import ReadMoreModal from "./../../../components/App/ReadMoreModal";
import Actionbar from "./../../../components/Common/Actionbar";
import Proportional from './../../../components/App/Proportional';

import Result from './../../../components/List/Sidebar/ResultsList/Result';
// import CompanyResult from './../Companies/CompanyResult';
import ProductThumb from './../Products/ProductThumb';
// import EventThumb from './../Events/EventThumb';
import CasehistoryThumb from './../Casehistories/CasehistoryThumb';
import ContactForm from "./../../../components/Common/ContactForm";
import FindUs from "./../../../components/Common/FindUs";
import {getPermissions, getUser} from "./../../../store/auth/selectors";
import {contactCompany, getCompanyActive} from "../../../store/chat/actions";

import {showPlansModal} from "../../../store/settings/actions";

import {PERMISSION_CHAT} from "../../../store/auth/permissions";
import {setFavorite} from "../../../store/auth/actions";

import NewsService from './../../../services/NewsService';
import Post from './../../../components/App/Post';
import Slider from './../../../components/App/Slider';
// import {hideFullModal} from "./../../../store/settings/actions";

import CompactMenuIcon from './../../../components/App/CompactMenuIcon';
import Meta from "./../../../components/App/Meta";

const Fixed = styled.div`
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 999;
  background: #FFFFFF;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
`;
const MainContainer = styled.div`
  margin-top: 3px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
`;
const Title = styled.h1`
  font-weight: bold;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  margin-left: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;

`;
const MacroCol = styled.div`
  padding: 0px ${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
`;
const Row = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -7px;
  margin-left: -7px;

`;
const Col = styled.div`
  padding: 0px 7px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
`;
// const Center = styled.div`
//   text-align: center;
//   display: flex;
//   justify-content: space-around;
// }
// `;

// const SlickSlide = styled.div`
//   padding: 0 8px;
// 	box-sizing: border-box;
// `;

const Text = styled.div`
  column-count: ${props => props.cols && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.cols : 1};

  & a:hover{
    font-weight: bold;
  }
`;

const ContactsModal = styled.div`
  width: 100%;
  max-width: 500px;
  background: #fff;
  padding: 30px;
`;

const ContactsModalHeader = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const SocialLink = styled.span`
  position: relative;
  top: -8px;
`;

const Position = styled.div`
  display: inline-block;
  background: #E4018E;
  color: #fff;
  text-align: center;
  padding: 8px 22px;
  border-radius: 12px;
  font-size: 15px;
  margin-bottom: 10px;
  margin-right: 10px;
`;

const HallImageCnt = styled.div`
  position: relative;
`;
const HallImage = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;
const ListHighlight = styled.div`
  text-trasform: uppercase;
  color: #E4018E;
  font-size: 13px;
  font-weight: 700;
`;

// const HallTitle = styled.div`
//   color: #E4018E;
//   font-size: 12px;
//   font-weight: 700;
// `;
// const HallText = styled.div`
//   color: #000000;
//   font-size: 12px;
//   margin-bottom: 10px;

// `;

const PremiumCta = styled.div`
  text-align: center;
  margin: 30px auto;
  color: #ccc;

  button {
    display: inline-block;
  }
`;




const AttachmentsValue = styled.div`
  display: flex;
  flex-direction: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "row" : "column"};
  border-bottom: 1px solid #E4018E;
  align-items: center;
`;
const AttachmentsValueCell = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 10 }px 0px;
  margin-right: 10px;
  text-align: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "left" : "center" };

  &:nth-child(1){
    width: 75%;
  }
  &:nth-child(2){
    width: 25%;
  }
`;
const AttachmentName = styled.div`
  color: #E4018E;
  font-weight: bold;
  font-size: 13px;
`;

// const RelationsModal = styled.div`
//   display: flex;
//   flex-direction: column;
//   height: 100%;
// `;
// const RelationsModalTitle = styled.div`
//   color: #E4018E;
//   font-size: 15px;
//   line-height: 18px;
//   font-weight: 700;
//   border-bottom: 1px solid #dedede;
//   padding-bottom: 20px;
//   margin-bottom: 20px;
// `;
// const RelationsModalContent = styled.div`
//   width: 100%;
//   overflow-x: hidden;
//   overflow-y: auto;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;

//   & > * {
//     flex-basis: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "calc(33.333333% - 12px)" : "100%"};
//     margin-bottom: 15px;
//   }
// `;

export const CompactMenuIconWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
   width: 46px;
   height: 46px;
   flex-grow: 0;
   flex-shrink: 0;
   margin-left: 7px;
   z-index: 9999;
`;

export const Grid = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 30px;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  margin: 20px 0px;
`;
export const GridElement = styled.div`
  
`;

const PeopleValue = styled.div`
  display: flex;
  flex-direction: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "row" : "column"};
  border-bottom: 1px solid #E4018E;
  align-items: center;
`;
const PeopleValueCell = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 10 }px 0px;
  margin-right: 10px;
  text-align: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "left" : "center" };

  &:nth-child(1){
    width: 15%;
  }
  &:nth-child(2){
    width: 30%;
  }
  &:nth-child(3){
    width: 40%;
  }
  &:nth-child(4){
    width: 15%;
  }
`;

const PersonAvatar = styled.img`
  border: 1px solid #E4018E;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`;
const PersonAvatarPlaceholder = styled.img`
  border: 1px solid #E4018E;
  width: 100%;
  height: 100%;
  background-color: #dedede;
  border-radius: 100%;
`;
const PersonName = styled.div`
  color: #E4018E;
  font-weight: bold;
  font-size: 16px;
`;
const PersonRole = styled.div`
  color: #606060;
  font-weight: normal;
  font-size: 13px;
`;
// const PersonDescription = styled.div`
//   color: #606060;
//   font-weight: normal;
//   font-size: 14px;
// `;


const mapStateToProps = (state) => ({
  translations: getTranslations(state),
  config: getConfig(state),
  layout: getLayout(state),
  user: getUser(state),
  lang: getLocale(state),
  permissions: getPermissions(state),
  favorites: getFavorites(state),
  activeCompanies: getActiveCompanies(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  showPlansModal: dispatch => dispatch(showPlansModal),
});

class CompanySheet extends Component {

  state = {
    showModal: null,
    chatEnabled: false,
    banners: []
  }

  sectionReferences = null;

  constructor(props) {
    super(props);
    this.props = props;

    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {

    if (!this.props.activeCompanies.hasOwnProperty(this.props.data.id)) {
      this.props.dispatch(getCompanyActive(this.props.data.id));
    }
    if(this.state.banners.length === 0){
      NewsService.getList("Aziende").then(ret  => {
        // console.log(ret);
        this.setState({
          banners: ret
        });
      }).catch(err => console.log('There was an error:' + err));
    }


  }

  componentDidUpdate(prevProps) {
  }

  toggleModal(modal) {
    this.setState({
      showModal: modal
    })
  }

  chatHandler = () => {

    if (!this.isCompanyActive()) return;

    const { permissions, dispatch } = this.props;
    const hasPermission = permissions.indexOf(PERMISSION_CHAT) !== -1;
    if (hasPermission) {
      this.props.dispatch(contactCompany({
        companyName: this.props.data.options.name,
        companyId: this.props.data.id
      }));
      this.props.history.push('/it/chat');
    } else {
      dispatch(showPlansModal())
    }


  }

  likeHandler = (favorites) => {

    this.props.dispatch(setFavorite({
      area: 'exhibitor',
      idElement: this.props.data.id,
      isFavorite: favorites && favorites.filter(item => item.itemType === "exhibitor" && item.itemId === this.props.data.id).length > 0
    }));

  }

  memoHandler = () => {
  }

  isCompanyActive = () => {
    const {activeCompanies, data} = this.props;
    return activeCompanies && data && activeCompanies.hasOwnProperty(data.id) && activeCompanies[data.id];
  }

  render() {
    const { props } = this;

    const theme = props.theme;
    const {data, translations, lang, config, layout, favorites, permissions} = props;

    const isPremium = permissions.indexOf('fullexhibitor:r') !== -1;

    const { showModal } = this.state;

    let chatEnabled = this.isCompanyActive();

    let modalText = '';

    if (showModal) {
      modalText = translations['sheet-company'][`_intro-contatto-diretto-${showModal.type}`].replace('%s', showModal.name);
    }

    // console.log(data.servicesData.location.hallAndBooth);

    return data ? (
      <>
        <Meta
            title={data ? data.options.name : null}
            description={data ? (
              data.options.name + " " + 
              (data.options.locations && data.options.locations.length > 0 ? (
                data.options.locations[0].city + " " + 
                (data.options.locations[0].countryId ? data.options.locations[0].countryId[lang] : null) + " "
              ) : null)
            ) : null}
            image={data && data.options.logo ? data.options.logo.src : null}
        />
        {layout === LAYOUT_XS || layout === LAYOUT_SM ? 
          <CompactMenuIconWrapper>
            <CompactMenuIcon />
          </CompactMenuIconWrapper>
        : null }
        
        <Fixed>
          <Container>
            <Actionbar list={[
              {
                name: translations["UI"]["Memo"],
                icon: theme.icons.set.memo,
                onClick: this.memoHandler,
                show: false,
                isActive: false,
                isDisabled: false
              },{
                name: favorites && favorites.filter(item => item.itemType === "exhibitor" && item.itemId === data.id).length > 0 ? translations["UI"]["Liked"] : translations["UI"]["Like"],
                icon: theme.icons.set.favorite,
                onClick: () => this.likeHandler(favorites),
                show: false,
                isActive: favorites && favorites.filter(item => item.itemType === "exhibitor" && item.itemId === data.id).length > 0,
                isDisabled: false
              },{
                name: translations["UI"]["Contact"],
                icon: theme.icons.set.contact,
                onClick: () => this.toggleModal({type:'company', email: data.options.contacts.filter((item) => item.classification._id === "email").map((item) => item.value)[0], name: data.options.name}),
                show: data.options.contacts && data.options.contacts.filter((item) => item.classification._id === "email").length,
                isActive: false,
                isDisabled: false
              },{
                name: translations["UI"]["Chat"],
                icon: theme.icons.set.chat,
                onClick: this.chatHandler,
                show: config.enable_chat,
                isActive: chatEnabled,
                isDisabled: !chatEnabled
              },{
                name: translations["UI"]["Save"],
                icon: theme.icons.set.calendar,
                onClick: null,
                show: false,
                isActive: false,
                isDisabled: false
              }
            ]}/>
          </Container>
        </Fixed>
        <Wrapper>
          <MainContainer>
            <Container>


            <Title>
              <Icon color={"#606060"} name={theme.icons.set.homepage} size={{value: 25, unit: "px"}} />&nbsp;&nbsp;
              {data.options.name}
            </Title>
            
            {(layout === LAYOUT_XS || layout === LAYOUT_SM) & ((data.servicesData && data.servicesData.location && data.servicesData.location.hallAndBooth && data.servicesData.location.hallAndBooth.length > 0) && config.enable_halls) ?
              <>
                <br/>
                {data.servicesData.location.hallAndBooth.slice(0, 3).map((item, index) =>
                  <Position key={`hall-${index}`}>{item.hallName[lang] + (item.booth ? " " + translations["UI"]["Stand"] + " " + item.booth : "")}</Position>
                )}
                <Spacer size={{value: 14, unit: "px"}}/>
              </>
            : null }
              
            <Spacer size={{value: 12, unit: "px"}} />

            <MacroRow layout={layout}>
              <MacroCol size="66" layout={layout}>
              {data.options.logo || data.options.headerImage?
                <>
                  <Row layout={layout}>
                    {data.options.logo ?
                      <Col size={data.options.headerImage ? 50 : 100} layout={layout}>
                        <Chunk open={true} collapsible={false} fullHeight={true}>
                          <Image fit={"contain"} height={{"value": 100, "unit": "px"}} src={data.options.logo.src} alt="logo"/>
                        </Chunk>
                      </Col>
                    : null}
                    {layout !== LAYOUT_XS && layout !== LAYOUT_SM ?
                      null
                    :
                      <Spacer size={{value: 12, unit: "px"}} />
                    }
                    {data.options.headerImage ?
                      <Col size={data.options.logo ? 50 : 100} layout={layout}>
                        <Chunk open={true} collapsible={false} fullHeight={true} noPadding={true}>
                          <Image width={{value: 100, unit: "%"}} height={
                            layout !== LAYOUT_XS && layout !== LAYOUT_SM ?
                              (data.logo ? {"value": 100, "unit": "%"} : {"value": 150, "unit": "px"})
                            :
                              {"value": 150, "unit": "px"}
                          } src={data.options.headerImage.src} alt="cover"/>
                        </Chunk>
                      </Col>
                    : null}
                  </Row>
                  <Spacer size={{value: 14, unit: "px"}}/>
                </>
              : null}

                {isPremium && ((data.options.gallery && data.options.gallery.length > 0) || (data.options.video && data.options.video.length > 0)) ?
                  <>
                    <Chunk title={translations["sheet-company"]["Gallery"]} open={true}>
                      <Gallery
                        images={data.options.gallery && data.options.gallery.length > 0 ? data.options.gallery : []}
                        videos={data.options.video && data.options.video.length > 0 ? data.options.video : []}
                        lang={lang}
                      />
                    </Chunk>
                    <Spacer size={{value: 14, unit: "px"}}/>
                  </>
                : null}

                <Chunk title={translations["sheet-company"]["Contacts"]}>

                  {/*}{data.country || data.region || data.province ?
                    <InfoRow
                      label={translations["sheet-company"]["Geographical area"]}
                      value={<>
                        {data.country} <br/>
                        {data.region}<br/>
                        {data.province}
                      </>}
                    />
                  : null }*/}
                  {data.options.locations && data.options.locations.length > 0 ?
                    <InfoRow
                      label={translations["sheet-company"]["Address"]}
                      value={data.options.locations.map((val, i) =>
                        <>
                          {val.classification && val.classification[lang] ? <><b>{val.classification[lang]}</b><br/></> : null}
                          {val.address}<br/>
                          {val.city} {val.zip} {val.provinceId ? val.provinceId[lang] : null}<br/>
                          {val.countryId ? val.countryId[lang] : null}<br/>
                          {i < data.options.locations.length-1 ?<br/> : null}
                        </>
                      )}
                    />
                  : null}
                  {data.options.languages && data.options.languages.length > 0 ?
                    <InfoRow
                      label={translations["sheet-company"]["Languages spoken"]}
                      value={data.options.languages.map(val =>
                        <span key={`language-${val[lang]}`}>{val[lang]}<br/></span>
                      )}
                    />
                  : null }
                  {data.options.contacts && data.options.contacts.filter((item) => item.classification._id === "web").length > 0 ?
                    <InfoRow
                      label={translations["sheet-company"]["Website"]}
                      value={data.options.contacts.filter((item) => item.classification._id === "web").map(val =>
                        <a href={val.value} key={`website-${val.value}`}>{val.value}<br/></a>
                      )}
                    />
                  : null }
                  {data.options.contacts && data.options.contacts.filter((item) => item.subtype._id === "phone;phone-tel" || item.subtype._id === "phone;phone-cell").length > 0 ?
                    <InfoRow
                      label={translations["sheet-company"]["Phone"]}
                      value={data.options.contacts.filter((item) => item.subtype._id === "phone;phone-tel" || item.subtype._id === "phone;phone-cell" ).map(val =>
                        <span key={`phones-${val.value}`}>{val.value}<br/></span>
                      )}
                    />
                  : null }
                  {data.options.contacts && data.options.contacts.filter((item) => item.subtype._id === "phone;phone-fax").length > 0 ?
                    <InfoRow
                      label={translations["sheet-company"]["Fax"]}
                      value={data.options.contacts.filter((item) => item.subtype._id === "phone;phone-fax").map(val =>
                        <span key={`faxes-${val.value}`}>{val.value}<br/></span>
                      )}
                    />
                  : null }
                  {data.options.contacts && data.options.contacts.filter((item) => item.classification._id === "social").length > 0 ?
                    <InfoRow label={translations["sheet-company"]["Social"]}>
                      {data.options.contacts && data.options.contacts.filter((item) => item.classification._id === "social").map((item, i) =>
                        <a rel="noopener noreferrer" key={i} href={item.value} target="_blank"><Icon color={"#606060"} name={(item.subtype._id.replace("social;",""))+"-1"} size={{value: 20, unit: "px"}} /> <SocialLink>{item.subtype[lang]}</SocialLink><br/></a>
                      )}
                    </InfoRow>
                  : ""}



                </Chunk>

                {(config.enable_login && !isPremium) && (
                  <PremiumCta>
                    <Button onClick={() => this.props.dispatch(showPlansModal())} size={"tiny"} variant={"default-o"}>{translations["UI"]["_upgrade-premium-button"]}</Button>
                    <p>{translations["UI"]["_upgrade-premium-text"]}</p>
                  </PremiumCta>
                )}

                <Spacer size={{value: 14, unit: "px"}}/>
                {data.options.people && data.options.people.length > 0?
                  <>
                    <Chunk title={translations["sheet-company"]["About us"]} open={false}>

                      <Row layout={layout}>
                        {["president","ceo"].map((role, i) =>
                          data.options.people.filter(item => item.classification._id === role).length > 0 ?
                            <Col size="50" key={i}>
                              <LabelledValue label={data.options.people.filter(item => item.classification._id === role)[0].classification[lang]} border={false}>
                                {data.options.people.filter(item => item.classification._id === role)[0].firstName + " " + data.options.people.filter(item => item.classification._id === role)[0].lastName}<br/><br/>
                                {data.options.people.filter(item => item.classification._id === role)[0].contacts.filter((item) => item.classification._id === "email").length > 0 ?
                                  <Button onClick={() => this.toggleModal({type: 'companycontact', email: data.options.people.filter(item => item.classification._id === role)[0].contacts.filter((item) => item.classification._id === "email")[0].value, name: data.options.people.filter(item => item.classification._id === role)[0].firstName + " " + data.options.people.filter(item => item.classification._id === role)[0].lastName})} size={"tiny"} variant={"default-o"}
                                          display={"inline-block"}>{translations["sheet-company"]["Contact directly"]}</Button>
                                  : ""}
                              </LabelledValue>
                            </Col>
                          : null
                        )}
                        {data.options.people.filter(item => item.classification._id !== "president" && item.classification._id !== "ceo").length > 0 ?
                          data.options.people.filter(item => item.classification._id !== "president" && item.classification._id !== "ceo").map((person, i) =>
                            <Col size="25" key={i}>
                              <LabelledValue label={person.classification[lang]} border={false}>
                                {person.firstName + " " + person.lastName}<br/><br/>
                                {person.contacts.filter((item) => item.classification._id === "email").length > 0 ?
                                  <Button onClick={() => this.toggleModal({type: 'companycontact', email: person.contacts.filter((item) => item.classification._id === "email")[0].value, name: person.firstName + " " + person.lastName})} size={"tiny"} variant={"default-o"}
                                          display={"inline-block"}>{translations["sheet-company"]["Contact directly"]}</Button>
                                  : ""}
                              </LabelledValue>
                            </Col>
                          )
                        : null }
                      </Row>
                    </Chunk>
                    <Spacer size={{value:14, unit: "px"}}/>
                  </>
                : null}

                { (!config.enable_login || isPremium) && (
                  (data.options.brandIdentities && data.options.brandIdentities.length > 0) ||
                  (data.options.ownerName) ||
                  (data.options.foundationYear) ||
                  (data.options.typology) ||
                  (data.options.brands && data.options.brands.length > 0) ||
                  (data.options.otherInfos && data.options.otherInfos.length > 0)
                ) ?
                  <>
                    <Chunk title={translations["sheet-company"]["Company info"]}>
                      {data.options.brandIdentities && data.options.brandIdentities.length > 0 ?
                        <InfoRow
                          label={translations["sheet-company"]["Identity"]}
                          value={data.options.brandIdentities.map((item) => item[lang]).join(", ")}
                        />
                      : ""}
                      {data.options.ownerName ?
                        <InfoRow
                          label={translations["sheet-company"]["Owner"]}
                          value={data.options.ownerName}
                        />
                      : ""}
                      {data.options.foundationYear ?
                        <InfoRow
                          label={translations["sheet-company"]["Foundation year"]}
                          value={data.options.foundationYear}
                        />
                      : ""}
                      {data.options.typology ?
                        <InfoRow
                          label={translations["sheet-company"]["Company typology"]}
                          value={data.options.typology[lang]}
                        />
                      : ""}
                      {data.options.brands && data.options.brands.length > 0 ?
                        <InfoRow
                          label={translations["sheet-company"]["Brands"]}
                          value={data.options.brands.join(", ")}
                        />
                      : ""}
                      {data.options.subsidiaryCompanyLocation && data.options.subsidiaryCompanyLocation.length > 0 ?
                        <InfoRow
                          label={translations["sheet-company"]["Subsidiary company"]}
                          value={data.options.subsidiaryCompanyLocation.map(val => val[lang]).join(", ")}
                        />
                      : ""}
                      {data.options.dealers !== null ?
                        <InfoRow
                          label={translations["sheet-company"]["Dealers"]}
                          value={data.options.dealers ? translations["UI"]["Yes"] : translations["UI"]["No"]}
                        />
                      : ""}
                      {data.options.certificates ?
                        <InfoRow
                          label={translations["sheet-company"]["Certificates"]}
                          value={data.options.certificates}
                        />
                      : ""}
                      {data.options.otherInfos && data.options.otherInfos.length > 0 ?
                        <InfoRow
                          label={translations["sheet-company"]["Other information"]}
                          value={data.options.otherInfos.map(val => val[lang]).join(", ")}
                        />
                      : ""}
                    </Chunk>
                    <Spacer size={{value: 14, unit: "px"}}/>
                  </>
                : null}

                { (!config.enable_login || isPremium) && (
                  (data.options.categories && data.options.categories.length > 0) ||
                  (data.options.productionAreas && data.options.productionAreas.length > 0) ||
                  (data.options.annualRevenue && data.options.annualRevenue[lang])
                )?
                  <>
                    <Chunk title={translations["sheet-company"]["Production"]}>
                    {data.options.categories && data.options.categories.length > 0 ?
                      <InfoRow
                        label={translations["sheet-company"]["Trade Sectors"]}
                        value={null}
                      >
                        <List>
                          <ul>
                            {data.options.categories.map((lvl0, index0) =>
                              <li key={index0}>
                                <ListHighlight>{lvl0.value[lang]}</ListHighlight>
                                {lvl0.children ?
                                  <ul>
                                    {lvl0.children.map((lvl1, index1) =>
                                      <li key={index1}>
                                        {lvl1.value[lang]}
                                        {lvl1.children ?
                                          <ul>
                                            {lvl1.children.map((lvl2, index2) =>
                                              <li key={index2}>
                                                {lvl2.value[lang]}
                                                {lvl2.children ?
                                                  <ul>
                                                    {lvl2.children.map((lvl3, index3) =>
                                                      <li key={index3}>
                                                        {lvl3.value[lang]}
                                                        {lvl3.children ?
                                                          <ul>
                                                            {lvl3.children.map((lvl4, index4) =>
                                                              <li key={index4}>{lvl4.value[lang]}</li>
                                                            )}
                                                          </ul>
                                                          : ""}
                                                      </li>
                                                    )}
                                                  </ul>
                                                  : ""}
                                              </li>
                                            )}
                                          </ul>
                                          : ""}
                                      </li>
                                    )}
                                  </ul>
                                  : ""}
                              </li>
                            )}
                          </ul>
                        </List>
                        {/*<Row>
                        <Col>The quick, brown fox jumps over a lazy dog DJs flock by when MTV ax quiz prog Junk MTV quiz graced by fox whelps Bawds Waltzbad nymph for quick jigs ve Fox nymphs grab quick-jived waltz Brick quiz whangs jumpy veldt fox Bright vixens fowl quack Quick zephyrs vex bold Jim</Col>
                        <Col>The quick, brown fox jumps over a lazy dog DJs flock by when MTV ax quiz prog Junk MTV quiz graced by fox whelps Bawds Waltzbad nymph for quick jigs ve Fox nymphs grab quick-jived waltz Brick quiz whangs jumpy veldt fox Bright vixens fowl quack Quick zephyrs vex bold Jim</Col>
                        <Col>The quick, brown fox jumps over a lazy dog DJs flock by when MTV ax quiz prog Junk MTV quiz graced by fox whelps Bawds Waltzbad nymph for quick jigs ve Fox nymphs grab quick-jived waltz Brick quiz whangs jumpy veldt fox Bright vixens fowl quack Quick zephyrs vex bold Jim</Col>
                      </Row>*/}
                      </InfoRow>
                    : ""}

                    {data.options.productionAreas && data.options.productionAreas.length > 0 ?
                      <InfoRow
                        label={translations["sheet-company"]["Production area"]}
                        value={null}
                      >
                        {/*<Text cols="3" layout={layout}>
                          {data.options.productionAreas.map(val =>
                            <span key={`production-area-${val.countryId[lang]+"-"+val.regionId[lang]}`}>{val.countryId[lang]}{val.regionId[lang] ? ": " + val.regionId[lang].join(", ") : ""}
                              <br/></span>
                          )}
                        </Text>*/}
                        <Text cols="3" layout={layout}>
                          {data.options.productionAreas.map(valCountry => valCountry.countryId[lang]).filter((item, pos, self) => self.indexOf(item) === pos).map(valCountry =>
                            <span key={`production-area-${valCountry}`}>{valCountry}{data.options.productionAreas.filter(valRegion => valRegion.countryId[lang] === valCountry).length > 0 ? ": " + data.options.productionAreas.filter(valRegion => valRegion.countryId[lang] === valCountry).map(valRegion => valRegion.regionId[lang]).join(", ") : null}
                            <br/></span>
                          )}
                        </Text>
                      </InfoRow>
                      : ""}
                      {data.options.annualRevenue && data.options.annualRevenue[lang] ?
                        <InfoRow
                          label={translations["sheet-company"]["Revenue"]}
                          value={data.options.annualRevenue[lang]}
                        />
                      : ""}
                    </Chunk>
                    <Spacer size={{value: 14, unit: "px"}}/>
                  </>
                : null}

                {(!config.enable_login || isPremium) && config.areas["products"].enable && data.products && data.products.length > 0 ?
                  <Grid columns={layout === LAYOUT_XS || layout === LAYOUT_SM ? 1 : 3}>
                    {data.products.map((item, index) =>(
                      <GridElement key={index}>
                        <ProductThumb
                          key={index}
                          item={item}
                        />
                      </GridElement>
                    ))}
                  </Grid>
                : null}

                { (!config.enable_login || isPremium) && (
                  (data.options.mainOperation) ||
                  (data.options.distributionChannelsItaly && data.options.distributionChannelsItaly.length > 0) ||
                  (data.options.exportPercentage) ||
                  (data.options.exportTo && data.options.exportTo.length > 0) ||
                  (data.options.desiredExportTo && data.options.desiredExportTo.length > 0) ||


                  (data.channelsItaly && data.channelsItaly.length > 0)
                ) ?
                  <>
                    <Chunk title={translations["sheet-company"]["Distribution"]}>
                      {data.options.mainOperation ?
                        <InfoRow
                          label={translations["sheet-company"]["Prevailing operations"]}
                          value={data.options.mainOperation[lang]}
                        />
                        : ""}
                      {/*data.options.distributionChannelsItaly && data.options.distributionChannelsItaly.length > 0 ?
                        <InfoRow
                          label={translations["sheet-company"]["Distribution channels in Italy"]}
                          value={data.options.distributionChannelsItaly.map(val => val[lang]).join(", ")}
                        />
                        : ""*/}
                      {data.options.exportPercentage ?
                        <InfoRow
                          label={translations["sheet-company"]["Export percent"]}
                          value={data.options.exportPercentage + "%"}
                        />
                        : ""}
                      {data.options.exportTo && data.options.exportTo.length > 0 ?
                        <InfoRow
                          label={translations["sheet-company"]["Countries to which we export"]}
                          value={null}
                        >
                          <Text cols="3" layout={layout}>
                            {data.options.exportTo.map(val =>
                              <span key={`export-to-${val.countryId[lang]}`}>{val.countryId[lang]}{val.distributionChannelIds && val.distributionChannelIds.length > 0 ? ": " + val.distributionChannelIds.map(val => val[lang]).join(", ") : ""}
                                <br/></span>
                            )}
                          </Text>
                        </InfoRow>
                        : ""}
                      {data.options.desiredExportTo && data.options.desiredExportTo.length > 0 ?
                        <InfoRow
                          label={translations["sheet-company"]["Countries where we would like to export"]}
                          value={null}
                        >
                          <Text cols="3" layout={layout}>
                            {data.options.desiredExportTo.map(val =>
                              <span key={`desired-export-to-${val[lang]}`}>{val[lang]}
                                <br/></span>
                            )}
                          </Text>
                        </InfoRow>
                        : ""}
                    </Chunk>
                    <Spacer size={{value: 14, unit: "px"}}/>
                  </>
                : null}

                {(!config.enable_login || isPremium) && config.areas["casehistories"].enable && data.casehistories && data.casehistories.length > 0 ?

                  data.casehistories.map((item, index) =>(
                    <React.Fragment key={index}>
                      <Chunk collapsible={false}>
                        <CasehistoryThumb
                          key={index}
                          item={item}
                        />
                      </Chunk>
                      <Spacer size={{value: 14, unit: "px"}}/>
                    </React.Fragment>
                  ))
                : null}

                {(!config.enable_login || isPremium) && data.people && data.people.length > 0 ?
                  <>
                    <Chunk title={translations["sheet-company"]["People"]} variant="event">
                      {data.people.map((val, index) =>
                        <PeopleValue key={"people-"+index} layout={layout}>
                          <PeopleValueCell layout={layout}>
                            <Proportional ratio="1">
                              {val.options.mediaId && val.options.mediaId.src ?
                                <PersonAvatar src={val.options.mediaId.src}/>
                              :
                                <PersonAvatarPlaceholder />
                              }
                            </Proportional>
                          </PeopleValueCell>
                          <PeopleValueCell layout={layout}>
                            <PersonName>{val.options.name} {val.options.surname}</PersonName>
                            <PersonRole>{val.options.jobTitle[lang]}</PersonRole>
                          </PeopleValueCell>
                          {/* <PeopleValueCell layout={layout}>
                            <PersonDescription>{val.description[lang]}</PersonDescription>
                          </PeopleValueCell> */}
                          {/*<PeopleValueCell layout={layout}>Linkedin</PeopleValueCell>*/}
                        </PeopleValue>
                      )}
                    </Chunk>
                    <Spacer size={{value: 25, unit: "px"}} />
                  </>
                : "" }

                {(!config.enable_login || isPremium) && data.options && ((lang === "it" && data.options.descriptionSheetItalian) || (lang === "en" && data.options.descriptionSheetEnglish)) ?
                  <>
                    <Chunk title={translations["sheet-event"]["Attachments"]}>
                      {lang === "it" && data.options.descriptionSheetItalian ?
                        <AttachmentsValue layout={layout}>
                          <AttachmentsValueCell layout={layout}>
                            <AttachmentName>{data.options.descriptionSheetItalian.options.lTitle[lang]}</AttachmentName>
                          </AttachmentsValueCell>
                          <AttachmentsValueCell layout={layout}>
                            <a rel="noopener noreferrer" href={data.options.descriptionSheetItalian.src} target="_blank">
                              <Button display="inline-block" variant={"default-o"}>{translations["UI"]["Download"]}</Button>
                            </a>
                          </AttachmentsValueCell>
                        </AttachmentsValue>
                      : null}
                      {lang === "en" && data.options.descriptionSheetEnglish ?
                        <AttachmentsValue layout={layout}>
                          <AttachmentsValueCell layout={layout}>
                            <AttachmentName>{data.options.descriptionSheetEnglish.options.lTitle[lang]}</AttachmentName>
                          </AttachmentsValueCell>
                          <AttachmentsValueCell layout={layout}>
                            <a rel="noopener noreferrer" href={data.options.descriptionSheetEnglish.src} target="_blank">
                              <Button display="inline-block" variant={"default-o"}>{translations["UI"]["Download"]}</Button>
                            </a>
                          </AttachmentsValueCell>
                        </AttachmentsValue>
                      : null}
                    </Chunk>
                    <Spacer size={{value: 25, unit: "px"}} />
                  </>
                : null }




              </MacroCol>
              <MacroCol size="34" layout={layout}>
                {this.state.banners ?
                  <>
                    <Slider padding={{value: 0, unit: "px"}} size={1} showDots={false} showArrows={false} timing={10000} items={this.state.banners.sort(() => 0.5-Math.random()).map(item =>
                      <React.Fragment key={item.titolo_it}>
                        <Post
                          variant={"advertising"}
                          abstract={ReactHtmlParser(item["abstract_"+(lang === "it" ? lang : "en")])}
                          content={item["testo_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(item["testo_"+(lang === "it" ? lang : "en")]) : null}
                          ctaText={item["cta_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(item["cta_"+(lang === "it" ? lang : "en")]) : null}
                          ctaLink={item["link_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(item["link_"+(lang === "it" ? lang : "en")]) : null}
                          image={item.immagine}
                        />
                        <Spacer size={{value: 34, unit: "px"}} />
                      </React.Fragment>
                    )} />
                  </>
                : null }
                {/*{ Math.random() < 0.5 ?
                  <Post
                    variant={"advertising"}
                    abstract={"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut lor intema."}
                    image={banner1Image}
                    ctaText={"Scopri di più"}
                    ctaLink={"#"}
                  />
                :
                  <Post
                    variant={"advertising"}
                    abstract={"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut lor intema."}
                    image={banner2Image}
                    ctaText={"Scopri di più"}
                    ctaLink={"#"}
                  />
                }*/}

                { (data.servicesData && data.servicesData.location && data.servicesData.location.hallAndBooth && data.servicesData.location.hallAndBooth.length > 0) && config.enable_halls ?
                  <>
                    <FindUs>
                      <HallImageCnt>
                        <Image src={theme.halls["void"]} alt=""/>
                        {data.servicesData.location.hallAndBooth.map((item, index) =>
                          <HallImage key={"hallimage-"+index}>
                            <Image src={theme.halls[item.hall]} alt=""/>
                          </HallImage>
                        )}
                      </HallImageCnt>
                      <ReadMore
                        visibleContent={data.servicesData.location.hallAndBooth.slice(0, 3).map((item, index) =>
                          <Position key={`hall-${index}`}>{item.hallName[lang] + (item.booth ? " " + translations["UI"]["Stand"] + " " + item.booth : "")}</Position>
                        )}
                        hiddenContent={data.servicesData.location.hallAndBooth.slice(3).length ? data.servicesData.location.hallAndBooth.slice(3).map((item, index) =>
                          <Position key={`hall-${index}`}>{item.hallName[lang] + (item.booth ? " " + translations["UI"]["Stand"] + " " + item.booth : "")}</Position>
                        ): null}
                        buttonMore={<Button size={"tiny"} variant={"default-o"}
                                            display={"inline-block"}>{translations["UI"]["Show more"]}</Button>}
                        buttonLess={<Button size={"tiny"} variant={"default-o"}
                                            display={"inline-block"}>{translations["UI"]["Show less"]}</Button>}
                      />
                    </FindUs>

                    <Spacer size={{value: 14, unit: "px"}}/>
                  </>
                : null }



                {config.areas["subcasehistories"].enable && data.subcasehistories.length > 0 ?
                  <>
                    <Chunk title={translations["sheet-company"]["Subcasehistories"]} collapsible={false}>
                      <ReadMore
                        visibleContent={data.subcasehistories.slice(0,2).map((item, index) =>(
                          <Result
                            key={index}
                            area={"subcasehistories"}
                            slug={item.slug}
                            item={item}
                            url={"/"+lang+"/subcasehistories/"+item.id}
                          />
                        ))}
                        hiddenContent={data.subcasehistories.slice(2).length ? data.subcasehistories.slice(2).map((item, index) =>(
                          <Result
                            key={index}
                            area={"subcasehistories"}
                            slug={item.slug}
                            item={item}
                            url={"/"+lang+"/subcasehistories/"+item.id}
                          />
                        )) : null}
                        buttonMore={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show more"]}</Button>}
                        buttonLess={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show less"]}</Button>}
                      />

                    </Chunk>

                    <Spacer size={{value: 14, unit: "px"}}/>
                  </>
                : null}
              </MacroCol>
            </MacroRow>



            </Container>
          </MainContainer>
        </Wrapper>
        {data.options.contacts && data.options.contacts.filter((item) => item.classification._id === "email").length && (
          <Modal show={showModal} onClose={e => this.toggleModal(false)}>
            <ContactsModal>
              <ContactsModalHeader>
                {/*data.options.logo ?
                  <img src={data.options.logo.src} />
                : null */}
                <p>{modalText}</p>
              </ContactsModalHeader>
              <ContactForm lang={lang} config={config} translations={translations} company={data.options.name} reset={!showModal} toEmail={showModal ? data.options.contacts.filter((item) => item.classification._id === "email").map((item) => item.value) : null} toName={showModal ? showModal.name : null} type={showModal ? showModal.type : null} />
            </ContactsModal>
          </Modal>
        )}
      </>
    ) : <></>;
  }

}

export default compose(
  withTheme,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CompanySheet);
