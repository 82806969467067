import React, {useState } from 'react';
// import { Link, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import styled from "styled-components";
import { useDispatch, useSelector} from "react-redux";
import { getLayout, getConfig } from "./../../../store/settings/selectors";
import { getLocale, getTranslations } from "./../../../store/localization/selectors";
import {getPermissions, getUser} from "./../../../store/auth/selectors";
import {showPlansModal, toggleFilters} from "./../../../store/settings/actions";

import {LAYOUT_XS, LAYOUT_SM, LAYOUT_MD} from './../../../settings';
//
import NewsService from './../../../services/NewsService';
import RecommendedService from './../../../services/RecommendedService';

import Container from './../../../components/App/Container';
import Spacer from './../../../components/App/Spacer';
// import Post from './../../../components/App/Post';
// import Image from './../../../components/App/Image';
// import CompactMenuIcon from './../../../components/App/CompactMenuIcon';
import Slider from './../../../components/App/Slider';
// import Button from "./../../../components/App/Button";
import HomeProduct from './HomeProduct';
import HomeAdv from './HomeAdv';

// import FindUs from './../../../components/Common/FindUs';
// import Sidebar from './Sidebar';
import DataService from "../../../services/DataService";
// import { isArray } from 'lodash';
// import {zipWith} from "rxjs/operators";
import {PERMISSION_PRODUCT} from "../../../store/auth/permissions";
// import {login} from "../../../store/auth/actions";

import Proportional from "./../../../components/App/Proportional";

const Wrapper = styled.div`
  height: calc(100vh - 50px);
  height: calc(calc(var(--vh, 1vh) * 100) - 50px);
  overflow: scroll;

  background-color: #ffffff;
`;

const Title = styled.div`
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: #7B3572;
  margin-top: 20px;
  margin-bottom: 10px;
`;
const Background = styled.div`
  background-color: ${props => props.color};
  width: 100%;
`;





const HeroContainer = styled.div`
  height: 100%;
  width: 100%;
  background: url(${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? props.bgMobi : props.bgDesk});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  & > * {
    height: 100%;
  }
`;
const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "100%" : "50%"};
  height: 100%;
  padding: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "50px 20px" : "50px"};
`;
const HeroTitle = styled.div`
  font-size: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "30px" : "50px"};
  line-height: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "35px" : "55px"};
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 50px;
  font-family: "Lato";
`;
const HeroText = styled.div`
  font-size: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "27px" : "35px"};
  line-height: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "32px" : "40px"};
  font-weight: 300;
  color: #ffffff;
  font-family: "Lato";
`;

const Adv = styled.div`
  background-color: #F9F1F8;
  border-radius: 5px;
  height: 100%;
  padding: 5px;
`;
/* ^^^ */


// const MainContainer = styled.div`
//   margin-top: 3px;
//   position: relative;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: center;
//   /* min-height: 100%; */
//   flex-grow: 1;
//   flex-shrink: 1;
//   overflow: auto;
// `;
const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  margin-left: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
`;
const MacroCol = styled.div`
  padding: 0px ${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;

  ${props => props.autoHeight ? `

    & > * {
      height: 100%;
      display: flex;
    }
  ` : `

  `};
`;


// const Notification = styled.div`
//   text-align: center;
//   font-size: 10px;
//   font-weight: 600;
//   color: #A3A3A3;
//   margin-top:20px;
// `;




// const Hero = styled.div`
//   color: #000000;
//   border-radius: 12px;
//   padding: 20px;
// `;
// const HeroImage = styled.div`
//   border-radius: 12px;
//   overflow: hidden;

//   & img{
//     margin-bottom: -4px;
//   }
// `;
// const HeroTitle = styled.div`
//   font-weight: 700;
// /*  margin-top: 10px;*/
//   margin-bottom: 10px;
//   font-size: 24px;
//   line-height: 32px;
// `;
// const HeroText = styled.div`
//   font-size: 14px;
//   line-height: 18px;
// `;

const Block = styled.div`
  border-radius: 12px;
  padding: 20px;
`;
// const BlockTitle = styled.div`
//   color: #E5087E;
//   font-weight: 700;
//   margin-bottom: 5px;
//   font-size: 18px;
//   margin-top: 10px;
//   padding-left: 10px;
//   height: 18px;
// `;
// const HeroCta = styled.div`
//   text-align: left;
// `;

// const AdvBlock = styled.div`
//   // margin: 0px 0px;
//   // padding: 0px 5px 5px;
//   // border: 2px solid #e4018e;
//   // border-radius: 5px;
// `;

// const Adv = styled.div`
//   color: #818181;
//   font-weight: 500;
//   margin-bottom: 15px;
//   font-size: 12px;
//   margin-top: 8px;
//   height: 18px;
// `;

export const CompactMenuIconWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
   width: 46px;
   height: 46px;
   flex-grow: 0;
   flex-shrink: 0;
   margin-left: 7px;
   z-index: 9999;
`;

const Notify = styled.div`
  text-align: center;
  padding: 12px 0px 14px;
  font-size: 15px;
  background-color: #F9F1F8;
  color: #7B3572;
  font-weight: 700;
`;

export default function Home(){

  const dispatch = useDispatch();
  dispatch(toggleFilters(false));

  const layout = useSelector(getLayout);
  // const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);
  const config = useSelector(getConfig);
  const user = useSelector(getUser);
  const permissions = useSelector(getPermissions);
  const translations = useSelector(getTranslations);
  
  const [loaded, setLoaded] = useState(false);
  const [productsHGL, setProductsHGL] = useState(null);
  const [productsHGLAdv, setProductsHGLAdv] = useState(null);
  const [productsHGLLabel, setProductsHGLLabel] = useState(null);
  const [productsTOP, setProductsTOP] = useState(null);
  const [productsTOPAdv, setProductsTOPAdv] = useState(null);
  const [productsTOPLabel, setProductsTOPLabel] = useState(null);
  const [topHero, setTopHero] = useState(null);
  const [middleHero, setMiddleHero] = useState(null);
  const [tips, setTips] = useState(null);

  function shuffleArray(arr) {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  }

  async function convertProductIdToObj(arr) {
    return await DataService.getList({area: 'products', ids: arr.map(item => item), limit: arr.length});
  }

  async function loadData() {
    setLoaded(true);
    // try {
      // Prodotti
      const products = await RecommendedService.getList(user ? user.id : null);
      // Contenuti
      const data = await NewsService.getList();
      
      // HGL
      if(products && products.filter(val => val.code === "HGL").length > 0){
        let res = await convertProductIdToObj(products.filter(val => val.code === "HGL")[0].items.map(item => item.item));
        setProductsHGL(res);
        setProductsHGLLabel(products.filter(val => val.code === "HGL")[0].label);
        //adv
        console.log(products.filter(val => val.code === "HGL")[0].adv);
        let res2 = await convertProductIdToObj(products.filter(val => val.code === "HGL")[0].adv);
        setProductsHGLAdv(res2);
        console.log(res2);
      }
      
      // TOP
      if(products && products.filter(val => val.code === "TOP").length > 0){
        let res = await convertProductIdToObj(products.filter(val => val.code === "TOP")[0].items.map(item => item.item));
        setProductsTOP(res);
        setProductsTOPLabel(products.filter(val => val.code === "TOP")[0].label);
        //adv
        let res2 = await convertProductIdToObj(products.filter(val => val.code === "TOP")[0].adv);
        setProductsTOPAdv(res2);
      }

      // TOP HERO
      if(data && data['Top Banner']){
        await setTopHero(data['Top Banner']);
      }
      // ADV
      if(data && data['Frame DX']){
        Object.keys(data['Frame DX']).map(key => {
          data['Frame DX'][key] = shuffleArray(data['Frame DX'][key]);
          return 0;
        });      
        await setTips(data['Frame DX']);
      }

      // MIDDLE HERO
      if(data && data['Middle Banner']){
        await setMiddleHero(data['Middle Banner']);
      }

    // } catch (e) {}

  }

  if(!loaded){
    loadData();
  }

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  function productClickHandler(e) {
    if (!config.enable_login || permissions.indexOf(PERMISSION_PRODUCT) === -1) {
      e.preventDefault();
      dispatch(showPlansModal())
    }
  }

  // const blockLang = lang === "it" ? "it" : "en";

  return (
    <Wrapper>
      {translations["UI"]["_homepage-notify"] ? 
        <Notify>{translations["UI"]["_homepage-notify"]}</Notify>
      : null }
      {/* Top Hero */}
      {topHero ? 
        <Proportional ratio={layout === LAYOUT_XS || layout === LAYOUT_SM ? 0.75 : 2.22}>
          <HeroContainer layout={layout} bgDesk={topHero["background"]} bgMobi={topHero["background_mobile"]}>
            <Container>
              <HeroContent layout={layout}>
                <HeroTitle layout={layout}> 
                  {ReactHtmlParser(topHero["titolo_"+lang])}
                </HeroTitle> 
                <HeroText layout={layout}> 
                  {ReactHtmlParser(topHero["abstract_"+lang])}
                </HeroText> 
              </HeroContent>
            </Container>  
          </HeroContainer>
        </Proportional>
      : null }

      {/* HGL */}
      {productsHGL && productsHGL.length > 0 ? 
        <Container>   
          <Block>   
            <Title>{productsHGLLabel}</Title>                   
            <MacroRow layout={layout}>
              <MacroCol size="66" layout={layout}>
                <Spacer size={{value: 5, unit: "px"}} />
                <Slider 
                  showDots={false} 
                  showArrows={false} 
                  timing={5000} 
                  size={layout === LAYOUT_XS || layout === LAYOUT_SM ? 1 : 4} 
                  padding={{
                    value: 10, 
                    unit: "px"
                  }}
                  items={productsHGL.slice(0, Math.ceil(productsHGL.length / 2)).map(item=> 
                    <HomeProduct onClick={productClickHandler} id={item.id} key={item.id} item={item} /> 
                  )}
                />   
                <Slider 
                  showDots={false} 
                  showArrows={false} 
                  timing={5000} 
                  size={layout === LAYOUT_XS || layout === LAYOUT_SM ? 1 : 4} 
                  padding={{
                    value: 10, 
                    unit: "px"
                  }}
                  items={productsHGL.slice(Math.ceil(productsHGL.length / 2), productsHGL.length).map(item=> 
                    <HomeProduct onClick={productClickHandler} id={item.id} key={item.id} item={item} /> 
                  )}
                />               
              </MacroCol>   
              <MacroCol size="34" layout={layout}>
                {productsHGLAdv && productsHGLAdv.length > 0 ? 
                  <Adv>
                    <Slider 
                      showDots={false} 
                      showArrows={false} 
                      timing={5000} 
                      size={1} 
                      padding={{
                        value: 10, 
                        unit: "px"
                      }}
                      items={productsHGLAdv.map(item=> 
                        <HomeProduct size="big" onClick={productClickHandler} id={item.id} key={item.id} item={item} /> 
                      )}
                    />  
                  </Adv>
                : null }
              </MacroCol>           
            </MacroRow>                        
          </Block>
        </Container>
      : null }

      {/* ADV */}
      {tips && tips.length > 0 ? 
        <Background color={"#F5F5F5"}>
          <Container>   
            <Block>   
              <Title>Highlighted</Title>                   
              <MacroRow layout={layout}>
              <Spacer size={{value: 5, unit: "px"}} />
                {tips.map(item =>
                  <MacroCol size="33" layout={layout}>
                    <HomeAdv
                      image={item["immagine"] ? item["immagine"] : null}
                      abstract={item["abstract_"+lang] ? item["abstract_"+lang] : null}
                      content={item["abstract_"+lang] ? item["abstract_"+lang] : null}
                      ctaText={item["testo_"+lang] ? item["testo_"+lang] : null}
                      ctaLink={item["link_"+lang] ? item["link_"+lang] : null}
                    /> 
                  </MacroCol>
                )}              
              </MacroRow>                        
            </Block>
          </Container>
        </Background>
      : null }

      {/* MiddleHero */}
      {middleHero ? 
        <Proportional ratio={layout === LAYOUT_XS || layout === LAYOUT_SM ? 0.75 : 2.72}>
          <HeroContainer layout={layout} bgDesk={middleHero["background"]} bgMobi={middleHero["background_mobile"]}>
            <Container>
              <HeroContent layout={layout}>
                <HeroTitle layout={layout}> 
                  {ReactHtmlParser(middleHero["titolo_"+lang])}
                </HeroTitle> 
                <HeroText layout={layout}> 
                  {ReactHtmlParser(middleHero["abstract_"+lang])}
                </HeroText> 
              </HeroContent>
            </Container>  
          </HeroContainer>
        </Proportional>
      : null }

      {/* TOP */}
      {productsTOP && productsTOP.length > 0 ? 
        <Container>   
          <Block>   
            <Title>{productsTOPLabel}</Title>                   
            <MacroRow layout={layout}>
              {productsTOPAdv && productsTOPAdv.length > 0 ? 
                <MacroCol size="17" layout={layout}>
                  <Spacer size={{value: 5, unit: "px"}} />
                  <Slider 
                    showDots={false} 
                    showArrows={false} 
                    timing={5000} 
                    size={1} 
                    padding={{
                      value: 10, 
                      unit: "px"
                    }}
                    items={productsTOPAdv.map(item=> 
                      <Adv>
                        <HomeProduct onClick={productClickHandler} id={item.id} key={item.id} item={item} /> 
                      </Adv>
                    )}
                  />
                </MacroCol>   
              : null }
              <MacroCol size="83" layout={layout}>
                <Spacer size={{value: 5, unit: "px"}} />
                <Slider 
                  showDots={false} 
                  showArrows={false} 
                  timing={5000} 
                  size={layout === LAYOUT_XS || layout === LAYOUT_SM ? 1 : 5} 
                  padding={{
                    value: 10, 
                    unit: "px"
                  }}
                  items={productsTOP.map(item=> 
                    <HomeProduct onClick={productClickHandler} id={item.id} key={item.id} item={item} /> 
                  )}
                />
              </MacroCol>              
            </MacroRow>                        
          </Block>
        </Container>
      : null }

        
      
    </Wrapper>

  );

}
