import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { withTheme } from './../../themes';
import { getLayout } from './../../store/settings/selectors';
import {getTranslations} from "./../../store/localization/selectors";
import * as moment from 'moment';

// import {LAYOUT_XS, LAYOUT_SM} from './../../settings';

import Icon from "./../App/Icon";
import Image from "./../App/Image";
import Button from "./../App/Button";
import ReadMore from "./../App/ReadMore";

const Wrapper  = styled.div`
  width: 100%;
  height: ${props => props.fullHeight ? "100%" : "auto"};
  border: ${props => props.borderSize + "px " + props.borderStyle + " " + props.borderColor};
  border-radius: ${props => props.borderRadius.value+props.borderRadius.unit};
  box-shadow: ${props => props.boxShadow};
  background: ${props => props.background};

  overflow: hidden;

  display: flex;
  flex-direction: column;
`;
const Figure  = styled.div`
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" 0px "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
`;
const Content  = styled.div`
  width: 100%;
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};


  display: flex;
  flex-direction: column;
`;
const Tags  = styled.div`
  color: ${props => props.textColor};
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  margin-bottom: 5px;
`;
const Title  = styled.div`
  color: ${props => props.textColor};
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  margin-bottom: 8px;
`;
const Text  = styled.div`
  color: ${props => props.textColor};
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  text-transform: ${props => props.textTransform};
  /*& *{
    color: ${props => props.textColor};
    font-family: ${props => props.textFont};
    font-size: ${props => props.textSize}px;
    font-weight: ${props => props.textWeight};
    line-height: ${props => props.textLineHeight}px;
    letter-spacing: ${props => props.textLetterSpacing}px;
    text-transform: ${props => props.textTransform};
    text-transform: ${props => props.textTransform};
  }*/
`;
const Highlighted = styled.div`
  color: ${props => props.textColor};
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  display: flex;
  align-items: center;
`;
const Recommended = styled.div`
  color: ${props => props.textColor};
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  display: flex;
  align-items: center;
`;
const Footer = styled.div`
  color: ${props => props.textColor};
  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  display: flex;
  align-items: center;
  border-top: ${props => props.borderSize + "px " + props.borderStyle + " " + props.borderColor};
`;
const Cta = styled.div`
  text-align: ${props => props.alignment};
`;

const Post = ({
  variant = "default",
  title,
  abstract,
  content,
  ctaText = false,
  ctaLink = false,
  ctaLinkInternal = false,
  tags = [],
  timestamp,
  image,
  isHighlighted = false,
  isRecommended = false,
  theme,
  imageHeight = null,
  imageFit = null,
  imagePadding = false
}) => {
  const layout = useSelector(getLayout);
  const translations = useSelector(getTranslations);
// console.log(tags);


  return (
    <Wrapper
      borderStyle={theme.post.borderStyle}
      borderSize={theme.post.borderSize}
      borderColor={theme.post.variant[variant].borderColor}
      borderRadius={theme.post.borderRadius}
      boxShadow={theme.post.variant[variant].boxShadow}
      background={theme.post.variant[variant].background}
    >
      {isHighlighted ?
        <Highlighted
          padding={theme.post.padding[layout]}
          textFont={theme.post.highlighted.textFont}
          textSize={theme.post.highlighted.textSize}
          textWeight={theme.post.highlighted.textWeight}
          textLineHeight={theme.post.highlighted.textLineHeight}
          textLetterSpacing={theme.post.highlighted.textLetterSpacing}
          textTransform={theme.post.highlighted.textTransform}
          textColor={theme.post.variant[variant].highlighted.textColor}
        >
          <Icon color={theme.post.variant[variant].highlighted.textColor} name={theme.icons.set.highlightedPost} size={{value: 20, unit: "px"}} />&nbsp;{translations["UI"]["Highlighted"]}
        </Highlighted>
      : null }
      {isRecommended ?
        <Recommended
          padding={theme.post.padding[layout]}
          textFont={theme.post.recommended.textFont}
          textSize={theme.post.recommended.textSize}
          textWeight={theme.post.recommended.textWeight}
          textLineHeight={theme.post.recommended.textLineHeight}
          textLetterSpacing={theme.post.recommended.textLetterSpacing}
          textTransform={theme.post.recommended.textTransform}
          textColor={theme.post.variant[variant].recommended.textColor}
        >
          <Icon color={theme.post.variant[variant].recommended.textColor} name={theme.icons.set.recommendedPost} size={{value: 20, unit: "px"}} />&nbsp;{translations["UI"]["Recommended"]}
        </Recommended>
      : null }
      {image ?
        <Figure
          padding={imagePadding ? theme.post.padding[layout] : null}
        >
          <Image
            height={imageHeight}
            fit={imageFit}
            src={image}
          />
        </Figure>
      : null }
      <Content
        padding={theme.post.padding[layout]}
      >
        <Tags
          textFont={theme.post.tags.textFont}
          textSize={theme.post.tags.textSize}
          textWeight={theme.post.tags.textWeight}
          textLineHeight={theme.post.tags.textLineHeight}
          textLetterSpacing={theme.post.tags.textLetterSpacing}
          textTransform={theme.post.tags.textTransform}
          textColor={theme.post.variant[variant].tags.textColor}
        >
          {tags.length ? tags.map(val =>
            <span key={val}>{val}</span>
          ) : null}
        </Tags>
        {title ?
          <Title
            textFont={theme.post.title.textFont}
            textSize={theme.post.title.textSize}
            textWeight={theme.post.title.textWeight}
            textLineHeight={theme.post.title.textLineHeight}
            textLetterSpacing={theme.post.title.textLetterSpacing}
            textTransform={theme.post.title.textTransform}
            textColor={theme.post.variant[variant].title.textColor}
          >
            {title}
          </Title>
        : null }
        <Text
          textFont={theme.post.content.textFont}
          textSize={theme.post.content.textSize}
          textWeight={theme.post.content.textWeight}
          textLineHeight={theme.post.content.textLineHeight}
          textLetterSpacing={theme.post.content.textLetterSpacing}
          textTransform={theme.post.content.textTransform}
          textColor={theme.post.variant[variant].content.textColor}
        >
          <ReadMore
            visibleContent={
              <>
                {abstract}
                {ctaText && ctaLink ?
                  <Cta alignment={theme.post.cta.alignment}>
                    <br/>
                    <a rel="noopener noreferrer" href={ctaLink} target="_blank">
                      <Button size={theme.post.variant[variant].buttonSize} variant={theme.post.variant[variant].buttonVariant} display={"inline-block"}>{ctaText}</Button>
                    </a>
                  </Cta>
                : null}
                {ctaText && ctaLinkInternal ?
                  <Cta alignment={theme.post.cta.alignment}>
                    <br/>
                    <Link to={ctaLinkInternal}>
                      <Button size={theme.post.variant[variant].buttonSize} variant={theme.post.variant[variant].buttonVariant} display={"inline-block"}>{ctaText}</Button>
                    </Link>
                  </Cta>
                : null}
              </>
            }
            hiddenContent={content}
            buttonMore={<Cta alignment={theme.post.cta.alignment}><Button size={theme.post.variant[variant].buttonSize} variant={theme.post.variant[variant].buttonVariant} display={"inline-block"}>{translations["UI"]["Show more"]}</Button></Cta>}
            buttonLess={<Cta alignment={theme.post.cta.alignment}><Button size={theme.post.variant[variant].buttonSize} variant={theme.post.variant[variant].buttonVariant} display={"inline-block"}>{translations["UI"]["Show less"]}</Button></Cta>}
          />
        </Text>
      </Content>
      {timestamp ?
        <Footer
          borderStyle={theme.post.footer.borderStyle}
          borderSize={theme.post.footer.borderSize}
          borderColor={theme.post.variant[variant].footer.borderColor}
          padding={theme.post.padding[layout]}
          textFont={theme.post.footer.textFont}
          textSize={theme.post.footer.textSize}
          textWeight={theme.post.footer.textWeight}
          textLineHeight={theme.post.footer.textLineHeight}
          textLetterSpacing={theme.post.footer.textLetterSpacing}
          textTransform={theme.post.footer.textTransform}
          textColor={theme.post.variant[variant].footer.textColor}
        >
          <Icon color={theme.post.variant[variant].footer.textColor} name={theme.icons.set.timestampPost} size={{value: 18, unit: "px"}} />&nbsp;{(moment(timestamp * 1000)).format('D MMMM YYYY')}{/* | {(moment(timestamp * 1000)).format('HH:mm')}*/}
        </Footer>
      : null }
    </Wrapper>
  )
}
export default withTheme(Post)
