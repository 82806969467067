import axios from "axios";
import {DOMAINS} from "./../domains";
import {getQueryStringFromObject, parseItemGallery} from "../helpers";
import isArray from "redux-actions/lib/utils/isArray";
// import OzwolImageService from "./OzwolImageService";
import {RECORDS_PER_PAGE} from "../settings";

export class DataService {

  constructor() {

    this.getDomainConfig = this.getDomainConfig.bind(this);
    this.getApiUrl = this.getApiUrl.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.getList = this.getList.bind(this);
    this.getCount = this.getCount.bind(this);
    this.getItemById = this.getItemById.bind(this);
    this.getFiltersStructure = this.getFiltersStructure.bind(this);
    this.getFiltersValues = this.getFiltersValues.bind(this);
    this.getFacets = this.getFacets.bind(this);

  }

  language = 'it';

  recordsPerPage = 20;

  getDomainConfig() {
    return DOMAINS[window.location.hostname];
  }

  getApiUrl() {

    const domainConfig = this.getDomainConfig().chatConfig;
    let apiUrl = domainConfig.default.apiUrl;
    if (domainConfig.hasOwnProperty(window.location.hostname))
      apiUrl = domainConfig[window.location.hostname].apiUrl;
    return apiUrl;

  }

  setLanguage(language) {
    this.language = language;
    axios.defaults.headers.common['Accept-Language'] = this.language;
  }

  async getList({area, subarea = null, page = 0, search = null, filters = {}, ids = [], limit = null}) {
    if(area === "events" && this.language !== "it"){
      axios.defaults.headers.common['Accept-Language'] = "en";
    }else{
      axios.defaults.headers.common['Accept-Language'] = this.language;
    }
    const domainConfig = this.getDomainConfig();
    const apiUrl = this.getApiUrl();

    return new Promise(async (resolve, reject) => {

      if (apiUrl) {
        let isAreaEnabled = domainConfig.areas[area].enable;
        if (isAreaEnabled) {
          if (subarea) {
            if (!(Object.keys(domainConfig.areas[area].subareas)).includes(subarea)) {
              subarea = Object.keys(domainConfig.areas[area].subareas).filter(key => domainConfig.areas[area].subareas[key].key === subarea)[0];
            }
          } else {
            subarea = Object.keys(domainConfig.areas[area].subareas)[0];
          }

          let isSubareaEnabled = subarea ? domainConfig.areas[area].subareas[subarea].enable : false;


          if (isSubareaEnabled) {

            let areaEndpoint = domainConfig.areas[area].dataServiceKey;
            let subareaType = domainConfig.areas[area].subareas[subarea].dataServiceKey;
            let subareaKey = domainConfig.areas[area].subareas[subarea].key;

            Object.keys(filters).filter(key => key.indexOf('_lv') !== -1).map(key => {
              filters = {...filters, [key.split('_lv')[0]]: filters[key]};
              return 0;
            });

            let parameters = Object.keys(filters).reduce((acc, key) => {
              const paramValue = isArray(filters[key]) ? filters[key].join(',') : filters[key]
              return {
                ...acc,
                [key]: paramValue
              }
            }, {
              _limit: RECORDS_PER_PAGE,
              _page: page + 1,
            })

            if (search) {
              parameters = {...parameters, _q: search};
            }
            if (ids && ids.length) {
              parameters = {...parameters, id: ids.join(',')};
            }

            if (limit) {
              parameters = {...parameters, _limit: limit};
            }

            const apiUrlCall = `${apiUrl}${areaEndpoint}?type=${subareaType}&${getQueryStringFromObject(parameters)}`;
            try {
              let {status, data} = await axios.get(apiUrlCall);
              if (status === 200) {
                data = data.map(item => ({
                  ...item,
                  type: subareaKey,
                  tags: item.servicesData && item.servicesData.represented && item.servicesData.represented.isRepresented ? ["represented"] : []
                }))


                resolve(data);
              } else {
                resolve(null);
              }
            } catch (error) {
              console.log(error);
              resolve(null);
            }
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      } else {
        resolve(null);
      }

    })
  }

  async getCount({area, subarea = null}) {

    const domainConfig = this.getDomainConfig();
    const apiUrl = this.getApiUrl();

    return new Promise(async (resolve, reject) => {

      if (apiUrl) {
        let isAreaEnabled = domainConfig.areas[area].enable;
        if (isAreaEnabled) {
          if (subarea) {
            if (!(Object.keys(domainConfig.areas[area].subareas)).includes(subarea)) {
              // console.log();
              subarea = Object.keys(domainConfig.areas[area].subareas).filter(key => domainConfig.areas[area].subareas[key].key === subarea)[0];
            }
          } else {
            subarea = Object.keys(domainConfig.areas[area].subareas)[0];
          }
          let isSubareaEnabled = subarea ? domainConfig.areas[area].subareas[subarea].enable : false;

          if (isSubareaEnabled) {

            let areaEndpoint = domainConfig.areas[area].dataServiceKey;
            let subareaType = domainConfig.areas[area].subareas[subarea].dataServiceKey;
            // let subareaKey = domainConfig.areas[area].subareas[subarea].key;

            const apiUrlCall = `${apiUrl}${areaEndpoint}?type=${subareaType}`;
            // axios.defaults.headers.common['Authorization'] = "Bearer 69a3250eC9ac2eFBc5ff804c9f20d0Bd66f4dDad";
            try {
              let response = await axios.get(apiUrlCall);
              if (response.status === 200) {
                resolve(parseInt(response.headers["x-total-count"]));
              } else {
                resolve(null);
              }
            } catch (error) {
              console.log(error);
              resolve(null);
            }
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      } else {
        resolve(null);
      }

    })
  }

  async getItemById(area, id, full = true) {
    if(area === "events" && this.language !== "it"){
      axios.defaults.headers.common['Accept-Language'] = "en";
    }else{
      axios.defaults.headers.common['Accept-Language'] = this.language;
    }

    const domainConfig = this.getDomainConfig();
    const apiUrl = this.getApiUrl();
    let that = this;

    return new Promise(async (resolve, reject) => {
      if (apiUrl) {
        let areaEndpoint = domainConfig.areas[area].dataServiceKey;

        const {data} = await axios.get(apiUrl + areaEndpoint + "?id=" + id, {});
        let record = data[0];

        switch (area) {
          case "companies":

            if (full) {

              // prodotti
              let products = [
                ...(await this.getList({area: "products", subarea: "products", page: 0, filters: {exhibitorId: id}, limit: 9999})),
                ...(await this.getList({area: "products", subarea: "wine", page: 0, filters: {exhibitorId: id}, limit: 9999})),
                ...(await this.getList({area: "products", subarea: "spirit", page: 0, filters: {exhibitorId: id}, limit: 9999}))
              ]
              products.sort((a, b) => a.options.order > b.options.order)

              // events
              const events = [
                ...(await this.getList({area: "events", subarea: "indoor", page: 0, filters: {exhibitorId: id}, limit: 9999})),
                ...(await this.getList({area: "events", subarea: "outdoor", page: 0, filters: {exhibitorId: id}, limit: 9999})),
                ...(await this.getList({area: "events", subarea: "virtual", page: 0, filters: {exhibitorId: id}, limit: 9999}))
              ]

              // projects
              let casehistories = await this.getList({
                area: "casehistories",
                subarea: "casehistories",
                page: 0,
                filters: {exhibitorId: id}
              });

              // relations
              let coexhibsIds = (await axios.get(apiUrl + domainConfig.areas["companies"].dataServiceKey + "/" + id + "/coexhibitors", {})).data.map((item) => item.id);
              let coexhibsChildIds = record.servicesData && record.servicesData.coExhibitor && record.servicesData.coExhibitor.coExhibitorDetails ? record.servicesData.coExhibitor.coExhibitorDetails.map((item) => item.parentExhibitorId) : [];
              let representsIds = record.servicesData && record.servicesData.represented && record.servicesData.represented.represented ? record.servicesData.represented.represented.map((item) => item.exhibitorId) : [];
              let representsChildIds = record.servicesData && record.servicesData.represented && record.servicesData.represented.representedBy ? record.servicesData.represented.representedBy.map((item) => item.parentExhibitorId) : [];

              const relations = {
                coexhibs:  coexhibsIds.length > 0 ? [...(await this.getList({area: "companies", ids: coexhibsIds, full: false, limit: 999}))].sort((a,b) => (coexhibsIds.indexOf(a.id) > coexhibsIds.indexOf(b.id)) ? 1 : 0) : [],
                coexhibsChild: coexhibsChildIds.length > 0 ? [...(await this.getList({area: "companies", ids: coexhibsChildIds, full: false, limit: 999}))].sort((a,b) => (coexhibsChildIds.indexOf(a.id) > coexhibsChildIds.indexOf(b.id)) ? 1 : 0) : [],
                represents:  representsIds.length > 0 ? [...(await this.getList({area: "companies", ids: representsIds, full: false, limit: 999}))].sort((a,b) => (representsIds.indexOf(a.id) > representsIds.indexOf(b.id)) ? 1 : 0) : [],
                representsChild:  representsChildIds.length > 0 ? [...(await this.getList({area: "companies", ids: representsChildIds, full: false, limit: 999}))].sort((a,b) => (representsChildIds.indexOf(a.id) > representsChildIds.indexOf(b.id)) ? 1 : 0) : []
              }
              // gallery
              if (record.options.gallery && record.options.gallery.length) {
                const gallery = await parseItemGallery(record.options.gallery);
                record = {
                  ...record,
                  options: {
                    ...record.options,
                    gallery
                  }
                }
              }

              record = {
                ...record,
                products,
                events,
                casehistories,
                relations
              }

            }

            break;

          case "products":
            if(full) {

              // company products
              const companyProducts = [
                ...(await this.getList({area: "products", subarea: "", page: 0, filters: {exhibitorId: record.scope.exhibitorId}})).filter(item => item.id !== id),
                ...(await this.getList({area: "products", subarea: "wine", page: 0, filters: {exhibitorId: record.scope.exhibitorId}})).filter(item => item.id !== id),
                ...(await this.getList({area: "products", subarea: "spirit", page: 0, filters: {exhibitorId: record.scope.exhibitorId}})).filter(item => item.id !== id),
              ];

              // event
              const productEvents = [
                ...(await this.getList({area: "events", subarea: "indoor", page: 0, filters: {productId: id}})),
                ...(await this.getList({area: "events", subarea: "outdoor", page: 0, filters: {productId: id}})),
                ...(await this.getList({area: "events", subarea: "virtual", page: 0, filters: {productId: id}}))
              ]

              record ={
                ...record,
                companyProducts,
                productEvents
              }

            }

            break;

          case "events":

            const companyData = await this.getItemById("companies", record.scope.exhibitorId, false);
            let eventProducts = [];
            if(record.options.productIds && record.options.productIds.length > 0){
              eventProducts = [
                ...(await this.getList({area: "products", subarea: "wine", page: 0, ids: record.options.productIds.map(item => item.id)})).filter(item => item.id !== id),
                ...(await this.getList({area: "products", subarea: "spirit", page: 0, ids: record.options.productIds.map(item => item.id)})).filter(item => item.id !== id)
              ];
            }
            const companyEvents = [
              ...(await this.getList({area: "events", subarea: "indoor", page: 0, filters: {exhibitorId: record.scope.exhibitorId}})).filter(item => item.id !== id),
              ...(await this.getList({area: "events", subarea: "outdoor", page: 0, filters: {exhibitorId: record.scope.exhibitorId}})).filter(item => item.id !== id),
              ...(await this.getList({area: "events", subarea: "virtual", page: 0, filters: {exhibitorId: record.scope.exhibitorId}})).filter(item => item.id !== id),
            ];

            record ={
              ...record,
              companyData,
              eventProducts,
              companyEvents
            }

            break;

          case "casehistories":

            const companyCasehistories = [
              ...(await that.getList({area: "casehistories", subarea: "", page: 0, filters: {exhibitorId: record.scope.exhibitorId}})).filter(item => item.id !== id),
            ]

            let product = null;
            if(record.options.relatedProductId){
              product = await that.getItemById("products", record.options.relatedProductId, false);
            }

            if (record.options.gallery && record.options.gallery.length) {
              const gallery = await parseItemGallery(record.options.gallery);
              record = {
                ...record,
                options: {
                  ...record.options,
                  gallery
                }
              }
            }

            record ={
              ...record,
              companyCasehistories,
              product
            }

            break;
          case "subcompanies":

            if (full) {
              // people
              const people = [
                ...(await this.getList({area: "people", page: 0, filters: {exhibitorId: id}, limit: 9999}))
              ]

              // subprojects
              let subcasehistories = await this.getList({
                area: "subcasehistories",
                subarea: "subcasehistories",
                page: 0,
                filters: {exhibitorId: id}
              });

              // gallery
              if (record.options.gallery && record.options.gallery.length) {
                const gallery = await parseItemGallery(record.options.gallery);
                record = {
                  ...record,
                  options: {
                    ...record.options,
                    gallery
                  }
                }
              }
              record = {
                ...record,
                people,
                subcasehistories
              }

            }

            break;
          default: 
            break;

        }

        resolve(record);

      } else {
        resolve(null);
      }
    })
  }

  async getFiltersStructure(area, subarea = '') {
    if(area === "events" && this.language !== "it"){
      axios.defaults.headers.common['Accept-Language'] = "en";
    }else{
      axios.defaults.headers.common['Accept-Language'] = this.language;
    }

    const domainConfig = this.getDomainConfig();
    const apiUrl = this.getApiUrl();

    return new Promise(async (resolve, reject) => {

      if (apiUrl) {

        if (subarea) {
          if (!(Object.keys(domainConfig.areas[area].subareas)).includes(subarea)) {
            subarea = Object.keys(domainConfig.areas[area].subareas).filter(key => domainConfig.areas[area].subareas[key].key === subarea)[0];
          }
        } else {
          subarea = Object.keys(domainConfig.areas[area].subareas)[0];
        }
        let areaEndpoint = domainConfig.areas[area].dataServiceKey;
        let subareaType = domainConfig.areas[area].subareas[subarea].dataServiceKey;

        let apiUrlCall = `${apiUrl}schema/${areaEndpoint}`

        if (areaEndpoint === 'products') {
          apiUrlCall += `?type=${subareaType}`
        }

        try {
          let {status, data} = await axios.get(apiUrlCall);
          if (status === 200) {
            // data = isArray(data) ? data.map(item => ({...item, type: subareaType})) : {...data, type: subareaType};
            resolve(data.properties);
          } else {
            resolve(null);
          }
        } catch (error) {
          console.log(error);
          resolve(null);
        }

      } else {
        resolve(null);
      }
    });
  }

  async getFiltersValues(area) {
    if(area === "events" && this.language !== "it"){
      axios.defaults.headers.common['Accept-Language'] = "en";
    }else{
      axios.defaults.headers.common['Accept-Language'] = this.language;
    }

    const domainConfig = this.getDomainConfig();
    const apiUrl = this.getApiUrl();

    return new Promise((resolve, reject) => {
      if (apiUrl) {
        let areaEndpoint = domainConfig.areas[area].dataServiceKey;

        // axios.defaults.headers.common['Authorization'] = "Bearer 69a3250eC9ac2eFBc5ff804c9f20d0Bd66f4dDad";
        axios.get(apiUrl + areaEndpoint + "/filters", {}).then(function (result) {
          // console.log(result);
          if (result.status === 200) {
            Object.keys(result.data).forEach(i => result.data[i].type = "list");
            resolve(result.data);
          } else {
            resolve(null);
          }
        }).catch(function (error) {
          console.log(error);
          resolve(null);
        });
      } else {
        resolve(null);
      }
    });
  }

  async getFacets({field, filters, query = null, search, c, area, subarea, ...args}) {
    if(area === "events" && this.language !== "it"){
      axios.defaults.headers.common['Accept-Language'] = "en";
    }else{
      axios.defaults.headers.common['Accept-Language'] = this.language;
    }

    const domainConfig = this.getDomainConfig();
    const apiUrl = this.getApiUrl();

    let hasLevel = false;
    let originalFieldLevel = null;

    if (field && field.indexOf('_lv') !== -1) {
      originalFieldLevel = field.split('_lv')[1]
      field = field.split('_lv')[0];
      hasLevel = true;
    }

    return new Promise(async (resolve, reject) => {
      if (apiUrl) {

        if (subarea) {
          if (!(Object.keys(domainConfig.areas[area].subareas)).includes(subarea)) {
            subarea = Object.keys(domainConfig.areas[area].subareas).filter(key => domainConfig.areas[area].subareas[key].key === subarea)[0];
          }
        } else {
          subarea = Object.keys(domainConfig.areas[area].subareas)[0];
        }
        let areaEndpoint = domainConfig.areas[area].dataServiceKey;
        let subareaType = domainConfig.areas[area].subareas[subarea].dataServiceKey;

        if (hasLevel) {
          Object.keys(filters).filter(key => key.indexOf('_lv') !== -1).map(key => {
            const tempKey = key.split('_lv');
            if (tempKey[1] <= originalFieldLevel) {
              filters = {...filters, [tempKey[0]]: filters[key]};
            }
            return 0;
          });
        }

        let parameters = Object.keys(filters).filter(key => !search || (search.trim() !== '' && key !== field)).reduce((acc, key) => {
          const paramValue = isArray(filters[key]) ? filters[key].join(',') : filters[key];
          return ({
            ...acc,
            [key]: paramValue
          })
        }, {
          filter: field,
          _limit: 99999,
        })

        if (query) {
          parameters = {...parameters, _q: query}
        }

        if (search) {
          parameters = {...parameters, _q: search}
        }

        if (subareaType) {
          parameters = {...parameters, type: subareaType}
        }

        const apiUrlCall = `${apiUrl}${areaEndpoint}/filters?${getQueryStringFromObject(parameters)}`;

        try {
          const {status, data} = await axios.get(apiUrlCall);
          if (status === 200) {
            resolve(data);
          } else {
            resolve(null);
          }
        } catch (error) {
          console.log(error);
          resolve(null);
        }

      } else {
        resolve(null);
      }

    });
  }

}

export default new DataService();
