import React from 'react';
import styled from 'styled-components';
import Button from "../App/Button";
import Logo from "../App/Logo";
import {useDispatch} from "react-redux";
import {login} from "../../store/auth/actions";

const Wrapper = styled.div`
    max-width: 90%;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
`;

const Header = styled.div`
  background-color: #7B3572;
  color: #fff;
  font-size: 18px;
  padding: 20px 60px;
  font-weight: 500;
`;

const Body = styled.div`
    background-color: #fff;
    padding: 40px 60px;
    -webkit-box-shadow: inset 0px 10px 10px -10px rgba(50, 50, 50, 0.4);
    -moz-box-shadow: inset 0px 10px 10px -10px rgba(50, 50, 50, 0.4);
    box-shadow: inset 0px 10px 10px -10px rgba(50, 50, 50, 0.4);
}`;

const FavoriteModal = ({translations}) => {

  const dispatch = useDispatch();

  const onClick = e => {
    e.preventDefault();
    dispatch(login(), [dispatch])
  };

  return (
    <Wrapper>
      <Header>{translations['_login-popup-title']}</Header>
      <Body>
        <Logo width={{value: 200, unit: 'px'}} />
        <p style={{ marginBottom: '30px' }}>{translations['_login-popup-text']}</p>
        <Button onClick={onClick} align={'center'}>{translations['Login']}</Button>
      </Body>
    </Wrapper>
  );

}

export default React.memo(FavoriteModal)
