import React, {useState} from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import {useSelector} from "react-redux";
import {getUser} from "./../../store/auth/selectors";
import {getLayout} from "./../../store/settings/selectors";
import {getLocale, getTranslations} from "./../../store/localization/selectors";

import Button from "./../../components/App/Button";
import Icon from "./../../components/App/Icon";
import Logo from "./../../components/App/Logo";

import {LAYOUT_XS, LAYOUT_SM} from './../../settings';

import NewsService from './../../services/NewsService';
import WelcomeAdv from "./WelcomeAdv";


const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0,0,0,0.46);
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  border-radius: 10px;
  background: #ffffff;
  max-width: 700px;
  padding: 30px;

  text-align: center;
  max-height: 90vh;
`;
const Content = styled.div`
  max-height: calc(90vh - 60px);
  overflow-x: hidden;
  overflow-y: auto;
`;

const Title = styled.div`  
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;
const Close = styled.div`  
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Elements = styled.div`  
  display: grid;
  grid-template-${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "rows" : "columns"}: repeat(3, 1fr);
  grid-gap: 20px;
`;
const Element = styled.div`  
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const Adv = styled.div`
`;

const AdvTitle = styled.div`
  color: #818181;
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 12px;
  margin-top: 8px;
  height: 18px;
  text-align: left;
`;

const Spacer = styled.div`  
  height: ${props => props.height}px;
  width: 100%;
`;

function WelcomeModal({ onClose }) {
  const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);
  const user = useSelector(getUser);
  const layout = useSelector(getLayout);
  // console.log(user);
  const [adv, setADV] = useState(null);

  async function loadData() {

    try {
      // Contenuti
      const data = await NewsService.getList();
      await setADV(data['Welcome']);

    } catch (e) {}

  }


  if(!adv){
    loadData();
  }

    return (
        <Wrapper>
            <Container>
              <Close onClick={onClose}>
                <Icon
                  size={{
                  value: 30,
                  unit: "px"
                  }}
                  name={"close-1"}
                  color={"#000000"}
                  background={"transparent"}
                />
              </Close>
              <Content>
                <Logo height={{value: 40, unit: "px"}} variant="plus" />
                <Spacer height={40} />
                <Title>{translations["welcome"]["_welcome-title"]} {user && user.firstname ? user.firstname : ""}!</Title>
                {translations["welcome"]["_welcome-subtitle"]}
                <Spacer height={20} />
                <Elements layout={layout}>
                  <Element>
                    <Icon
                      size={{
                      value: 90,
                      unit: "px"
                      }}
                      name={"contact-1"}
                      color={"#E5087E"}
                      background={"transparent"}
                    />
                    {translations["welcome"]["_welcome-text-contact"]}
                  </Element>  
                  <Element>
                    <Icon
                      size={{
                      value: 90,
                      unit: "px"
                      }}
                      name={"search-1"}
                      color={"#E5087E"}
                      background={"transparent"}
                    />                
                    {translations["welcome"]["_welcome-text-search"]}
                  </Element>  
                  <Element>
                    <Icon
                      size={{
                      value: 90,
                      unit: "px"
                      }}
                      name={"favorite-3"}
                      color={"#E5087E"}
                      background={"transparent"}
                    />                  
                    {translations["welcome"]["_welcome-text-explore"]}
                  </Element>  
                </Elements>
                <Spacer height={40} />
                <Link to={"/"+lang+"/"} onClick={onClose}>
                  <Button variant="pink-o" display="inline-block">
                    {translations["welcome"]["_welcome-text-join"]}
                  </Button>
                </Link>
                <Spacer height={40} />
                {adv && adv.length > 0 ? 
                  <>
                    <Adv>
                      <AdvTitle>Advertising</AdvTitle>
                    </Adv>

                    <Elements layout={layout}>
                      {adv.map((item) => 
                        <Element>
                          <WelcomeAdv
                            image={item["immagine"] ? item["immagine"] : null}
                            abstract={item["abstract_"+lang] ? item["abstract_"+lang] : null}
                            content={item["abstract_"+lang] ? item["abstract_"+lang] : null}
                            ctaText={item["testo_"+lang] ? item["testo_"+lang] : null}
                            ctaLink={item["link_"+lang] ? item["link_"+lang] : null}
                          />
                        </Element>
                      )}
                    </Elements>
                  </>
                : null }
                
              </Content>
            </Container>
        </Wrapper>
    );

}

export default WelcomeModal;
