import { createRoutine } from 'redux-saga-routines'

export const AUTH_LOGIN = "Auth.Login"
export const AUTH_CHECK = "Auth.Check"
export const AUTH_LOGOUT = "Auth.Logout"
export const AUTH_SETFAVORITE = "Auth.SetFavorite"
export const AUTH_GETFAVORITES = "Auth.GetFavorites"
export const AUTH_GETRECENTS = "Auth.GetRecents"
export const AUTH_SETRECENTS = "Auth.SetRecents"
export const AUTH_PROFILE = "Auth.Profile"
export const AUTH_GET_PLAN = "Auth.GetPlan"
export const AUTH_SET_ROLES = "Auth.SetRoles"

export const login = createRoutine(AUTH_LOGIN)
export const check = createRoutine(AUTH_CHECK)
export const logout = createRoutine(AUTH_LOGOUT)
export const setFavorite = createRoutine(AUTH_SETFAVORITE)
export const getFavorites = createRoutine(AUTH_GETFAVORITES)
export const setRecents = createRoutine(AUTH_SETRECENTS)
export const getRecents = createRoutine(AUTH_GETRECENTS)
export const profile = createRoutine(AUTH_PROFILE)
export const getPlan = createRoutine(AUTH_GET_PLAN)
export const setRoles = createRoutine(AUTH_SET_ROLES)
