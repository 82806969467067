import { createRoutine } from 'redux-saga-routines'

const area = 'subcasehistories';

export const LIST = `${area}.List`;
export const FACETS = `${area}.Facets`;
export const FILTERS = `${area}.Filters`;
export const FILTERS_TREE = `${area}.FiltersTree`;
export const SET_COUNT = `${area}.SetCount`;
export const FILTERS_SCHEMA = `${area}.FiltersSchema`;

export const setList = createRoutine(LIST);
export const setFacets = createRoutine(FACETS);
export const setFilters = createRoutine(FILTERS);
export const setFiltersTree = createRoutine(FILTERS_TREE);
export const setCount = createRoutine(SET_COUNT);
export const setFiltersSchema = createRoutine(FILTERS_SCHEMA);
