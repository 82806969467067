import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {withTheme} from './../../themes'

import { getLayout } from './../../store/settings/selectors'

const Wrapper = styled.div`
  width: 1200px;
  max-width: ${props => props.w};
  margin: 0 auto;
  @media(max-width: 1200px) {
    width: 100%;
  }
`;

const Container = ({ theme, children, style = {} }) => {
  const layout = useSelector(getLayout)

  return (
    <Wrapper w={theme.container[layout].width.value + theme.container[layout].width.unit} style={style}>
      {children}
    </Wrapper>
  )
}

export default withTheme(Container)
