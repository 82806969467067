import React from "react";
import {useDispatch} from "react-redux";
import styled from "styled-components";
import {setStatus} from "../../store/settings/actions";
// import {DOMAINS} from "../../domains";

import Icon from "./../App/Icon";
// // import LogoutButton from "./../User/LogoutButton"


const Wrapper = styled.section`
  /* padding: 10px; */
  position: relative;
  color: ${props => props.color};
  display: flex;
  align-items: center;
  cursor: pointer;
`;


const CompactMenuIcon = () => {
  const dispatch = useDispatch();

  return (
    <Wrapper onClick={() => dispatch(setStatus({
      isUserpanelOpen: true
    }), [dispatch])}>
      <Icon
        name={"menu-1"}
        size={{value: 50, unit: "px"}}
        color={"#000000"}
        background={"transparent"}
      />
    </Wrapper>
  )
}

export default CompactMenuIcon;
