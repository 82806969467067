import produce from 'immer';
import { handleActions } from 'redux-actions';
import { setLocale, loadTranslation } from './actions';

const INITIAL_STATE = {
  current: null,
  translations: null,
};

const reducer = handleActions({

  // set locale
  [setLocale.TRIGGER]: (state) => produce(state, draft => {}),
  [setLocale.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.current = payload;
  }),
  [setLocale.FAILURE]: (state, { payload }) => produce(state, draft => {}),

  // load translation
  [loadTranslation.TRIGGER]: (state) => produce(state, draft => {}),
  [loadTranslation.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.translations = Object.assign({}, state.translations, payload);
  }),
  [loadTranslation.FAILURE]: (state, { payload }) => produce(state, draft => {}),

}, INITIAL_STATE);

export default reducer
