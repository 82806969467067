import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';

const Element  = styled.div`
  width: 100%;
  height: ${props => props.size.value + props.size.unit};
  flex-grow: 0;
  flex-shrink: 0;
`;

const Spacer = ({
  size
}) => {

  return (
    <Element
      size={size}
    / >
  )
}

Spacer.propTypes = {
  size: PropTypes.object,
}

export default Spacer
