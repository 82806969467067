import React from 'react';
import styled from 'styled-components'


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-height: 100%;
`;

const DefaultIdlepage = () => {
  return (
    <Wrapper>
      <div>Default Idlepage</div>
    </Wrapper>
  );
};

export default DefaultIdlepage;
