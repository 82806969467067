export const FIC = {
  tenant: "FIC",
  name: "Fieracavalli 2022",
  default_language: 'it',
  languages: ["it", "en"], //, "fr", "zh", "ru", "ko", "ja", "pt", "es", "de"
  enable_login: true,
  enable_helpdesk: false,
  enable_favorites: true,
  enable_suggested: false,
  enable_halls: true,
  enable_chat: false,
  enable_bcards: false,
  enable_memos: false,
  enable_wizard: false,
  enable_agenda: false,
  enable_terms: true,
  enable_languages_in_menu: true,
  tracking: [
    {
      //   type: 'hotjar',
      //   code: 183316,
      //   domains: ['catalogo.fieragricola.it']
      // }, {
      type: 'analytics',
      code: 'UA-1767479-6', //fiera produzione
      domains: ['fieracavalli.it','recat-fic-test.veronafiere.ozwol.tech'],
      // }, {
      //   type: 'analytics',
      //   code: 'UA-169833767-1', //interno test
      //   domains: ['recat-agr-test.firebaseapp.com'],
      // }, {
      //   type: 'analytics',
      //   code: 'UA-169833767-2', //interno produzione
      //   domains: ['catalogo.fieragricola.it'],
    }
  ],
  share_networks: ["facebook", "twitter", "pinterest"],
  translations: {
    url: "https://ozwol-tech-cdn.s3-eu-west-1.amazonaws.com/veronafiere/fieracavalli/",
  },
  ozwol: {
    endpoint: "https://api.ozwol.cloud/v1/executeScript",
    apiKey: "f9dc3e58-acc9-466f-9af1-085a19f86015",
    projectId: "47S3WM8K6G",
    cdn: "https://cdn.ozwol.cloud/47S3WM8K6G"
  },
  sendmail: {
    endpoint: "https://us-central1-fieragricola.cloudfunctions.net/app/api/sendmail",
    email: "noreply@veronafiere.it",
    bcc: "sara.potyscki@ozwol.com" //separati da virgola
  },
  cookiebot: "689e84ab-974e-4bf6-b11b-da9ea11c80db",
  auth0: {
    domain: "login.veronafiere.it",
    clientID: "rMiEB7P6Iy4EfyR7gBniufJCw1QsXIhf",
    responseType: "token id_token",
    scope: 'openid profile email',
    audience: "https://veronafiere.api.it",
    issuer: 'https://login.veronafiere.it/',
  },
  bcards: {
    apiUrl: null //"https://vrf-vinitaly-app.appspot.com/user/bcards/"
  },
  memos: {
    apiUrl: "https://vrf-fieracavalli-app.appspot.com/user/memos/"
  },
  news: {
    apiUrl: "https://apicatalogo.veronafiere.it/v1/FIC/contenuti_plus/"
  },
  recommended: {
    apiUrl: "https://apicatalogo.veronafiere.it/v1/FIC/users/"
  },
  areas: {
    "companies": {
      icon: 'company.svg',
      dataServiceKey: "exhibitors",
      enable: true,
      enable_detail: true,
      default: true,
      routes: {
        list: 'companies',
        detail: 'companies/{slug}'
      },
      subareas: {
        "companies": {
          default: true,
          key: "company",
          dataServiceKey: "",
          enable: true,
          enable_detail: true
        }
      }
    },
    "products": {
      icon: 'product.svg',
      dataServiceKey: "products",
      enable: true,
      enable_detail: true,
      default: false,
      singlePermission: "productdetail:r",
      routes: {
        list: 'products/{type}',
        detail: 'products/{type}/{slug}'
      },
      subareas: {
        "products": {
          default: true,
          key: "products",
          dataServiceKey: "",
          enable: true,
          enable_detail: true
        }
      }
    },
    "casehistories": {
      icon: 'casehistory.svg',
      dataServiceKey: "projects",
      enable: false,
      default: false,
      singlePermission: "projectdetail:r",
      routes: {
        list: 'casehistories',
        detail: 'casehistories/{slug}'
      },
      subareas: {
        "casehistories": {
          default: true,
          key: "casehistories",
          dataServiceKey: "",
          enable: true,
          enable_detail: true
        }
      }
    },
    "events": {
      icon: 'event.svg',
      dataServiceKey: "events",
      enable: true,
      enable_detail: true,
      default: false,
      routes: {
        list: 'events/{type}',
        detail: 'events/{type}/{slug}'
      },
      subareas: {
        "indoor": {
          default: true,
          key: "indoor",
          dataServiceKey: "indoor",
          enable: true,
          enable_detail: true
        },
        "outdoor": {
          default: false,
          key: "outdoor",
          dataServiceKey: "outdoor",
          enable: true,
          enable_detail: false
        }
      }
    }
  },
  accountSettings: {
    theme: "fieracavalli",
    proAccount: false
  },
  chatConfig: {
    default: {
      pluginEnv: 'staging',
      wsEndpoint: 'wss://qh2k5m2qw1.execute-api.eu-west-1.amazonaws.com/test/',
      apiUrl: 'https://11o2teju4f.execute-api.eu-west-1.amazonaws.com/staging/fic/',
      favoritesUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/favorites',
      recentsUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/recents',
      accountUrl: 'https://deevent-api-dev.24apps.it/api/v2/account/public'
    },
    'recat-fic-dev.veronafiere.ozwol.tech': {
      pluginEnv: 'staging',
      wsEndpoint: 'wss://qh2k5m2qw1.execute-api.eu-west-1.amazonaws.com/test/',
      apiUrl: 'https://11o2teju4f.execute-api.eu-west-1.amazonaws.com/staging/fic/',
      favoritesUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/favorites',
      recentsUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/recents',
      accountUrl: 'https://deevent-api-dev.24apps.it/api/v2/account/public'
    },
    'recat-fic-test.veronafiere.ozwol.tech': {
      pluginEnv: 'staging',
      wsEndpoint: 'wss://qh2k5m2qw1.execute-api.eu-west-1.amazonaws.com/test/',
      apiUrl: 'https://11o2teju4f.execute-api.eu-west-1.amazonaws.com/staging/fic/',
      favoritesUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/favorites',
      recentsUrl: 'https://deevent-api-dev.24apps.it/api/v2/userdata/public/recents',
      accountUrl: 'https://deevent-api-dev.24apps.it/api/v2/account/public'
    },
    'catalogo.fieracavalli.it': {
      pluginEnv: 'production',
      wsEndpoint: 'wss://chat.quickchat.online/vrf/',
      apiUrl: 'https://l1iq9e6hm6.execute-api.eu-west-1.amazonaws.com/production/fic/',
      favoritesUrl: 'https://deevent-api.24apps.it/api/v2/userdata/public/favorites',
      recentsUrl: 'https://deevent-api.24apps.it/api/v2/userdata/public/recents',
      accountUrl: 'https://deevent-api.24apps.it/api/v2/account/public'
    }
  },
  awsAnalytics: {
    localhost: null,
    default: {
      area: "staging",
      credentials: {
        accessKeyId: "AKIATLSVYBWBKK4LNFSX",
        secretAccessKey: "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"
      },
      region: "eu-west-1",
      deliveryStreamName: "testCatalogoWebFirehoseDelivery"
    },
    'catalogo.fieracavalli.it': {
      area: "production",
      credentials: {
        accessKeyId: "AKIAUCEJSNJLQTNPVR7F",
        secretAccessKey: "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"
      },
      region: "eu-west-1",
      deliveryStreamName: "AGRCatalogoWebFirehoseDeliveryStream"
    }
  },
  "needPermissionTo": []
};
