import React from 'react';
import { useTheme } from './../../themes';

import './Tag.scss';
import Icon from "../App/Icon";

const Tag = ({ item, label, onClick }) => {
  const theme = useTheme();

  return (
    <div className={'FilterTag'}>
      <button className={'FilterTag__Button'} type='button' onClick={e => onClick(item)}>
        <Icon name={'close-1'} color={theme.filters.tag.icon.color} size={{value: 16, unit: 'px'}} />
      </button>
      <span>{label}</span>
    </div>
  );
};

export default Tag;
