import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import {setCount, setFacets, setFilters, setFiltersSchema, setFiltersTree, setList} from './actions'
import DataService from "../../services/DataService";
import RecommendedService from "../../services/RecommendedService";

import {getConfig, getLevels, getResultsType, getSubarea} from "../settings/selectors";
import {getFavorites, getRecents, getUser} from "../auth/selectors";
import {setLevels} from "../settings/actions";
import {getCount} from "./selectors";
import {omit} from "lodash";
import {DOMAINS} from "./../../domains";
import RecentsService from "../../services/RecentsService";

function* setListSaga({ payload }) {
  const config = yield select(getConfig);
  let resetList = payload.reset;
  let subarea = payload.hasOwnProperty('subarea') ? payload.subarea : yield select(getSubarea);
  subarea = (subarea ? config.areas.products.subareas[subarea].key : null);
  try {

    let filterByIds = null;
    const resultsType = yield select(getResultsType);
    let clearIfEmpty = false;

    if (resultsType === 'recents') {
      const recents = yield call([RecentsService, 'getList'], 'products');
      filterByIds = recents && recents.length ? recents.map(({ itemId}) => itemId) : [];
      clearIfEmpty = true;
    } else if (resultsType === 'favorites') {
      const favorites = yield select(getFavorites);
      filterByIds = favorites ? favorites.map(item => item.itemId) : [];
      clearIfEmpty = true;
    } else if (resultsType === 'suggested') {
      const user = yield select(getUser);
      const recommendeds = yield RecommendedService.getProducts(user ? user.id : null).then(ret  => {return ret;}).catch(err => console.log('There was an error:' + err));
      filterByIds = recommendeds ? recommendeds.map(item => item.item) : [];
    }

    if (resetList) {
      yield put(setList.success(Object.assign({}, {
        items: [],
        count: 0,
        page: 0,
        reset: true,
        timestamp: null,
        resetSubarea: true,
        isLoading: true
      })))
    }

    let response = yield call([DataService, 'getList'], {
      area: "products",
      subarea: subarea,
      page: payload.page,
      search: payload.search,
      filters: payload.filters,
      ids: filterByIds,
      resultsType: 'suggested',
    });

    response = Object.assign({}, {
      items: clearIfEmpty && filterByIds.length === 0 ? [] : response,
      count: clearIfEmpty && filterByIds.length === 0 ? 0 : response.length,
      page: payload.page,
      reset: payload.reset,
      timestamp: payload.timestamp,
      resetSubarea: payload.resetSubarea,
      isLoading: false
    });

    yield put(setList.success(response))

  } catch(e) {

    yield put(setList.failure(e))

  }
}

function* setFacetsSaga({payload}) {

  const {field, tempFilters, reset, search} = payload;

  const subarea = yield select(getSubarea);

  try {

    let filterByIds = null;
    const resultsType = yield select(getResultsType);

    if (resultsType === 'recents') {
      const recents = yield select(getRecents);
      filterByIds = recents && recents.companies ? recents.companies : [];
    } else if (resultsType === 'favorites') {
      const favorites = yield select(getFavorites);
      filterByIds = favorites ? favorites.map(item => item.itemId) : [];
    } else if (resultsType === 'suggested') {
      const user = yield select(getUser);
      const recommendeds = yield RecommendedService.getCompanies(user ? user.id : null).then(ret  => {return ret;}).catch(err => console.log('There was an error:' + err));
      filterByIds = recommendeds ? recommendeds.map(item => item.item) : [];
    }

    if (reset) {

      yield put(setFacets.success({reset: true}));

    } else {

      let filters = omit(payload.filters, [field.field]);
      const query = tempFilters ? tempFilters[Object.keys(tempFilters)[0]] : '';
      const fieldName = payload.field.hasOwnProperty('field') ? payload.field.field : payload.field;

      const args = {
        area: 'products',
        subarea: subarea,
        field: fieldName,
        filters,
        query,
        search,
        ids: filterByIds
      };

      let data = yield call([DataService, 'getFacets'], args);
      let response = {
        field: fieldName,
        data
      };

      response = Object.assign({}, response, {
        count: 0
      });

      yield put(setFacets.success(response));

    }

  } catch (e) {

    console.error(e);

  }
}


function* setFiltersSaga({ payload }) {

  try {
    yield put(setFilters.success(payload));
  } catch(e) {
    console.error(e);
  }

}

function* setFiltersTreeSaga({ payload }) {

  const levels = yield select(getLevels);
  const subarea = yield select(getSubarea)
  const filters = payload.filters;

  try {

    if(levels.indexOf(payload.facet) === -1) {

      const args = {
        area: 'products',
        subarea: subarea,
        [payload.field]: payload.facet,
        field: payload.field,
        filters,
      };

      let data = yield call([DataService, 'getFacets'], args);

      if (data && data.length) {
          let fieldName = levels.filter(item => item.indexOf('_lv') === -1).reverse()[0];
          let filteredLevels = levels.filter(item => item.indexOf(`${fieldName}_lv`) === 0)
          const newLevels = [...levels, `${fieldName}_lv${filteredLevels.length + 1}`];
          yield put(setLevels.trigger({levels: [...newLevels], levelsDepth: newLevels.length}))
      }

    }

  } catch (e) {
  }

}

function* setCountSaga() {

  const count = yield select(getCount);

  if (count === null) {
    let total = 0;
    for(var subarea of Object.keys(DOMAINS[window.location.hostname].areas["products"].subareas)){
      total += yield call([DataService, 'getCount'], {
        area: "products",
        subarea: subarea
      });
    }
    yield put(setCount.success({count: total}));
  }

}

function* setFiltersSchemaSaga({ payload }) {

  try {
    let data = yield call([DataService, 'getFiltersStructure'], 'products', payload);
    yield put(setFiltersSchema.success(data));
  } catch (e) {
    yield put(setFiltersSchema.failure(e.message));
  }

}


export default function* root() {
  yield all([
    yield takeLatest([setList.TRIGGER], setListSaga),
    yield takeLatest([setFacets.TRIGGER], setFacetsSaga),
    yield takeLatest([setFilters.TRIGGER], setFiltersSaga),
    yield takeLatest([setFiltersTree.TRIGGER], setFiltersTreeSaga),
    yield takeLatest([setCount.TRIGGER], setCountSaga),
    yield takeLatest([setFiltersSchema.TRIGGER], setFiltersSchemaSaga)
  ])
}
