import React from 'react';
import {CSSTransition} from 'react-transition-group';
import Icon from "../../App/Icon";
import './style.scss';

function Modal({show, onClose, children}) {

  return (
    <CSSTransition
      in={show ? true : false}
      classNames={'Modal'}
      timeout={1000}
    >
      <div className={'Modal'}>
        <div className={'Modal_Overlay'} onClick={e => onClose(false)}>
          <button className={'Modal_CloseButton'}><Icon size={{value: 30, unit: 'px'}} color={'#fff'} name={'close-1'} /></button><br />
        </div>
        {children}
      </div>
    </CSSTransition>
  );

}

export default Modal;
