import React from 'react';
import { useSelector} from "react-redux";
import { getConfig } from "./../../store/settings/selectors";

import VirHomepageSidebar from "./../../tenants/VIR/Home/Sidebar";
import MdmHomepageSidebar from "./../../tenants/MDM/Home/Sidebar";

export default function Sidebar({selected}){
  const config = useSelector(getConfig);

  switch (config.tenant) {
    case "VIR":
      return <VirHomepageSidebar selected={selected}/>;
    case "MDM":
      return <MdmHomepageSidebar selected={selected} />;
    default:
      return <div>HomepageSidebar default</div>;
  }
}
