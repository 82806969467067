import React, { useState } from 'react';
// import { useSelector } from 'react-redux'
import styled from 'styled-components';
import { withTheme } from './../../themes';
// import Icon from "./../App/Icon"
// import { getLayout } from './../../store/settings/selectors'
// import {LAYOUT_XS, LAYOUT_SM} from './../../settings';

const Wrapper  = styled.div`
`;

const VisibleContent = styled.div`
  /* border: 1px solid lime; */
`;
const HiddenContent = styled.div`
  /* border: 1px solid red; */
`;

const ShowMore = styled.div`
  text-align: center;
  padding-top: 15px;
  /* border: 1px solid orange; */
`;
const ShowLess = styled.div`
  text-align: center;
  padding-top: 15px;
  /* border: 1px solid orange; */
`;

const ReadMore = ({
  visibleContent,
  hiddenContent,
  buttonMore,
  buttonLess
}) => {
  // const layout = useSelector(getLayout);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Wrapper
      isCollapsed={!isOpen}
    >

      <VisibleContent

      >
        {visibleContent}
        {!isOpen && hiddenContent ?
          <ShowMore onClick={() => setIsOpen(true)}>{buttonMore}</ShowMore>
        : null }

      </VisibleContent>
      {isOpen ?
        <HiddenContent>
          {hiddenContent}
          <ShowLess onClick={() => setIsOpen(false)}>{buttonLess}</ShowLess>
        </HiddenContent>
      : null }
    </Wrapper>
  )
}


export default withTheme(ReadMore)
