import React from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTheme } from "./../../../themes";

import Icon from "../../App/Icon";
import MenuItem from "../../App/MenuItem";
import Badge from "../../App/Badge";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

function LevelButton({ label, hideArrow, count, onClick, active, tags }) {
  const theme = useTheme();
  return (
    <MenuItem
      isActive={active}
      onClick={onClick}
      variant={theme.filters.item.menuitemVariant}
      rightIcon={(!hideArrow && !count) || (count && !hideArrow) ? <Icon name={theme.icons.set.menuitemNext} color={theme.menuitem.variant[theme.filters.item.menuitemVariant].normal.textColor} /> : null}
      after={tags}
    >
      <Wrapper>
        <Left>{label}</Left>
        <Right>
          {active ? <Icon name={theme.icons.set.checked} color={theme.menuitem.variant[theme.filters.item.menuitemVariant].normal.textColor} size={{value: 20, unit: 'px'}} /> : null}
          &nbsp;&nbsp;
          {count ? <Badge variant={theme.filters.item.badgeVariant}>{count}</Badge>: null }
        </Right>
      </Wrapper>
    </MenuItem>
  );
}

export function LevelLink ({ label, hideArrow, count, onClick, active, to, tags }) {
  const theme = useTheme();
  return (
    <Link to={to}>
      <MenuItem
        isActive={active}
        onClick={onClick}
        variant={theme.filters.item.menuitemVariant}
        rightIcon={!count || (count && !hideArrow) ? <Icon name={theme.icons.set.menuitemNext} color={theme.menuitem.variant[theme.filters.item.menuitemVariant].normal.textColor} /> : null}
        after={tags}
      >
        <Wrapper>
          <Left>{label}</Left>
          <Right>
            {active ? <Icon name={theme.icons.set.checked} color={theme.menuitem.variant[theme.filters.item.menuitemVariant].normal.textColor} size={{value: 20, unit: 'px'}} /> : null}
            &nbsp;&nbsp;
            {count ? <Badge variant={theme.filters.item.badgeVariant}>{count}</Badge>: null }
          </Right>
        </Wrapper>
      </MenuItem>
    </Link>
  );
}

export default LevelButton;
