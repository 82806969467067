import {createSelector} from 'reselect';

export const getFeature = createSelector(
  (state) => state.bcard,
  bcard => bcard
);

export const getList = createSelector(
  getFeature,
  bcard => bcard.list
);

export const getByEmail = (email) => createSelector(
  getList,
  (list) => email ? list.find(item => item.email === email) : null
);

export const getById = (id) => createSelector(
  getList,
  (list) => id ? list.find(item => item.id === id) : null
);
