import React from 'react';
import {isArray, isBoolean} from "lodash";
import Tag from "./Tag";

const Tags = ({selected, filter, translations, onRemoveFilter}) => {

  return Object.keys(selected).map((filterKey, index) => {
    let label = filterKey.split('_');
    label = translations[`_tag-label-${label[1]}`];
    if (!label) {
      label = '%s';
    }

    if (isArray(selected[filterKey])) {
      return selected[filterKey].map((facet, facetIndex) => {
        let facetLabel = decodeURIComponent(facet).split(' >> ')
        facetLabel = facetLabel[facetLabel.length - 1];
        return <Tag key={`${filterKey}-${facetIndex}`} item={{key: filterKey, value: facet}}
             label={label.replace('%s', facetLabel)} onClick={onRemoveFilter}/>
      });
    } else if (isBoolean(selected[filterKey])) {
      const facet = selected[filterKey];
      let facetLabel = decodeURIComponent(facet).split(' >> ')
      facetLabel = facetLabel[facetLabel.length - 1];
      return <Tag key={`${filterKey}-0`} item={{key: filterKey, value: facet}} label={label.replace('%s', facetLabel)} onClick={onRemoveFilter} />;
    } else {
      const filterKeyArray = filterKey.split('_');
      let value = selected[filterKey];
      const facet = translations[`_filter-label-${filterKeyArray[filterKeyArray.length - 1]}`];
      label = (facet ? `${facet}: ` : '') + '%s';
      const valueSplit = value.split('|');
      value = valueSplit.length > 1 ? valueSplit[1] : value;
      let facetLabel = decodeURIComponent(value).split(' >> ')
      facetLabel = facetLabel[facetLabel.length - 1];
      return <Tag key={`${filterKey}-0`} item={{key: filterKey, value: value}} label={label.replace('%s', facetLabel)} onClick={onRemoveFilter} />;
    }

    // return null;

  })

}

export default Tags;
