import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import Authorization from './../../components/User/Authorization'
import UserPanel from './../../components/User/UserPanel'
import Idlepage from './../../components/Idlepage/Idlepage'
import List from "./../../components/List/List"
import Page from "./../../components/Page/Page"

import {ListWrapper, Mover, PageWrapper, PageWrapperNoSidebar, PageWrapperNoSidebarCentered} from "./styled";
import {getConfig} from "../../store/settings/selectors";
import {useSelector} from "react-redux";
import Home from "../Home/Home";
import HomeSidebar from "../Home/Sidebar";
import Memo from "./../Memo/Memo";
import BCards from "../BCards/BCards";
import BCardsSidebar from "../BCards/Sidebar";
import MyArea from "../MyArea/MyArea";
import HelpDesk from "./../Helpdesk/Helpdesk";
import MyBCard from "../MyArea/MyBCard";
import Terms from "../Terms/Terms";
import ContactPolicy from "../Terms/ContactPolicy";

function Redirector({match}) {

  const config = useSelector(getConfig);
  const {default_language, languages} = config;

  let broswerLanguage = navigator.language || navigator.userLanguage;
  broswerLanguage = broswerLanguage.indexOf('-') ? broswerLanguage.split('-') : broswerLanguage;
  broswerLanguage = (typeof broswerLanguage === 'string' ? broswerLanguage : broswerLanguage[0]).toLowerCase();

  const toLanguage =
    broswerLanguage && languages.indexOf(broswerLanguage) !== -1 ? broswerLanguage : (
      !match || (match && languages.indexOf(match.params.lang) === -1) ? default_language : match.params.lang
    );

  return (
    <Redirect to={`/${toLanguage}`}/>
  );
}

const Desktop = (props) => {

  const {theme, status, config} = props;

  const listRoutes = Object.keys(config.areas).filter(areaKey => config.areas[areaKey].enable).map(areaKey => {

    const activeSubareas = Object.keys(config.areas[areaKey].subareas).filter(subareaKey => config.areas[areaKey].subareas[subareaKey].enable);
    let url = `${areaKey}`;
    if (activeSubareas.length > 1) {
      url = `${areaKey}/:subarea`
    }
    return {key: areaKey, route: `/:lang/${url}`};

  });

  const idleRoutes = Object.keys(config.areas).filter(areaKey => config.areas[areaKey].enable).map(areaKey => {

    const activeSubareas = Object.keys(config.areas[areaKey].subareas).filter(subareaKey => config.areas[areaKey].subareas[subareaKey].enable);
    let url = `${areaKey}`;
    if (activeSubareas.length > 1) {
      url = `${areaKey}/:subarea`
    }
    return {key: areaKey, route: `/:lang/${url}`};
  });

  const detailRoutes = Object.keys(config.areas).filter(areaKey => config.areas[areaKey].enable).map(areaKey => {

    const activeSubareas = Object.keys(config.areas[areaKey].subareas).filter(subareaKey => config.areas[areaKey].subareas[subareaKey].enable);
    let url = `${areaKey}/:slug/:text?`;
    if (activeSubareas.length > 1) {
      url = `${areaKey}/:subarea/:slug/:text?`
    }
    return {key: areaKey, route: `/:lang/${url}`};
  });

  return (
    <Mover isMobile={false} isOpen={status.isFilterOpen} filterWidth={theme.filters.width} listWidth={theme.list.width}>
        <Switch>
          <Route exact path='/authorization' render={(route) => <Authorization/>}/>
          <Route exact path='/' component={Redirector}/>
          <Route exact path='/:lang' render={() => theme.homepage.fullsize ? null : (
            <ListWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <HomeSidebar selected={"homepage"}/>
            </ListWrapper>
          )}/>
          <Route exact path='/:lang/homepage/:id' render={() => theme.homepage.fullsize ? null : (
            <ListWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <HomeSidebar selected={"homepage"} />
            </ListWrapper>
          )}/>
          <Route exact path='/:lang/inspiration/:id?' render={() => theme.homepage.fullsize ? null : (
            <ListWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <HomeSidebar selected={"inspiration"} />
            </ListWrapper>
          )}/>
          <Route exact path='/:lang/markettrends/:id?' render={() => theme.homepage.fullsize ? null : (
            <ListWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <HomeSidebar selected={"markettrends"} />
            </ListWrapper>
          )}/>
          <Route exact path='/:lang/socialwall/:id?' render={() => theme.homepage.fullsize ? null : (
            <ListWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <HomeSidebar selected={"socialwall"} />
            </ListWrapper>
          )}/>
          <Route exact path='/:lang/winepeople/:id?' render={() => theme.homepage.fullsize ? null : (
            <ListWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <HomeSidebar selected={"winepeople"} />
            </ListWrapper>
          )}/>
          <Route exact path='/:lang/vinitalyworld/:id?' render={() => theme.homepage.fullsize ? null : (
            <ListWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <HomeSidebar selected={"vinitalyworld"} />
            </ListWrapper>
          )}/>
          <Route exact path='/:lang/bcards/:id?' render={() => (
            <ListWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <BCardsSidebar />
            </ListWrapper>
          )}/>
          {listRoutes.map(item =>
            <Route
              key={`list-${item.key}`}
              path={item.route}
              render={route => (
                <ListWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
                  <List analytics={false} detailBaseUrl={item.route} area={item.key} />
                </ListWrapper>
                )
              }
            />
          )}
        </Switch>
      {/*<PageWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>*/}
        <Switch>
          <Route exact path='/authorization' render={(route) => <></>} />

          <Route exact path='/:lang' render={() => theme.homepage.fullsize ? (
            <PageWrapperNoSidebarCentered filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Home />
            </PageWrapperNoSidebarCentered>
          ) : (
            <PageWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Home />
            </PageWrapper>
          )}/>
          <Route exact path='/:lang/homepage/:id' render={() => theme.homepage.fullsize ? (
            <PageWrapperNoSidebarCentered filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Home />
            </PageWrapperNoSidebarCentered>
          ) : (
            <PageWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Home />
            </PageWrapper>
          )}/>
          <Route exact path='/:lang/inspiration/:id?' render={() => theme.homepage.fullsize ? (
            <PageWrapperNoSidebarCentered filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Home />
            </PageWrapperNoSidebarCentered>
          ) : (
            <PageWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Home />
            </PageWrapper>
          )}/>
          <Route exact path='/:lang/markettrends/:id?' render={() => theme.homepage.fullsize ? (
            <PageWrapperNoSidebarCentered filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Home />
            </PageWrapperNoSidebarCentered>
          ) : (
            <PageWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Home />
            </PageWrapper>
          )}/>
          <Route exact path='/:lang/socialwall/:id?' render={() => theme.homepage.fullsize ? (
            <PageWrapperNoSidebarCentered filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Home />
            </PageWrapperNoSidebarCentered>
          ) : (
            <PageWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Home />
            </PageWrapper>
          )}/>
          <Route exact path='/:lang/winepeople/:id?' render={() => theme.homepage.fullsize ? (
            <PageWrapperNoSidebarCentered filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Home />
            </PageWrapperNoSidebarCentered>
          ) : (
            <PageWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Home />
            </PageWrapper>
          )}/>
          <Route exact path='/:lang/vinitalyworld/:id?' render={() => theme.homepage.fullsize ? (
            <PageWrapperNoSidebarCentered filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Home />
            </PageWrapperNoSidebarCentered>
          ) : (
            <PageWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Home />
            </PageWrapper>
          )}/>
          {/*<Route exact path='/:lang/meeting' render={() => <Meeting />}/>*/}
          <Route exact path='/:lang/myarea' render={() => (
            <PageWrapperNoSidebar filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <MyArea />
            </PageWrapperNoSidebar>
          )} />
          <Route exact path='/:lang/mybcard' render={() => (
            <PageWrapperNoSidebar filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <MyBCard />
            </PageWrapperNoSidebar>
          )} />
          <Route exact path='/:lang/memo' render={() => (
            <PageWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Memo />
            </PageWrapper>
          )} />
          {/*<Route exact path='/:lang/:area' children={<Idlepage analytics={true}/>}/>*/}
          {idleRoutes.map(item => (
            <Route exact key={`list-${item.key}`} path={`${item.route}`} render={route => (
              <PageWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
                 <Idlepage analytics={true} area={item.key} />
              </PageWrapper>
            )} />
          ))}
          {detailRoutes.map(item => (
            <Route exact key={`list-${item.key}`} path={`${item.route}`} render={() => (
               <PageWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
                  <Page showListButton={false} analytics={true} area={item.key}/>
               </PageWrapper>
            )} />
          ))}
          <Route exact path='/:lang/bcards' render={() => (
            <PageWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <Idlepage analytics={false} area={"bcards"}/>}/>
            </PageWrapper>
          )} />
          <Route exact path='/:lang/bcards/:id' render={() => (
            <PageWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <BCards />
            </PageWrapper>
          )} />
          <Route exact path='/:lang/helpdesk' render={() => (
            <PageWrapper filterWidth={theme.filters.width} listWidth={theme.list.width}>
              <HelpDesk />
            </PageWrapper>
          )} />
          <Route exact path='/:lang/terms' render={() => (
            <PageWrapper filterWidth={{ value: 0, unit: 'px'}} listWidth={theme.list.width}>
              <Terms page="main" />
            </PageWrapper>
          )} />
          <Route exact path='/:lang/terms/privacy-app' render={() => (
            <PageWrapper filterWidth={{ value: 0, unit: 'px'}} listWidth={theme.list.width}>
              <Terms page="privacy-app" />
            </PageWrapper>
          )} />
          <Route exact path='/:lang/contact-policy' render={() => (
            <PageWrapper filterWidth={{ value: 0, unit: 'px'}} listWidth={theme.list.width}>
              <ContactPolicy />
            </PageWrapper>
          )} />
        </Switch>
      {/*</PageWrapper>*/}
      <Switch>
        <Route path='/authorization' render={(route) => <></>}/>
        <Route exact path='/authorization' render={(route) => <></>}/>
        <Route exact path='*' children={<UserPanel analytics={false}/>}/>
      </Switch>
    </Mover>
  );
};

export default React.memo(Desktop)
