import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Homepage from "./Homepage";
// import Inspiration from "./Inspiration";



export default function Home(){
  //
  // const layout = useSelector(getLayout);
  // const translations = useSelector(getTranslations);
  // const lang = useSelector(getLocale);
  // const config = useSelector(getConfig);
  // const user = useSelector(getUser);



  return (
    <Switch>
      <Route exact path='/:lang' render={() => <Homepage />}/>
      {/*<Route exact path='/:lang/inspiration/:id?' render={() => <Inspiration />}/>*/}
    </Switch>

  );

}
