import axios from "axios";
import {DOMAINS} from "./../domains";

export class MemosService {

  constructor() {

    this.getList = this.getList.bind(this);
    this.getItem = this.getItem.bind(this);
    this.getDomainConfig = this.getDomainConfig.bind(this);
    this.getApiUrl = this.getApiUrl.bind(this);

  }


  getDomainConfig() {
    return DOMAINS[window.location.hostname];
  }

  getApiUrl() {

    const domainConfig = this.getDomainConfig().chatConfig;
    let apiUrl = domainConfig.default.apiUrl;
    if (domainConfig.hasOwnProperty(window.location.hostname))
      apiUrl = domainConfig[window.location.hostname].apiUrl;
    return apiUrl;

  }

  async getList(accessToken){

    const domainConfig = this.getDomainConfig();

    return new Promise((resolve, reject) => {
      if(domainConfig.memos.apiUrl){
        axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
        axios.get(domainConfig.memos.apiUrl, {}).then(function(result){
          if(result.status === 200){
            resolve(result.data.content);
          }else{
            resolve(null);
          }
        }).catch(function (error) {
          console.log(error);
          resolve(null);
        });
      }else{
        resolve(null);
      }
    });
  }

  async getItem(accessToken, id){

    const domainConfig = this.getDomainConfig();

    return new Promise((resolve, reject) => {
      if(domainConfig.memos.apiUrl){
        axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
        axios.get(domainConfig.memos.apiUrl+id, {}).then(function(result){
          // console.log(result);
          if(result.status === 200 && result.data.content.length){
            resolve(result.data.content[0]);
          }else{
            resolve(null);
          }
        }).catch(function (error) {
          console.log(error);
          resolve(null);
        });
      }else{
        resolve(null);
      }
    });
  }

}

export default new MemosService();
