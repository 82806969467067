import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { useTheme } from './../../themes';

const Wrapper  = styled.div`
  display: ${props => props.display};
  padding: ${props => props.padding ? props.padding.top.value+props.padding.top.unit+" "+props.padding.left.value+props.padding.left.unit+" "+props.padding.bottom.value+props.padding.bottom.unit+" "+props.padding.right.value+props.padding.right.unit+" " : "0px"};
  border: none;
  border-radius: ${props => props.borderRadius.value+props.borderRadius.unit};
  background: transparent;
  color: ${props => props.variant.hover.textColor};
  ${props => props.fullSize ? 'width: 100%;' : ''}
  outline: none;

  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
  ${props => props.align === 'center' ? 'margin: 0 auto;' : ''};

  text-align: center;

  &:disabled {
    cursor: default;
    opacity: 0.5
  }
`;

const Button = ({
  variant = "default",
  size = "default",
  display = "block",
  onClick,
  children,
  disabled,
  fullSize = false,
  align = null
}) => {
  const theme = useTheme()
  return (
    <Wrapper
      disabled={disabled === true}
      onClick={onClick}
      display={display}
      fullSize={fullSize}
      padding={theme.button.size[size].padding}
      borderStyle={theme.button.borderStyle}
      borderSize={theme.button.size[size].borderSize}
      borderRadius={theme.button.size[size].borderRadius}
      borderColor={theme.button.variant[variant].normal.borderColor}
      hoverBorderColor={theme.button.variant[variant].hover.borderColor}
      variant={theme.button.variant[variant]}
      textFont={theme.button.textFont}
      textSize={theme.button.size[size].textSize}
      textWeight={theme.button.size[size].textWeight}
      textLineHeight={theme.button.size[size].textLineHeight}
      textLetterSpacing={theme.button.textLetterSpacing}
      textTransform={theme.button.textTransform}
      align={align}
    >
      {children}
    </Wrapper>
  )
}

Button.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  display: PropTypes.string,
  align: PropTypes.string,
  onClick: PropTypes.func,
}

export default React.memo(Button)
