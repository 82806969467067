import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { getFavorites } from './../../store/auth/selectors';
import { setFavorite } from '../../store/auth/actions';
// import { toggleModal } from "../../store/settings/actions";
import { useTheme } from './../../themes';

import Icon from "./../App/Icon";

const Wrapper  = styled.button`
  position: relative;
  display: inline-block;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
  outline: none;
`;

const ToggleFavoriteButton = ({slug, area, size, colorOn, colorOff}) => {
  const theme = useTheme()
  // let { area } = useParams()

  // const user = useSelector(getUser);
  let favorites = useSelector(getFavorites);
  // favorites = null;
  const dispatch = useDispatch();

  const favAreas = {
    "companies": "exhibitor",
    "products": "product",
    "events": "event",
    "casehistories": "project",
    "architect": "architect",
    "architects": "architect",
    "project-architects": "project-architects",
    "projects-architects": "project-architects",
    "subcompanies": "architect",
    "subcasehistories": "project-architects",
  };

  const onClick = (e) => {
    e.preventDefault();
    dispatch(setFavorite({
      area: favAreas[area],
      idElement: slug,
      isFavorite: favorites ? favorites.filter(item => item.itemId === slug).length : null
    }), [dispatch]);

  }
  
  return (
    <Wrapper onClick={onClick}>
        <Icon
          name={theme.icons.set.favorite}
          size={size}
          color={favorites && area && favorites.filter(item => item.itemId === slug).length ? colorOn : colorOff}
          isFav={favorites && area && favorites.filter(item => item.itemId === slug).length}
        />
    </Wrapper>
  )
}

export default React.memo(ToggleFavoriteButton)
