import React from "react";
import { useSelector } from "react-redux";
import { getConfig } from "./../../store/settings/selectors";
import { getLocale } from "./../../store/localization/selectors";
import { useTheme } from "../../themes";

import MenuItem from "../App/MenuItem";
import Icon from "./../App/Icon";

const LanguageSelector = () => {
  const theme = useTheme();
  const lang = useSelector(getLocale);
  const config = useSelector(getConfig);
  
  return (
    <>
      {config.languages.map((code) =>(
        <a href={"/"+code} key={code}>
          <MenuItem
            rightIcon={lang === code ? <Icon
              name={theme.icons.set.checked}
              color={theme.primaryColor}
            /> : null}
            variant={theme.userpanel.menuitemVariantItem}
            >{(() => {
              switch (code) {
                case "it":
                  return "Italiano";
                case "en":
                  return "English";
                case "zh":
                  return "中文";
                case "ja":
                  return "日本語";
                case "ru":
                  return "русский";
                case "de":
                  return "Deutsch";
                case "fr":
                  return "Français";
                case "es":
                  return "Español";
                case "pt":
                  return "Português";
                case "ko":
                  return "한국의";
                default:
                  return "Uncoded Language";
              }
            })()}</MenuItem>
          </a>
      ))}
    </>
  )
}

export default React.memo(LanguageSelector)
