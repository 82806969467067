import React from 'react';
// import { Link, useParams } from 'react-router-dom';
import styled from "styled-components";
import {useTheme} from "./../../themes";
import { useSelector } from "react-redux";
import { getLayout } from "./../../store/settings/selectors";
import { getTranslations } from "./../../store/localization/selectors";
// import { getUser } from "./../../store/auth/selectors";

import {LAYOUT_XS, LAYOUT_SM, LAYOUT_MD} from './../../settings';


import Container from './../App/Container';
// import Chunk from './../App/Chunk';
import Spacer from './../App/Spacer';
// import Image from './../App/Image';
// import Button from './../App/Button';
// import InfoRow from './../App/InfoRow';
// import Result from './../List/Sidebar/ResultsList/Result';
import Analytics from "../Analytics/Analytics";

import CompactMenuIcon from './../../components/App/CompactMenuIcon';


export const CompactMenuIconWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
   width: 46px;
   height: 46px;
   flex-grow: 0;
   flex-shrink: 0;
   margin-left: 7px;
   z-index: 9999;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  overflow-x: none;
  overflow-y: auto;
`;

const MainContainer = styled.div`
  margin-top: 3px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
`;
const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  margin-left: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
`;
const MacroCol = styled.div`
  padding: 0px ${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
`;

const Element = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 10px;
`;
const Block = styled.div`
  padding: 15px 20px;

  &:not(:last-child){
    border-bottom: 1px solid #dedede;
  }
`;
// const AlignRight = styled.div`
//   text-align: right;
// `;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleBlock = styled.div`
`;

// const Photo = styled.div`
//   flaot: right;
//   width: 70px;
//   height: 50px;
//   border-radius: 10px;
//   overflow: hidden;
//   background: #dedede;
// `;
const Title = styled.div`
  color: ${props => props.color};
  font-size: 14px;
  font-weight: 600;
`;
// const Subtitle = styled.div`
//   color: #585858;
//   font-size: 11px;
//   font-weight: 400;
// `;
const Text = styled.div`
  color: #585858;
  font-size: 12px;
  font-weight: 400;
`;

export default function Helpdesk(){

  const layout = useSelector(getLayout);
  const translations = useSelector(getTranslations);
  // const lang = useSelector(getLocale);
  // const config = useSelector(getConfig);
  // const user = useSelector(getUser);
  const theme = useTheme();




  return (
    <Wrapper>
      {layout === LAYOUT_XS || layout === LAYOUT_SM ? 
        <CompactMenuIconWrapper>
          <CompactMenuIcon />
        </CompactMenuIconWrapper>
      : <Spacer size={{value: 34, unit: "px"}} /> }
      
      <MainContainer>
        <Container>

          <MacroRow layout={layout}>
            <MacroCol size="66" layout={layout}>


                <Element>
                  <Block>
                    <Header>
                      <TitleBlock>
                        <Title color={theme.color}>{translations["helpdesk"]["_title-1"]}<br/>{translations["helpdesk"]["_title-2"]}</Title>
                      </TitleBlock>
                    </Header>
                  </Block>
                  <Block>
                    <Text><b>{translations["helpdesk"]["_row-1-title"]}</b><br/>{translations["helpdesk"]["_row-1-text"]}</Text>
                  </Block>
                  <Block>
                    <Text><b>{translations["helpdesk"]["_row-2-title"]}</b><br/>{translations["helpdesk"]["_row-2-text"]}</Text>
                  </Block>
                </Element>

            </MacroCol>
            <MacroCol size="34" layout={layout}>




            </MacroCol>
          </MacroRow>
        </Container>
      </MainContainer>
      <Analytics name="helpdesk" />
    </Wrapper>

  );

}
