import React from 'react';
import styled from 'styled-components';
import {useSelector} from "react-redux";
import {getToken} from "../../store/auth/selectors";
import {getAccountSettings, getChatEnvByDomain} from "../../store/settings/selectors";
import {getLocale} from "../../store/localization/selectors";
import {getLayout} from "./../../store/settings/selectors";

import {LAYOUT_MD, LAYOUT_SM, LAYOUT_XS} from './../../settings';

import Container from './../App/Container';
import Spacer from './../App/Spacer';
import Analytics from "../Analytics/Analytics";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
`;

const MainContainer = styled.div`
  margin-top: 3px;
  padding-bottom: 30px;
`;
const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  margin-left: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
`;
const MacroCol = styled.div`
  padding: 0px ${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;  
`;

export default function MyBCard({ forced = false }) {

  const token = useSelector(getToken);
  const language = useSelector(getLocale);
  const layout = useSelector(getLayout);
  const accountSettings = useSelector(getAccountSettings);
  const pluginEnv = useSelector(getChatEnvByDomain);

  return (
    <Wrapper>
      <Spacer size={{value: 34, unit: "px"}} />
      <MainContainer>
        <Container>

          <MacroRow layout={layout}>
            <MacroCol size="10" />
            <MacroCol size="80" layout={layout}>
              {token ? (
                <vrf-account env={pluginEnv} locale={language} route={'business-card'} token={token.accessToken} theme={accountSettings.theme} />
              ) : null}
              </MacroCol>
            </MacroRow>

          </Container>
        </MainContainer>
        <Analytics name="mybcard" />
      </Wrapper>
  );

}
