import React from 'react';
import {Link} from "react-router-dom";
// import ReactHtmlParser from 'react-html-parser';
import {useSelector} from "react-redux";
import styled from 'styled-components'

import { getConfig } from './../../../../store/settings/selectors';

// import {Wrapper, WrapperTop, WrapperBottom, Tag, UpperText, TitleText, LowerText, Left, Right} from "./styled";
// import {useTheme} from "../../../../themes";
// import ToggleFavoriteButton from "../../../User/ToggleFavoriteButton";
// import ResultsList from "./ResultsList";

import VirCompanyResult from './../../../../tenants/VIR/Companies/CompanyResult';
import VirProductResult from './../../../../tenants/VIR/Products/ProductResult';
import VirEventResult from './../../../../tenants/VIR/Events/EventResult';

import MdmCompanyResult from './../../../../tenants/MDM/Companies/CompanyResult';
import MdmProductResult from './../../../../tenants/MDM/Products/ProductResult';
import MdmCasehistoryResult from './../../../../tenants/MDM/Casehistories/CasehistoryResult';
import MdmEventResult from './../../../../tenants/MDM/Events/EventResult';
import MdmSubCompanyResult from './../../../../tenants/MDM/SubCompanies/SubCompanyResult';
import MdmSubCasehistoryResult from './../../../../tenants/MDM/SubCasehistories/SubCasehistoryResult';

import AgrCompanyResult from './../../../../tenants/AGR/Companies/CompanyResult';
import AgrProductResult from './../../../../tenants/AGR/Products/ProductResult';
import AgrEventResult from './../../../../tenants/AGR/Events/EventResult';

import FicCompanyResult from './../../../../tenants/FIC/Companies/CompanyResult';
import FicProductResult from './../../../../tenants/FIC/Products/ProductResult';
import FicEventResult from './../../../../tenants/FIC/Events/EventResult';

import SamCompanyResult from './../../../../tenants/SAM/Companies/CompanyResult';
import SamProductResult from './../../../../tenants/SAM/Products/ProductResult';
import SamEventResult from './../../../../tenants/SAM/Events/EventResult';

const Container = styled.div`
  /* width: calc(100% - 30px);
  margin: 0px 15px; */
`;

const Result = ({slug, item, url, area, isActive, enableLogin, enableFavorite, tagsPosition, tags, onClick, resultsType}) => {
  const config = useSelector(getConfig);
  // const showRightColumn = enableFavorite;
  return (
    <Container>
      <Link to={url} onClick={(e) => onClick ? onClick(e, item.type) : null}>
        {(() => {
          switch (config.tenant) {
            case "VIR":
              switch (area) {
                case "companies":
                  return (<VirCompanyResult item={item} isActive={isActive} url={url} resultType = {resultsType}/>);
                case "products":
                  return (<VirProductResult item={item} isActive={isActive} url={url} resultType = {resultsType}/>);
                case "events":
                  return (<VirEventResult item={item} isActive={isActive} url={url} resultType = {resultsType}/>);
                default:
                  return null;
              }
            case "MDM":
              switch (area) {
                case "companies":
                  return (<MdmCompanyResult item={item} isActive={isActive} resultType = {resultsType}/>);
                case "products":
                  return (<MdmProductResult item={item} isActive={isActive} resultType = {resultsType}/>);
                case "events":
                  return (<MdmEventResult item={item} isActive={isActive} url={url} resultType = {resultsType}/>);
                case "casehistories":
                  return (<MdmCasehistoryResult item={item} isActive={isActive}/>);
                case "subcompanies":
                  return (<MdmSubCompanyResult item={item} isActive={isActive}/>);
                case "subcasehistories":
                  return (<MdmSubCasehistoryResult item={item} isActive={isActive}/>);
                default:
                  return null;
              }
            case "AGR":
              switch (area) {
                case "companies":
                  return (<AgrCompanyResult item={item} isActive={isActive} url={url} resultType = {resultsType}/>);
                case "products":
                  return (<AgrProductResult item={item} isActive={isActive} url={url} resultType = {resultsType}/>);
                case "events":
                  return (<AgrEventResult item={item} isActive={isActive} url={url} resultType = {resultsType}/>);
                default:
                  return null;
              }
            case "FIC":
              switch (area) {
                case "companies":
                  return (<FicCompanyResult item={item} isActive={isActive} url={url} resultType = {resultsType}/>);
                case "products":
                  return (<FicProductResult item={item} isActive={isActive} url={url} resultType = {resultsType}/>);
                case "events":
                  return (<FicEventResult item={item} isActive={isActive} url={url} resultType = {resultsType}/>);
                default:
                  return null;
              }
            case "SAM":
              switch (area) {
                case "companies":
                  return (<SamCompanyResult item={item} isActive={isActive} url={url} resultType = {resultsType}/>);
                case "products":
                  return (<SamProductResult item={item} isActive={isActive} url={url} resultType = {resultsType}/>);
                case "events":
                  return (<SamEventResult item={item} isActive={isActive} url={url} resultType = {resultsType}/>);
                default:
                  return null;
              }
            default:
              return null;
          }
        })()}
      </Link>
    </Container>

  );
}

Result.defaultProps = {
  tagsPosition: 'bottom',
  tags: []
}
export default React.memo(Result);
