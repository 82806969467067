import React from 'react';
import {useParams} from 'react-router-dom';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getLayout} from "./../../store/settings/selectors";
import {getLocale, getTranslations} from "./../../store/localization/selectors";

import {LAYOUT_MD, LAYOUT_SM, LAYOUT_XS} from './../../settings';
import MobileHeader from './../Common/MobileHeader';

import Container from './../App/Container';
import Chunk from './../App/Chunk';
import Spacer from './../App/Spacer';
import Image from './../App/Image';
import InfoRow from './../App/InfoRow';
import {getById} from "../../store/bcard/selectors";

// const Wrapper = styled.div`
//   width: 100%;
//   justify-content: center;
//   display: flex;
//   padding-top: 30px;
//   padding-bottom: 20px;
//   height: 100%;
//   // overflow: hidden;
// `;




const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;

  height: calc(100vh - 50px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;


const MainContainer = styled.div`
  margin-top: 3px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* min-height: 100%; */
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
`;

const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  margin-left: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
`;
const MacroCol = styled.div`
  padding: 0px ${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;
const Avatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 20px;
`;
const Title = styled.div`
`;
const Name = styled.div`
  color: #7B3572;
  font-size: 16px;
  font-weight: 700;
`;
const Company = styled.div`
  color: #585858;
  font-size: 12px;
  font-weight: 700;
`;

export default function BCards(){

  const layout = useSelector(getLayout);
  const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);

  let { id } = useParams();
  const record = useSelector(getById(id));

  return (
    <Wrapper>
      <MobileHeader
        backlink={`/${lang}/bcards`}
        title={record ? record.fullname : null}
      />
      <MainContainer>
        <Spacer size={{value: 28, unit: "px"}} />
        <Container>

          <MacroRow layout={layout}>
            <MacroCol size="66" layout={layout}>
              {record ?
                <Chunk open={true} collapsible={false}>
                  <Header>
                    { record?.photo ?
                      <Avatar>
                        <Image src={record.photo} width={{"value": 80, "unit": "px"}} height={{"value": 80, "unit": "px"}} fit="cover" />
                      </Avatar>
                    : null }
                    <Title>
                      <Name>{record.fullname}</Name>
                      <Company>{record.organization}</Company>
                    </Title>
                  </Header>
                  {record.name ?
                    <InfoRow
                      label={translations["UI"]["firstName"]}
                      value={record.name}
                    />
                  : ""}
                  {record.surname ?
                    <InfoRow
                      label={translations["UI"]["lastName"]}
                      value={record.surname}
                    />
                  : ""}
                  {record.organization ?
                    <InfoRow
                      label={translations["bcard"]["Organization"]}
                      value={record.organization}
                    />
                  : ""}
                  {record.email ?
                    <InfoRow
                      label={translations["bcard"]["Email"]}
                      value={record.email}
                    />
                  : ""}
                  {record.address ?
                    <InfoRow
                      label={translations["bcard"]["Address"]}
                      value={`${record.address} ${record.city}`}
                    />
                  : ""}
                  {record.telephone ?
                    <InfoRow
                      label={translations["bcard"]["Telephone"]}
                      value={record.telephone}
                    />
                  : ""}
                  {record.country ?
                    <InfoRow
                      label={translations['UI']['nation']}
                      value={record.country.toUpperCase()}
                    />
                    : ""}
                  {record.website ?
                    <InfoRow
                      label={translations["bcard"]["Url"]}
                      value={record.website}
                    />
                  : ""}
                </Chunk>
              : null}
            </MacroCol>
            <MacroCol size="34" layout={layout}>




            </MacroCol>
          </MacroRow>

        </Container>
        <Spacer size={{value: 28, unit: "px"}} />
      </MainContainer>
    </Wrapper>

  );

}
