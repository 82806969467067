import axios from "axios";
import {DOMAINS} from "./../domains";

export class RecentsService {

  constructor() {

    this.getList = this.getList.bind(this);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.getDomainConfig = this.getDomainConfig.bind(this);
    this.getApiUrl = this.getApiUrl.bind(this);

  }

  getDomainConfig() {
    return DOMAINS[window.location.hostname];
  }

  getApiUrl() {

    const domainConfig = this.getDomainConfig().chatConfig;
    if (domainConfig.hasOwnProperty(window.location.hostname))
      return domainConfig[window.location.hostname].recentsUrl
    return domainConfig.default.recentsUrl;

  }

  areas = {
    "companies": "exhibitor",
    "products": "product",
    "events": "event",
    "casehistories": "project",
    "exhibitor": "exhibitor",
    "product": "product",
    "event": "event",
    "project": "project",
    "architect": "architect",
    "architects": "architect",
    "project-architects": "project-architects",
    "projects-architects": "project-architects",
    "subcompanies": "architect",
    "subcasehistories": "project-architects",
    "subcompany": "architect",
    "subcasehistory": "project-architects"
  };

  tenants = {
    'VIR': 'VIR',
    'MDM': 'MMP',
    'AGR': 'AGR',
    'FIC': 'FIC',
    'SAM': 'SAM'
  }

  async getList(area){

    const domainConfig = this.getDomainConfig();
    const apiUrl = this.getApiUrl();

    return new Promise(async (resolve, reject) => {
      if(area){
        if(apiUrl){
          try {
            const result = await axios.get(apiUrl + "?itemType=" + this.areas[area] + "&exhibitionId=" + this.tenants[domainConfig.tenant], {});
            resolve(result.data);
          } catch (error) {
            console.log(error);
            resolve(null);
          }
        }else{
          resolve(null);
        }
      }else{
        resolve(null);
      }
    });

  }

  async add(accessToken, idUser, area, idElement){
    const domainConfig = this.getDomainConfig();
    const apiUrl = this.getApiUrl();
    return new Promise(async (resolve, reject) => {
      if(apiUrl){
        try {
          const result = await axios.post(apiUrl, {
            exhibitionId: this.tenants[domainConfig.tenant],
            itemType: this.areas[area],
            itemId: idElement,
            userId: idUser
          })
          resolve(result.data.content);
        } catch (error) {
          console.log(error);
          resolve(null);
        }
      }else{
        resolve(null);
      }
    });
  }

  async remove(accessToken, idUser, area, idElement){

    const domainConfig = this.getDomainConfig();
    const apiUrl = this.getApiUrl();

    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.delete(apiUrl + "/" + idElement + "?itemType=" + this.areas[area] + "&exhibitionId=" + this.tenants[domainConfig.tenant]);
        resolve(result.data.content);
      } catch (error) {
        console.log(error);
        resolve(null);
      }
    });
  }

}

export default new RecentsService();
